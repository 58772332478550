<template>
  <common-dialog
    :visible.sync="visible"
    title="运单详情"
    type="alert"
    :confirm="confirm"
    :cancel="cancel"
    :close="cancel"
    size="80%"
  >
    <detail class="detail" ref="detail" :wayId="wayId" opt="detail" disabled></detail>

  </common-dialog>
</template>

<script>
import CommonDialog from '@/components/Common/CommonDialog.vue'
import Detail from './WayBill.vue'
export default {
  components: {
    CommonDialog,
    Detail
  },
  data () {
    return {
      visible: false,
      wayId: '',
    }
  },
  methods: {
    confirm() {

    },
    cancel() {
    }
  }
}
</script>

<style scoped lang="scss">
.detail {
  width: 100%;
}
</style>