import request from '@/utils/http'

export function queryBaseAreaList() {
  return request({
    url: '/base/area/queryBaseAreaList',
    method: 'post'
  })
}

export function queryAreaByName(params) {
  return request({
    url: '/base/area/queryAreaByName',
    method: 'post',
    params
  })
}
// 网络货运 --start
export function queryDriverInList(params) {
  return request({
    url: '/net/driver/queryDriverInList',
    method: 'post',
    params
  })
}
export function queryVehicleInList(params) {
  return request({
    url: '/net/driver/queryVehicleInList',
    method: 'post',
    params
  })
}
export function queryWayBillInList(params) {
  return request({
    url: '/net/driver/queryWayBillInList',
    method: 'post',
    params
  })
}
export function queryFinWriteOffList(params) {
  return request({
    url: '/net/driver/queryFinWriteOffList',
    method: 'post',
    params
  })
}
export function saveOrUpdateDriver(params) {
  return request({
    url: '/base/vehicle/saveOrUpdateDriver',
    method: 'post',
    params
  })
}
// ---end

export function queryBasePrvnList() {
  return request({
    url: '/base/area/queryBasePrvnList',
    method: 'post'
  })
}

export function queryBaseConsignorList(params) {
  return request({
    url: '/base/cnor/queryBaseConsignorList',
    method: 'post',
    params
  })
}

export function queryConsignorContactList(params) {
  return request({
    url: '/base/cnor/queryConsignorContactList',
    method: 'post',
    params
  })
}

export function queryConsignorAddrList(params) {
  return request({
    url: '/base/cnor/queryConsignorAddrList',
    method: 'post',
    params
  })
}
export function queryConsignorAddrLngLatById(params) {
  return request({
    url: '/base/rcvr/queryConsignorAddrLngLatById',
    method: 'post',
    params
  })
}

export function queryBaseReceiverContactList(params) {
  return request({
    url: '/base/rcvr/queryBaseReceiverContactList',
    method: 'post',
    params
  })
}

export function queryBaseReceiverAddrList(params) {
  return request({
    url: '/base/rcvr/queryBaseReceiverAddrList',
    method: 'post',
    params
  })
}

export function queryCnorIdByCo(params) {
  return request({
    url: '/base/cnor/queryCnorIdByCo',
    method: 'post',
    params
  })
}

export function queryConsignerContacts(params) {
  return request({
    url: '/base/cnor/queryConsignerContacts',
    method: 'post',
    params
  })
}

export function queryConsignerAddrs(params) {
  return request({
    url: '/base/cnor/queryConsignerAddrs',
    method: 'post',
    params
  })
}

export function selectBaseConsignor(params) {
  return request({
    url: '/base/cnor/selectBaseConsignor',
    method: 'post',
    params
  })
}

export function downloadTemplate() {
  return request({
    url: '/base/priceplan/downloadTemplate',
    method: 'post',
    responseType: 'arraybuffer'
  })
}

export function submitPriceExcel(params) {
  return request({
    url: '/base/priceplan/submitPriceExcel',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    method: 'post',
    data: params
  })
}

export function queryBaseReceiverList(params) {
  return request({
    url: '/base/rcvr/queryBaseReceiverList',
    method: 'post',
    params
  })
}

export function queryBaseReceiverLists(params) {
  return request({
    url: '/base/rcvr/queryBaseReceiverLists',
    method: 'post',
    params
  })
}

export function queryBaseCargoList(params) {
  return request({
    url: '/base/cargo/queryBaseCargoList',
    method: 'post',
    params
  })
}

export function calcBasePricePlan(params) {
  return request({
    url: '/base/priceplan/calcBasePricePlan',
    method: 'post',
    params
  })
}

export function calcProtocolPricePlan(params) {
  return request({
    url: '/base/priceplan/calcProtocolPricePlan',
    method: 'post',
    params
  })
}

export function calcBaseInnerPricePlan(params) {
  return request({
    url: '/base/priceplan/calcBaseInnerPricePlan',
    method: 'post',
    params
  })
}

export function platformIn() {
  return request({
    url: '/base/vehicle/platformIn',
    method: 'post'
  })
}

export function driverIn(params) {
  return request({
    url: '/base/vehicle/driverIn',
    method: 'post',
    params
  })
}

export function Registerquery(params) {
  return request({
    url: '/base/vehicle/Registerquery',
    method: 'post',
    params
  })
}

export function openBalancePay(params) {
  return request({
    url: '/base/vehicle/openBalancePay',
    method: 'post',
    params
  })
}

export function getAuthCode(params) {
  return request({
    url: '/base/vehicle/getAuthCode',
    method: 'post',
    params
  })
}

export function getRoadTransport(params) {
  return request({
    url: '/base/vehicle/getRoadTransport',
    method: 'post',
    params
  })
}

export function insertOrderNoById(params) {
  return request({
    url: '/base/vehicle/insertOrderNoById',
    method: 'post',
    params
  })
}

export function updateDriverInStatusByOrderNo(params) {
  return request({
    url: '/base/vehicle/updateDriverInStatusByOrderNo',
    method: 'post',
    params
  })
}

export function queryVehicleList4DD() {
  return request({
    url: '/base/vehicle/queryVehicleList4DD',
    method: 'post'
  })
}
export function queryVehicleList4DDIsNetwork(params) {
  return request({
    url: '/base/vehicle/queryVehicleList4DDIsNetwork',
    method: 'post',
    params
  })
}

export function queryVehicleLinePrefere(params) {
  return request({
    url: '/base/vehicle/queryVehicleLinePrefere',
    method: 'post',
    params
  })
}
export function queryVehicleVerifyInfoByvehId(params) {
  return request({
    url: '/base/vehicle/queryVehicleVerifyInfoByvehId',
    method: 'post',
    params
  })
}

export function saveOrUpdateVehicleLinePrefere(params) {
  return request({
    url: '/base/vehicle/saveOrUpdateVehicleLinePrefere',
    method: 'post',
    params
  })
}

export function queryVehicleVerifyInfo(params) {
  return request({
    url: '/base/vehicle/queryVehicleVerifyInfo',
    method: 'post',
    params
  })
}

export function queryAllBaseCarrierList() {
  return request({
    url: '/base/carrier/queryAllBaseCarrierList',
    method: 'post'
  })
}

export function queryCarrierByBranchId(params) {
  return request({
    url: '/base/carrier/queryCarrierByBranchId',
    method: 'post',
    params
  })
}

export function saveOrUpdateCarrierVerifyInfo(params) {
  return request({
    url: '/base/carrier/saveOrUpdateCarrierVerifyInfo',
    method: 'post',
    params
  })
}

export function freezeCarrier(params) {
  return request({
    url: '/base/carrier/freezeCarrier',
    method: 'post',
    params
  })
}

export function queryCarrierVerifyInfo(params) {
  return request({
    url: '/base/carrier/queryCarrierVerifyInfo',
    method: 'post',
    params
  })
}

export function exportCarrierModel(params) {
  return request({
    url: '/base/carrier/exportCarrierModel',
    method: 'post',
    responseType: 'arraybuffer',
    params
  })
}

export function submitCarrierInfo(params) {
  return request({
    url: '/base/carrier/submitCarrierInfo',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    method: 'post',
    data: params
  })
}

export function queryBaseCarrierDestListById(params) {
  return request({
    url: '/base/carrier/queryBaseCarrierDestListById',
    method: 'post',
    params
  })
}

export function saveOrUpdateConsignor(params) {
  return request({
    url: '/base/cnor/saveOrUpdateConsignor',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    method: 'post',
    data: params
  })
}
export function saveConsignorAccount(params) {
  return request({
    url: '/base/cnor/saveConsignorAccount',
    method: 'post',
    params
  })
}

export function removeConsignor(params) {
  return request({
    url: '/base/cnor/removeConsignor',
    method: 'post',
    params
  })
}

export function queryAllBaseConsignorList() {
  return request({
    url: '/base/cnor/queryAllBaseConsignorList',
    method: 'post'
  })
}

export function saveOrUpdateReceiver(params) {
  return request({
    url: '/base/rcvr/saveOrUpdateReceiver',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    method: 'post',
    data: params
  })
}

export function removeReceiver(params) {
  return request({
    url: '/base/rcvr/removeReceiver',
    method: 'post',
    params
  })
}

export function queryReceiverContacts(params) {
  return request({
    url: '/base/rcvr/queryReceiverContacts',
    method: 'post',
    params
  })
}

export function queryReceiverAddrs(params) {
  return request({
    url: '/base/rcvr/queryReceiverAddrs',
    method: 'post',
    params
  })
}

export function saveOrUpdateCargo(params) {
  return request({
    url: '/base/cargo/saveOrUpdateCargo',
    method: 'post',
    params
  })
}

export function removeCargo(params) {
  return request({
    url: '/base/cargo/removeCargo',
    method: 'post',
    params
  })
}

// 车辆管理
export function queryBaseVehicleList(params) {
  return request({
    url: '/base/vehicle/queryBaseVehicleList',
    method: 'post',
    params
  })
}

// 认证管理
export function queryBaseVehicleVerifyList(params) {
  return request({
    url: '/base/vehicle/queryBaseVehicleVerifyList',
    method: 'post',
    params
  })
}

// 修改司机认证信息
export function saveOrUpdateVehicleVerifyInfo4App(params) {
  return request({
    url: '/base/vehicle/app/saveOrUpdateVehicleVerifyInfo4App',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    method: 'post',
    data: params
  })
}

// 添加司机认证信息
export function vehicleRegister(params) {
  return request({
    url: '/user/app/vehicleRegister',
    method: 'post',
    params
  })
}

// ocr扫描身份证正面
export function idCardScan(params) {
  return request({
    url: '/ocrScan/app/idCardScan',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    data: params
  })
}// ocr扫描身份证国徽面
export function idCardScanBack(params) {
  return request({
    url: '/ocrScan/app/idCardScanBack',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    data: params
  })
}// ocr扫描驾驶证
export function driverLicense(params) {
  return request({
    url: '/ocrScan/app/driverLicense',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    data: params
  })
}
// ocr扫描行驶证
export function vehicleLicenseScan(params) {
  return request({
    url: '/ocrScan/app/vehicleLicenseScan',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    data: params
  })
}
// ocr扫描行驶证副页
export function vehicleLicenseScanBack(params) {
  return request({
    url: '/ocrScan/app/vehicleLicenseScanBack',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    data: params
  })
}
// ocr扫描道路运输证
export function transportationLicenseScan(params) {
  return request({
    url: '/ocrScan/app/transportationLicenseScan',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    data: params
  })
}
// ocr扫描银行卡
export function bankCard(params) {
  return request({
    url: '/ocrScan/app/bankCard',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    data: params
  })
}

// 校验手机号唯一性
export function checkDriverPhone(params) {
  return request({
    url: '/base/vehicle/checkDriverPhone',
    method: 'post',
    params
  })
}

export function removeVehicle(params) {
  return request({
    url: '/base/vehicle/removeVehicle',
    method: 'post',
    params
  })
}
// 车辆新增保存到车辆表
export function saveOrUpdateVehicle(params) {
  return request({
    url: '/base/vehicle/saveOrUpdateVehicle',
    method: 'post',
    params
  })
}
// 车辆新增保存到认证表
export function saveOrUpdateVehicleTo(params) {
  return request({
    url: '/base/vehicle/saveOrUpdateVehicleTo',
    method: 'post',
    params
  })
}
// 保存司机认证信息
export function verifiedVehicleInfo(params) {
  return request({
    url: '/base/vehicle/verifiedVehicleInfo',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    method: 'post',
    data: params
  })
}
// 修改认证信息
export function updateVehicleInfo(params) {
  return request({
    url: '/base/vehicle/updateVehicleInfo',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    method: 'post',
    data: params
  })
}

// 价格管理
export function queryBasePricePlanList(params) {
  return request({
    url: '/base/priceplan/queryBasePricePlanList',
    method: 'post',
    params
  })
}
// 司机车辆信息上传
export function uploadVehicleMng(params) {
  return request({
    url: '/base/vehicle/uploadVehicleMng',
    method: 'post',
    params
  })
}
export function saveOrUpdatePricePlan(params) {
  return request({
    url: '/base/priceplan/saveOrUpdatePricePlan',
    method: 'post',
    params
  })
}

export function dealPricePlanStatus(params) {
  return request({
    url: '/base/priceplan/dealPricePlanStatus',
    method: 'post',
    params
  })
}

export function queryBasePricePlanCustList(params) {
  return request({
    url: '/base/priceplancust/queryBasePricePlanCustList',
    method: 'post',
    params
  })
}

export function queryCustList(params) {
  return request({
    url: '/base/priceplancust/queryCustList',
    method: 'post',
    params
  })
}

export function saveOrUpdatePricePlanCust(params) {
  return request({
    url: '/base/priceplancust/saveOrUpdatePricePlanCust',
    method: 'post',
    params
  })
}

export function queryBasePricePlanDtlList(params) {
  return request({
    url: '/base/priceplandtl/queryBasePricePlanDtlList',
    method: 'post',
    params
  })
}

// 承运商管理
export function queryBaseCarrierList(params) {
  return request({
    url: '/base/carrier/queryBaseCarrierList',
    method: 'post',
    params
  })
}

export function submitBranch(params) {
  return request({
    url: '/base/carrier/submitBranch',
    method: 'post',
    params
  })
}

export function queryBranch(params) {
  return request({
    url: '/base/carrier/queryBranch',
    method: 'post',
    params
  })
}

export function saveOrUpdateCarrier(data) {
  return request({
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    url: '/base/carrier/saveOrUpdateCarrier',
    method: 'post',
    params: {},
    data
  })
}

export function removeCarrier(params) {
  return request({
    url: '/base/carrier/removeCarrier',
    method: 'post',
    params
  })
}

export function updatePricePlanDtl(params) {
  return request({
    url: '/base/priceplandtl/updatePricePlanDtl',
    method: 'post',
    params
  })
}

export function queryBasePricePlanRuleList(params) {
  return request({
    url: '/base/priceplanrule/queryBasePricePlanRuleList',
    method: 'post',
    params
  })
}

export function saveOrUpdatePricePlanRule(params) {
  return request({
    url: '/base/priceplanrule/saveOrUpdatePricePlanRule',
    method: 'post',
    params
  })
}

export function removePricePlanRule(params) {
  return request({
    url: '/base/priceplanrule/removePricePlanRule',
    method: 'post',
    params
  })
}

export function removePricePlanDtl(params) {
  return request({
    url: '/base/priceplandtl/removePricePlanDtl',
    method: 'post',
    params
  })
}

export function changeStatus(params) {
  return request({
    url: '/base/priceplandtl/changeStatus',
    method: 'post',
    params
  })
}

export function removePricePlanCust(params) {
  return request({
    url: '/base/priceplancust/removePricePlanCust',
    method: 'post',
    params
  })
}

export function isRelative(params) {
  return request({
    url: '/base/priceplancust/isRelative',
    method: 'post',
    params
  })
}

export function queryAllAreaList() {
  return request({
    url: '/base/area/queryAllAreaList',
    method: 'post'
  })
}

export function queryProvinceList() {
  return request({
    url: '/base/area/queryProvinceList',
    method: 'post'
  })
}

export function queryStartCityOptions(params) {
  return request({
    url: '/base/area/queryStartCityOptions',
    method: 'post',
    params
  })
}

export function queryDestCityOptions(params) {
  return request({
    url: '/base/area/queryDestCityOptions',
    method: 'post',
    params
  })
}

export function queryBaseCrirPricePlanList(params) {
  return request({
    url: '/base/crirpriceplan/queryBaseCrirPricePlanList',
    method: 'post',
    params
  })
}

export function queryBaseCrirPricePlanDtlList(params) {
  return request({
    url: '/base/crirpriceplandtl/queryBaseCrirPricePlanDtlList',
    method: 'post',
    params
  })
}

export function editBaseCrirPricePlanDtl(params) {
  return request({
    url: '/base/crirpriceplandtl/editBaseCrirPricePlanDtl',
    method: 'post',
    params
  })
}

export function removeBaseCrirPricePlanDtl(params) {
  return request({
    url: '/base/crirpriceplandtl/removeBaseCrirPricePlanDtl',
    method: 'post',
    params
  })
}

export function addBaseCrirPricePlan(params) {
  return request({
    url: '/base/crirpriceplan/addBaseCrirPricePlan',
    method: 'post',
    params
  })
}

export function addBaseCrirPricePlanDtl(params) {
  return request({
    url: '/base/crirpriceplandtl/addBaseCrirPricePlanDtl',
    method: 'post',
    params
  })
}

export function addBaseCrirPricePlanRule(params) {
  return request({
    url: '/base/crirpriceplanrule/addBaseCrirPricePlanRule',
    method: 'post',
    params
  })
}

export function queryBaseCrirPricePlanRuleList(params) {
  return request({
    url: '/base/crirpriceplanrule/queryBaseCrirPricePlanRuleList',
    method: 'post',
    params
  })
}

export function removeBaseCrirPricePlanRule(params) {
  return request({
    url: '/base/crirpriceplanrule/removeBaseCrirPricePlanRule',
    method: 'post',
    params
  })
}

export function editBaseCrirPricePlanRule(params) {
  return request({
    url: '/base/crirpriceplanrule/editBaseCrirPricePlanRule',
    method: 'post',
    params
  })
}

export function calcBaseCrirPricePlan(params) {
  return request({
    url: '/base/crirpriceplan/calcBaseCrirPricePlan',
    method: 'post',
    params
  })
}

export function calcVehicleBaseCrirPricePlan(params) {
  return request({
    url: '/base/crirpriceplan/calcVehicleBaseCrirPricePlan',
    method: 'post',
    params
  })
}

export function queryCarrier(params) {
  return request({
    url: '/base/carrier/queryCarrier',
    method: 'post',
    params
  })
}

export function queryBaseInvosList(params) {
  return request({
    url: '/base/invo/queryBaseInvosList',
    method: 'post',
    params
  })
}

export function queryBaseInvoiceData(params) {
  return request({
    url: '/base/invo/queryBaseInvoiceData',
    method: 'post',
    params
  })
}

export function saveOrUpdateInvoData(params) {
  return request({
    url: '/base/invo/saveOrUpdateInvoData',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    method: 'post',
    data: params
  })
}

export function removeInvoice(params) {
  return request({
    url: '/base/invo/removeInvoice',
    method: 'post',
    params
  })
}

export function queryBaseFleetList(params) {
  return request({
    url: '/base/fleet/queryBaseFleetList',
    method: 'post',
    params
  })
}

export function queryBaseFleetAllList(params) {
  return request({
    url: '/base/fleet/queryBaseFleetAllList',
    method: 'post',
    params
  })
}
export function removeFleet(params) {
  return request({
    url: '/base/fleet/removeFleet',
    method: 'post',
    params
  })
}
export function saveOrUpdateFleet(params) {
  return request({
    url: '/base/fleet/saveOrUpdateFleet',
    method: 'post',
    params
  })
}
export function queryBaseFleetById(params) {
  return request({
    url: '/base/fleet/queryBaseFleetById',
    method: 'post',
    params
  })
}
export function queryCarrierById(params) {
  return request({
    url: '/base/carrier/queryCarrierById',
    method: 'post',
    params
  })
}

export function addRoadTransportCertificate(params) {
  return request({
    url: '/base/vehicle/addRoadTransportCertificate',
    method: 'post',
    params
  })
}
