import request from '@/utils/http'

export function getSerialNumber(params) {
  return request({
    url: '/sys/serial/getSerialNumber',
    method: 'post',
    params
  })
}

export function queryRoleList(params) {
  return request({
    url: '/sys/role/queryRoleList',
    method: 'post',
    params
  })
}

export function addRole(params) {
  return request({
    url: '/sys/role/addRole',
    method: 'post',
    params
  })
}

export function editRole(params) {
  return request({
    url: '/sys/role/editRole',
    method: 'post',
    params
  })
}

export function removeRole(params) {
  return request({
    url: '/sys/role/removeRole',
    method: 'post',
    params
  })
}

export function saveColumnsStatus(params) {
  return request({
    url: '/sys/user/saveColumnsStatus',
    method: 'post',
    params
  })
}

export function queryColumnsStatus(params) {
  return request({
    url: '/sys/user/queryColumnsStatus',
    method: 'post',
    params
  })
}

export function queryUserList(params) {
  return request({
    url: '/sys/user/queryUserList',
    method: 'post',
    params
  })
}

export function addUser(params) {
  return request({
    url: '/sys/user/addUser',
    method: 'post',
    params
  })
}

export function editUser(params) {
  return request({
    url: '/sys/user/editUser',
    method: 'post',
    params
  })
}

export function removeUser(params) {
  return request({
    url: '/sys/user/removeUser',
    method: 'post',
    params
  })
}

export function changePwd(params) {
  return request({
    url: '/sys/user/changePwd',
    method: 'post',
    params
  })
}

export function changeConsignorPwd(params) {
  return request({
    url: '/sys/user/changeConsignorPwd',
    method: 'post',
    params
  })
}

export function resetPwd(params) {
  return request({
    url: '/sys/user/resetPwd',
    method: 'post',
    params
  })
}

export function queryMenuList(params) {
  return request({
    url: '/sys/menu/queryMenuList',
    method: 'post',
    params
  })
}

export function querySelectedMenuList(params) {
  return request({
    url: '/sys/menu/querySelectedMenuList',
    method: 'post',
    params
  })
}

export function querySelectedButtonList(params) {
  return request({
    url: '/sys/button/querySelectedButtonList',
    method: 'post',
    params
  })
}

export function savePrms(param, params) {
  return request({
    url: '/sys/menu/savePrms',
    method: 'post',
    params: param,
    data: params
  })
}

export function addCompany(params) {
  return request({
    url: '/sys/co/addCompany',
    method: 'post',
    params
  })
}

export function editCompany(params) {
  return request({
    url: '/sys/co/editCompany',
    method: 'post',
    params
  })
}

export function removeCompany(params) {
  return request({
    url: '/sys/co/removeCompany',
    method: 'post',
    params
  })
}

export function queryPrintScripts() {
  return request({
    url: '/sys/print/queryPrintScripts',
    method: 'post'
  })
}

// 部门数据管理
export function addCo(params) {
  return request({
    url: '/sys/co/addCo',
    method: 'post',
    params
  })
}

export function listCo(params) {
  return request({
    url: '/sys/co/listCo',
    method: 'post',
    params
  })
}

export function updateAutoSign(params) {
  return request({
    url: '/sys/co/updateAutoSign',
    method: 'post',
    params
  })
}

export function updateAutoLocation(params) {
  return request({
    url: '/sys/co/updateAutoLocation',
    method: 'post',
    params
  })
}

export function updateAutoDefaultWei(params) {
  return request({
    url: '/sys/co/updateAutoDefaultWei',
    method: 'post',
    params
  })
}

export function getCo(params) {
  return request({
    url: '/sys/co/getCo',
    method: 'post',
    params
  })
}

export function delCo(params) {
  return request({
    url: '/sys/co/delCo',
    method: 'post',
    params
  })
}

export function updateCo(params) {
  return request({
    url: '/sys/co/updateCo',
    method: 'post',
    params
  })
}

export function listCoByParCo(params) {
  return request({
    url: '/sys/co/listCoByParCo',
    method: 'post',
    params
  })
}

export function changeAuthori(params) {
  return request({
    url: '/sys/authori/changeAuthori',
    method: 'post',
    params
  })
}
export function queryContractList(params) {
  return request({
    url: '/sys/contract/queryContractList',
    method: 'post',
    params
  })
}
export function getPreviewURL(params) {
  return request({
    url: '/sys/contract/getPreviewURL',
    method: 'post',
    params
  })
}
export function getFileURL(params) {
  return request({
    url: '/sys/contract/getFileURL',
    method: 'post',
    params
  })
}

export function listCo2(params) {
  return request({
    url: '/sys/co/listCo2',
    method: 'post',
    params
  })
}

// export function recvParam(param1, param2) {
//   return request({
//     url: '/sys/authori/recvParam',
//     headers: {
//       'Content-Type': 'application/json;charset=UTF-8'
//     },
//     method: 'post',
//     params: param2,
//     data: param1
//   })
// }
