<template xmlns:v-popover="http://www.w3.org/1999/xhtml">
  <div>
    <!-- popover -->
    <el-popover
      ref="popover"
      v-model="visiblePopover"
      :disabled="disabled"
      popper-class="el-select-table-popper"
      placement="bottom-start"
      trigger="click"
    >
      <el-table
        ref="tab"
        :row-class-name="tableRowClassName"
        :data="opts"
        highlight-current-row
        class="el-select-table"
        size="mini"
        border
        height="300"
        style="margin-top: 8px;"
        @row-click="rowClick"
      >
        <el-table-column
          v-for="(item, index) in tableConfig"
          :key="index"
          :disabled="disabled"
          :width="item.width"
          :prop="item.keyName"
          :label="item.name"
          align="center"
          show-overflow-tooltip
        />
      </el-table>
    </el-popover>
    <div
      v-popover:popover
      class="el-select-box"
      @mouseout.stop="mouseout"
      @mouseover.stop="mouseover"
    >
      <!-- input single -->
      <div v-if="!(multiple === '' || multiple)" :class="`el-select el-select--${size}`">
        <div :class="`el-input el-input--${size} el-input--suffix`">
          <input
            ref="input"
            v-model="valSHow"
            :placeholder="placeholder ? placeholder : '请选择'"
            :readonly="readonly"
            :disabled="disabled"
            type="text"
            autocomplete="off"
            class="el-input__inner"
            @blur="inputBlur"
            @keyup.up="keyUpUp"
            @keyup.down="keyUpDown"
            @keyup.enter="keyUpEnter"
            @keyup.tab="keyUpTab"
          />
          <span class="el-input__suffix" style="margin-right: -35px;">
            <span class="el-input__suffix-inner">
              <i
                v-if="!showClose || !val || val.length === 0"
                :class="{'is-reverse':visiblePopover}"
                class="el-select__caret el-input__icon el-icon-arrow-up"
              />
              <i
                v-else
                class="el-select__caret el-input__icon el-icon-circle-close is-show-close"
                @click.stop="clearableAct"
              />
            </span>
          </span>
        </div>
      </div>
      <!-- input multiple -->
      <div v-else :class="`el-select spec-multiple-select el-select--${size}`">
        <div ref="selectTags" class="el-select__tags">
          <span>
            <span
              v-for="item in valList"
              ref="tags"
              :key="item.__ob__.dep.id"
              :class="{ 'el-tag--mini': size === 'mini' }"
              class="el-tag el-tag--info el-tag--small"
            >
              <span class="el-select__tags-text">{{ item[valName] }}</span>
              <i class="el-tag__close el-icon-close" @click.stop="deleCur(item[valKey])" />
            </span>
          </span>
        </div>
        <div :class="`el-input el-input--${size} el-input--suffix`">
          <input
            ref="input"
            :disabled="disabled"
            :placeholder="val.length === 0 ? (placeholder ? placeholder : '请选择') : ''"
            type="text"
            readonly="readonly"
            autocomplete="off"
            class="el-input__inner"
          />
          <span class="el-input__suffix">
            <span class="el-input__suffix-inner">
              <i
                v-if="!showClose || !val || val.length === 0"
                :class="{'is-reverse':visiblePopover}"
                class="el-select__caret el-input_icon el-icon-arrow-up"
              />
              <i
                v-else
                class="el-select__caret el-input__icon el-icon-circle-close is-show-close"
                @click.stop="clearableAct"
              />
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ElSelectTable',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number, // v-model接收值 多选传数组
      default: null,
    },
    size: {
      type: String,
      default: null,
    }, // 同element size
    valName: {
      type: String,
      default: '',
    }, // 显示词
    valKey: {
      type: String,
      default: null,
    }, // 值
    tableConfig: {
      type: Array,
      default: null,
    },
    options: {
      type: Array,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    }, // 是否多选
    clearable: {
      type: Boolean,
      default: false,
    }, // 是否显示清除按钮
    placeholder: {
      type: String,
      default: null,
    },
    readonly: {
      type: String,
      default: null,
    },
    filterType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showClose: false,
      visiblePopover: false,
      val: '',
      inputHeight: 0,
      opts: [],
      initOpts: [],
      newValue: null,
      tabCurrIdx: 0,
      currRow: null,
    }
  },
  computed: {
    valSHow: {
      set: function (newValue) {
        this.newValue = newValue
        const thsOpts = this.options
        this.opts = []
        for (var i = 0; i < thsOpts.length; i++) {
          if (this.opts.length >= 20) {
            throw new Error()
          }
          if (this.filterType === 'cnorNm') {
            const cnorNm = thsOpts[i].cnorNm
            if (cnorNm.indexOf(newValue) >= 0) {
              this.opts.push(thsOpts[i])
            }
          }
          if (this.filterType === 'cnorCtcts') {
            const cnorCtcts = thsOpts[i].cnorCtcts
            if (cnorCtcts.indexOf(newValue) >= 0) {
              this.opts.push(thsOpts[i])
            }
          }
          if (this.filterType === 'cnorTel') {
            const cnorTel = thsOpts[i].cnorTel
            if (cnorTel.indexOf(newValue) >= 0) {
              this.opts.push(thsOpts[i])
            }
          }
          if (this.filterType === 'cnorAddr') {
            const cnorAddr = thsOpts[i].cnorAddr
            if (cnorAddr.indexOf(newValue) >= 0) {
              this.opts.push(thsOpts[i])
            }
          }
          if (this.filterType === 'rcvrNm') {
            const rcvrNm = thsOpts[i].rcvrNm
            if (rcvrNm.indexOf(newValue) >= 0) {
              this.opts.push(thsOpts[i])
            }
          }
          if (this.filterType === 'rcvrCtcts') {
            const rcvrCtcts = thsOpts[i].rcvrCtcts
            if (rcvrCtcts.indexOf(newValue) >= 0) {
              this.opts.push(thsOpts[i])
            }
          }
          if (this.filterType === 'rcvrTel') {
            const rcvrTel = thsOpts[i].rcvrTel
            if (rcvrTel.indexOf(newValue) >= 0) {
              this.opts.push(thsOpts[i])
            }
          }
          if (this.filterType === 'rcvrAddr') {
            const rcvrAddr = thsOpts[i].rcvrAddr
            if (rcvrAddr.indexOf(newValue) >= 0) {
              this.opts.push(thsOpts[i])
            }
          }
        }
        this.resetCurrRow()
      },
      get: function () {
        if (this.newValue === null) {
          const [cur] = this.initOpts.filter((c) => {
            return c[this.valKey] === this.val
          })
          return cur ? cur[this.valName] : ''
        } else {
          return this.newValue
        }
      },
    },
    multipled() {
      return this.multiple === '' || this.multiple
    },
    clearabled() {
      return this.clearable === '' || this.clearable
    },
    valList() {
      // 多选显示列表
      const val = this.val
      return this.opts.filter((c) => {
        for (const i in val) {
          if (val[i] === c[this.valKey]) return c
        }
      })
    },
  },
  watch: {
    options(v) {
      this.opts = []
      this.initOpts = []
      for (var i = 0; i < v.length; i++) {
        this.initOpts.push(v[i])
      }
      if (v.length > 20) {
        for (var j = 0; j < 20; j++) {
          this.opts.push(v[j])
        }
      } else {
        this.opts = v
      }
      // this.initOpts = this.opts
      // this.opts = []
      // for (var j = 0; j < 10; j++) {
      //   this.opts.push(v[j])
      // }
    },
    value(v) {
      // 监听值变动
      this.evaluate(v)
    },
    val(v) {
      // 值变动执行方法
      // 多选监听高度
      this.newValue = null
      this.opts = []
      if (this.initOpts.length > 20) {
        for (var j = 0; j < 20; j++) {
          this.opts.push(this.initOpts[j])
        }
      } else {
        this.opts = this.initOpts
      }
      if (this.multipled) this.$nextTick(this.computeHeight)
      this.$emit('input', v)
      this.$nextTick(this.valid) // 表单验证
    },
    visiblePopover(v) {
      // 根据PO是否显示判定input是否聚焦
      if (v) {
        this.$refs.input.focus()
        this.resetCurrRow()
      } /*  else {
        this.$refs.input.blur()
      } */
    },
  },
  created() {
    this.evaluate(this.value) // 赋值处理
    this.$nextTick(() => {
      if (this.multipled) this.computeInitial() // 多选计算
    })
  },
  mounted() {},
  methods: {
    deleCur(val) {
      // 多选 删除当前值
      this.val.splice(this.val.indexOf(val), 1)
    },
    computeHeight() {
      // 计算多选是input高度
      if (this.$refs.tags && this.$refs.tags[0]) {
        const height = this.$refs.selectTags.clientHeight // 获取高度
        const tagHeight = this.$refs.tag[0].clientHeight // 标签获取高度 不含margin
        this.$refs.input.style.height = `${
          height + this.inputHeight - tagHeight - 6
        }px` // 计算input需要高度
      } else {
        this.$refs.input.style.height = `${this.inputHeight}px`
      }
      this.visiblePopover = !this.visiblePopover // 重置po
      this.$nextTick(() => {
        this.visiblePopover = !this.visiblePopover
      })
    },
    computeInitial() {
      // 计算页面 进来时特定的值
      const elInput = this.$refs.input
      this.inputHeight = elInput.clientHeight + 2 // 多选 获取默认input高度
      this.$refs.selectTags.style.maxWidth = `${elInput.clientWidth - 30}px` // 计算selectTags宽度
    },
    mouseover() {
      // 鼠标移入
      if (this.clearabled) this.showClose = false
    },
    mouseout() {
      // 鼠标移出
      if (this.clearabled) this.showClose = false
    },
    clearableAct() {
      // 清除数据操作
      this.val = this.multipled ? this.val.splice(0, this.val.length) : 0
      this.newValue = ''
      this.opts = this.initOpts
      this.closed()
    },
    tableRowClassName({ row, rowIndex }) {
      // 重置表格中点中样式
      if (!this.multipled && this.val === row[this.valKey])
        return 'row-selected row-reset'
      if (this.multipled && this.val.indexOf(row[this.valKey]) > -1)
        return 'row-selected row-reset'
      return 'row-reset'
    },
    rowClick(row, event, column) {
      // 行点击选择
      const val = row[this.valKey]
      if (!this.mutipled) {
        this.val = val
      } else {
        const index = this.val.indexOf(val)
        if (index > -1) {
          this.val.splice(index, 1)
        } else {
          this.val.push(val)
        }
      }
      // this.newValue = row[this.filterType]
      // this.newValue = null
      // this.setParentValue(this.valSHow)
      this.closed()
    },
    closed() {
      // 关闭并聚焦
      if (!this.multipled) this.visiblePopover = false
    },
    evaluate(v) {
      // 赋值处理
      this.val = v
    },
    valid() {
      // 表单验证
      const prop = this.$parent.prop
      if (prop) {
        this.$parent.form.validateField(prop)
      }
    },
    inputBlur() {
      this.setParentValue(this.valSHow)
      // this.closed()
    },
    keyUpUp() {
      if (this.opts.length > 0) {
        if (this.tabCurrIdx === -1) {
          this.tabCurrIdx = this.opts.length - 1
        }
        if (this.tabCurrIdx <= this.opts.length) {
          this.tabCurrIdx = this.tabCurrIdx - 1
          if (this.tabCurrIdx === -1) {
            this.tabCurrIdx = this.opts.length - 1
          }
          this.currRow = this.opts[this.tabCurrIdx]
          this.$refs.tab.setCurrentRow(this.currRow)
        }
      }
    },
    keyUpDown() {
      if (this.opts.length > 0) {
        if (this.tabCurrIdx === -1) {
          this.tabCurrIdx = 0
        }
        if (this.tabCurrIdx <= this.opts.length) {
          this.tabCurrIdx = this.tabCurrIdx + 1
          if (this.tabCurrIdx === this.opts.length) {
            this.tabCurrIdx = 0
          }
          this.currRow = this.opts[this.tabCurrIdx]
          this.$refs.tab.setCurrentRow(this.currRow)
        }
      }
    },
    keyUpEnter() {
      if (this.visiblePopover === true) {
        this.rowClick(this.currRow)
      }
    },
    keyUpTab() {
      this.visiblePopover = true
    },
    resetCurrRow() {
      this.tabCurrIdx = -1
      this.currRow = this.opts[0]
      this.$refs.tab.setCurrentRow(this.currRow)
    },
    setParentValue(data) {
      if (this.filterType === 'cnorNm') {
        this.$emit('cnorNm', data)
      }
      if (this.filterType === 'cnorCtcts') {
        this.$emit('cnorCtcts', data)
      }
      if (this.filterType === 'cnorTel') {
        this.$emit('cnorTel', data)
      }
      if (this.filterType === 'cnorAddr') {
        this.$emit('cnorAddr', data)
      }
      if (this.filterType === 'rcvrNm') {
        this.$emit('rcvrNm', data)
      }
      if (this.filterType === 'rcvrCtcts') {
        this.$emit('rcvrCtcts', data)
      }
      if (this.filterType === 'rcvrTel') {
        this.$emit('rcvrTel', data)
      }
      if (this.filterType === 'rcvrAddr') {
        this.$emit('rcvrAddr', data)
      }
    },
  },
}
</script>
<style type="text/css">
.el-select-box,
.el-select-box .el-select {
  position: relative;
  width: 100%;
}
.el-select-table .row-reset {
  cursor: pointer;
}
.el-select-table .row-selected {
  color: #409eff;
  background: rgba(64, 158, 255, 0.1);
}
.el-select-table-popper {
  padding-top: 5px;
}
.el-select-table.el-table td,
.el-select-table.el-table th {
  padding: 4px 0;
}
</style>
