import request from "@/utils/http";

export function submitBizWayBill(params) {
  return request({
    url: "/biz/way/submitBizWayBill",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}
// OCR图片识别 身份证信息识别
export function idCardScan(params) {
  return request({
    url: "/ocrScan/app/idCardScan",
    headers: {
      "content-type": "multipart/form-data"
    },
    data: params,
    method: "post"
  });
}

export function modifyBizWayBill(params) {
  return request({
    url: "/biz/way/modifyBizWayBill",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}

export function queryBizWayBillList(params) {
  return request({
    url: "/biz/way/queryBizWayBillList",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    data: params
  });
}

export function removeBizWayBill(params) {
  return request({
    url: "/biz/way/removeBizWayBill",
    method: "post",
    params
  });
}

export function reviewBizWayBill(params) {
  return request({
    url: "/biz/way/reviewBizWayBill",
    method: "post",
    params
  });
}

export function acquireBizWayBill(params) {
  return request({
    url: "/biz/way/acquireBizWayBill",
    method: "post",
    params
  });
}

export function queryBizWaybillTraceList(params) {
  return request({
    url: "/biz/trace/queryBizWaybillTraceList",
    method: "post",
    params
  });
}

export function delelteTraceWayBill(params) {
  return request({
    url: "/biz/trace/delelteTraceWayBill",
    method: "post",
    params
  });
}

export function queryBizWaybillTraceLists(params) {
  return request({
    url: "/biz/trace/queryBizWaybillTraceLists",
    method: "post",
    params
  });
}

export function delelteTrace(params) {
  return request({
    url: "/biz/trace/delelteTrace",
    method: "post",
    params
  });
}

export function uploadFile(filedata, wayId, receiptStatus, remark, receiptTime) {
  return request({
    url: "/biz/way/uploadFile",
    method: "post",
    contentType: false,
    dataType: "json",
    headers: {
      wayId: wayId,
      receiptStatus: receiptStatus,
      remark: encodeURIComponent(remark),
      receiptTime: receiptTime
    },
    data: filedata
  });
}

export function batchReceipt(filedata, wayIds, receiptStatus, remark, receiptTime) {
  return request({
    url: "/biz/way/batchReceipt",
    method: "post",
    contentType: false,
    dataType: "json",
    headers: {
      wayIds: wayIds,
      receiptStatus: receiptStatus,
      remark: encodeURIComponent(remark),
      receiptTime: receiptTime
    },
    data: filedata
  });
}

export function queryBizWaybillReceiptList(params) {
  return request({
    url: "/biz/receipt/queryBizWaybillReceiptList",
    method: "post",
    params
  });
}

export function uploadPicture(fileData) {
  return request({
    url: "/biz/way/uploadPicture",
    method: "post",
    dataType: "json",
    data: fileData
  })
}

export function queryBizWayBillEditRecord(params) {
  return request({
    url: "/biz/way/queryBizWayBillEditRecord",
    method: "post",
    params
  });
}
export function cancelReceipt(params) {
  return request({
    url: "/biz/way/cancelReceipt",
    method: "post",
    params
  });
}

export function submitBizLadingBill(params) {
  return request({
    url: "/biz/lading/submitBizLadingBill",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}

export function submitBizDeliveryBill(params) {
  return request({
    url: "/biz/delivery/submitBizDeliveryBill",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}

export function submitBizDispatchBill(params) {
  return request({
    url: "/biz/dispatch/submitBizDispatchBill",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}

export function submitBizDispatchBillAll(params) {
  return request({
    url: "/biz/dispatch/submitBizDispatchBillAll",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}

export function editBizDispatchBill(params) {
  return request({
    url: "/biz/dispatch/editBizDispatchBill",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}

export function submitBizOutsourceBill(params) {
  return request({
    url: "/biz/outsource/submitBizOutsourceBill",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}

export function submitBizOutsourceBillAll(params) {
  return request({
    url: "/biz/outsource/submitBizOutsourceBillAll",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}

export function updateOutsourceBill(params) {
  return request({
    url: "/biz/outsource/updateOutsourceBill",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}

export function submitBizTranshipBill(params) {
  return request({
    url: "/biz/tranship/submitBizTranshipBill",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}

export function submitBizTranshipBillAll(params) {
  return request({
    url: "/biz/tranship/submitBizTranshipBillAll",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}

export function editBizTranshipBill(params) {
  return request({
    url: "/biz/tranship/editBizTranshipBill",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}

export function queryBizLadingBillList(params) {
  return request({
    url: "/biz/lading/queryBizLadingBillList",
    method: "post",
    params
  });
}

export function queryLadingDriverByWayId(params) {
  return request({
    url: "/biz/lading/queryLadingDriverByWayId",
    method: "post",
    params
  });
}

export function removeBizLadingBill(params) {
  return request({
    url: "/biz/lading/removeBizLadingBill",
    method: "post",
    params
  });
}

export function modifyBizLadingBill(params) {
  return request({
    url: "/biz/lading/modifyBizLadingBill",
    method: "post",
    params
  });
}

export function checkInBizLadingBill(params) {
  return request({
    url: "/biz/lading/checkInBizLadingBill",
    method: "post",
    params
  });
}

export function checkInBizDeliveryBill(params) {
  return request({
    url: "/biz/delivery/checkInBizDeliveryBill",
    method: "post",
    params
  });
}

export function acquireBizLadingBillRelList(params) {
  return request({
    url: "/biz/lading/acquireBizLadingBillRelList",
    method: "post",
    params
  });
}

export function queryBizDeliveryBillList(params) {
  return request({
    url: "/biz/delivery/queryBizDeliveryBillList",
    method: "post",
    params
  });
}

export function queryDeliveryDriverByWayId(params) {
  return request({
    url: "/biz/delivery/queryDeliveryDriverByWayId",
    method: "post",
    params
  });
}

export function removeBizDeliveryBill(params) {
  return request({
    url: "/biz/delivery/removeBizDeliveryBill",
    method: "post",
    params
  });
}

export function queryBizOutsourceBillList(params) {
  return request({
    url: "/biz/outsource/queryBizOutsourceBillList",
    method: "post",
    params
  });
}

export function removeBizOutsourceBill(params) {
  return request({
    url: "/biz/outsource/removeBizOutsourceBill",
    method: "post",
    params
  });
}

export function mergeOutsourceBill(params) {
  return request({
    url: "/biz/outsource/mergeOutsourceBill",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}

export function mergeBillAddChild(params) {
  return request({
    url: "/biz/outsource/mergeBillAddChild",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}

export function queryBizDispatchBillList(params) {
  return request({
    url: "/biz/dispatch/queryBizDispatchBillList",
    method: "post",
    params
  });
}

export function queryBizDispatchBillTraceList(params) {
  return request({
    url: '/biz/dispatch/queryBizDispatchBillTraceList',
    method: 'post',
    params
  })
}

export function queryDispatchDriverByWayId(params) {
  return request({
    url: "/biz/dispatch/queryDispatchDriverByWayId",
    method: "post",
    params
  });
}

export function isDispatchDriverByWayId(params) {
  return request({
    url: "/biz/dispatch/isDispatchDriverByWayId",
    method: "post",
    params
  });
}

export function removeBizDispatchBill(params) {
  return request({
    url: "/biz/dispatch/removeBizDispatchBill",
    method: "post",
    params
  });
}

export function setLocatDispatchBill(params) {
  return request({
    url: "/biz/way/setLocatDispatchBill",
    method: "post",
    params
  });
}

export function setLocatDispatchBills(params) {
  return request({
    url: "/biz/way/setLocatDispatchBills",
    method: "post",
    params
  });
}

export function setLocatDispatchBill2(params) {
  return request({
    url: "/biz/way/setLocatDispatchBill2",
    method: "post",
    params
  });
}

export function modifyBizWayBillReceipt(params) {
  return request({
    url: "/biz/way/modifyBizWayBillReceipt",
    method: "post",
    params
  });
}
export function addDispatchTrace(params) {
  return request({
    url: "/biz/trace/addDispatchTrace",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}

export function addDispatchTraceList(params) {
  return request({
    url: "/biz/trace/addDispatchTraceList",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}

export function queryLocationData(params) {
  return request({
    url: "/biz/way/queryLocationData",
    method: "post",
    params
  });
}

export function queryLocationData2(params) {
  return request({
    url: "/biz/way/queryLocationData2",
    method: "post",
    params
  });
}
export function insertLocationdata(params) {
  return request({
    url: "/location/insertLocationdata",
    method: "post",
    params
  });
}

export function queryLocationDataForDisp(params) {
  return request({
    url: "/biz/way/queryLocationDataForDisp",
    method: "post",
    params
  });
}

export function reportGpsForOrder(params) {
  return request({
    url: "/fin/customer/reportGpsForOrder",
    method: "post",
    params
  });
}

export function queryLocationDataZY(params) {
  return request({
    url: "/biz/way/queryLocationDataZY",
    method: "post",
    params
  });
}

export function queryBillDetailLocationData(params) {
  return request({
    url: "/biz/way/queryBillDetailLocationData",
    method: "post",
    params
  });
}

export function queryBizTranshipBillList(params) {
  return request({
    url: "/biz/tranship/queryBizTranshipBillList",
    method: "post",
    params
  });
}

export function queryBizTranshipDriverByWayId(params) {
  return request({
    url: "/biz/tranship/queryBizTranshipDriverByWayId",
    method: "post",
    params
  });
}

export function removeBizTranshipBill(params) {
  return request({
    url: "/biz/tranship/removeBizTranshipBill",
    method: "post",
    params
  });
}

export function acquireBizDeliveryBillRelList(params) {
  return request({
    url: "/biz/delivery/acquireBizDeliveryBillRelList",
    method: "post",
    params
  });
}

export function acquireBizDispatchBillRelList(params) {
  return request({
    url: "/biz/dispatch/acquireBizDispatchBillRelList",
    method: "post",
    params
  });
}

export function queryDriverFeeAllList(params) {
  return request({
    url: "/biz/dispatch/queryDriverFeeAllList",
    method: "post",
    params
  });
}

export function acquireBizOutsourceBillRelList(params) {
  return request({
    url: "/biz/outsource/acquireBizOutsourceBillRelList",
    method: "post",
    params
  });
}

export function checkInBizDispatchBill(params) {
  return request({
    url: "/biz/dispatch/checkInBizDispatchBill",
    method: "post",
    params
  });
}

export function acquireBizTranshipBillRelList(params) {
  return request({
    url: "/biz/tranship/acquireBizTranshipBillRelList",
    method: "post",
    params
  });
}

export function addBizWaybillTrace(params) {
  return request({
    url: "/biz/trace/addBizWaybillTrace",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}

export function batchAddBizWaybillTrace(params) {
  return request({
    url: "/biz/trace/batchAddBizWaybillTrace",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}

export function queryWaybillTraceRefList(params) {
  return request({
    url: "/biz/trace/queryWaybillTraceRefList",
    method: "post",
    params
  });
}

export function exportWaybillMngExcel(params) {
  return request({
    url: "/biz/way/exportWaybillMngExcel",
    method: "post",
    responseType: "blob",
    params
  });
}

export function exportPlanBillExcel(params) {
  return request({
    url: "/biz/plan/exportPlanBillExcel",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    data: params
  });
}

export function exportPlanbill4Fin(params) {
  return request({
    url: "/biz/plan/exportPlanBillFinance",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}

export function downloadExcelPlanbill(params) {
  return request({
    url: '/biz/plan/downloadExcelPlanbill',
    method: 'post',
    responseType: 'blob',
    params
  })
}

export function queryPlanbillExcelDownloadStatus(params) {
  return request({
    url: '/biz/plan/queryPlanbillExcelDownloadStatus',
    method: 'get',
    params
  })
}

export function updateBizWayBill(params) {
  return request({
    url: "/biz/way/updateBizWayBill",
    method: "post",
    params
  });
}
// 添加评价
export function insertBizWaybillComment(params) {
  return request({
    url: "/biz/way/insertBizWaybillComment",
    method: "post",
    params
  });
}
// 查询评价
export function queryBizWaybillComment(params) {
  return request({
    url: "/biz/way/queryBizWaybillComment",
    method: "post",
    params
  });
}
// 添加投诉建议标题
export function insertSuggest(params) {
  return request({
    url: "/biz/way/insertSuggest",
    method: "post",
    params
  });
}
// 投诉建议标题查询
export function querySuggest(params) {
  return request({
    url: "/biz/way/querySuggest",
    method: "post",
    params
  });
}
// 添加投诉内容详情
export function insertSuggestDetail(params) {
  return request({
    url: "biz/way/insertSuggestDetail",
    method: "post",
    params
  });
}
// 查询投诉建议详情内容
export function querySuggestDetail(params) {
  return request({
    url: "biz/way/querySuggestDetail",
    method: "post",
    params
  });
}

export function updateEnquiryStatus(params) {
  return request({
    url: "/biz/way/updateEnquiryStatus",
    method: "post",
    params
  });
}

export function updateBizWayBillNetWork(params) {
  return request({
    url: "/biz/way/updateBizWayBillNetWork",
    method: "post",
    params
  });
}

export function countNetworkWayBill(params) {
  return request({
    url: "/biz/way/countNetworkWayBill",
    method: "post",
    params
  });
}

export function countNetworkInWayBill(params) {
  return request({
    url: "/biz/way/countNetworkInWayBill",
    method: "post",
    params
  });
}

export function getNetWorkWayBill(params) {
  return request({
    url: "/biz/way/getNetWorkWayBill",
    method: "post",
    params
  });
}

export function getNetWorkWayBillFin(params) {
  return request({
    url: "/biz/way/getNetWorkWayBillFin",
    method: "post",
    params
  });
}

export function queryEnquiryList(params) {
  return request({
    url: "/biz/enquiry/queryEnquiryList",
    method: "post",
    params
  });
}
export function removeFile(params) {
  return request({
    url: "/biz/trace/removeFile",
    method: "post",
    params
  });
}

export function downloadFile(params) {
  return request({
    url: "/biz/trace/downloadFile",
    method: "post",
    params,
    responseType: "blob"
  });
}

// 计划单列表
export function queryPlanBill(param1, param2) {
  return request({
    url: "/biz/plan/queryPlanBill",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    data: param1,
    params: param2
  });
}
// 计划单列表汇总
export function queryPlanBillSum(data) {
  return request({
    url: "/biz/plan/queryPlanBillSum",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    data
  });
}

// 计划单明细信息
export function queryPlanDetail(params) {
  return request({
    url: "/biz/way/queryPlanDetail",
    method: "post",
    params
  });
}
// 计划单相关操作
export function submitPlanBill(params) {
  return request({
    url: "/biz/plan/submitPlanBill",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}
/* 订单生成计划单 */
export function generatePlanBill(params) {
  return request({
    url: "/biz/plan/generatePlanBill",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}

export function selectByPlanIdAndStrtAndDest(params) {
  return request({
    url: "/biz/plan/selectByPlanIdAndStrtAndDest",
    method: "post",
    params
  });
}

export function modifyPlanBill(params) {
  return request({
    url: "/biz/plan/modifyPlanBill",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}

export function mergePlanBill(params) {
  return request({
    url: "/biz/plan/mergePlanBill",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}

export function downloadTemplate(params) {
  return request({
    url: "/biz/plan/downloadTemplate",
    method: "post",
    responseType: "arraybuffer",
    params
  });
}
export function loadChildren(params) {
  return request({
    url: "/biz/plan/loadChildren",
    method: "post",
    params
  });
}
export function removePlanbill(params) {
  return request({
    url: "/biz/plan/removePlanbill",
    method: "post",
    params
  });
}
export function saveSplitPlanBill(params) {
  return request({
    url: "/biz/plan/saveSplitPlanBill",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}
export function acquirePlanBill(params) {
  return request({
    url: "/biz/plan/acquirePlanBill",
    method: "post",
    params
  });
}

export function getCustPrice(params) {
  return request({
    url: "/biz/plan/getCustPrice",
    method: "post",
    params
  });
}

export function submitPlanBillExcel(params) {
  return request({
    url: "/biz/plan/submitPlanBillExcel",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}

// 异常费用
export function insertAbnormalExpenses(params) {
  return request({
    url: "/biz/abnorExp/insertAbnormalExpenses",
    method: "post",
    params
  });
}

export function queryAbnormalExpenses(params) {
  return request({
    url: "/biz/abnorExp/queryAbnormalExpenses",
    method: "post",
    params
  });
}
export function updateAbnormalExpenses(params) {
  return request({
    url: "/biz/abnorExp/updateAbnormalExpenses",
    method: "post",
    params
  });
}
export function delAbnormalExpenses(params) {
  return request({
    url: "/biz/abnorExp/delAbnormalExpenses",
    method: "post",
    params
  });
}
export function submitExamineAbnormalExpenses(params) {
  return request({
    url: "/biz/abnorExp/submitExamineAbnormalExpenses",
    method: "post",
    params
  });
}
export function settlementAbnormalExpenses(params) {
  return request({
    url: "/biz/abnorExp/settlementAbnormalExpenses",
    method: "post",
    params
  });
}
export function queryBill(params) {
  return request({
    url: "/biz/abnorExp/queryBill",
    method: "post",
    params
  });
}

export function queryBillByWayId(params) {
  return request({
    url: "/biz/abnorExp/queryBillByWayId",
    method: "post",
    params
  });
}

export function examine(params) {
  return request({
    url: "/biz/abnorExp/examine",
    method: "post",
    params
  });
}

export function queryOrderBillList(params) {
  return request({
    url: "/biz/order/queryOrderBillList",
    method: "post",
    params
  });
}

export function batchGenerationPlanBill(params) {
  return request({
    url: "/biz/order/batchGenerationPlanBill",
    header: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}

/* 删除订单 */
export function deleteOrderBill(params) {
  return request({
    url: "/biz/order/deleteOrderBill",
    method: "post",
    params
  });
}

export function updateBizDispatchBillList(params) {
  return request({
    url: "/biz/dispatch/updateBizDispatchBillList",
    method: "post",
    params
  });
}

export function synchConfirmOutsource(params) {
  return request({
    url: "/biz/outsource/synchConfirmOutsource",
    method: "post",
    params
  });
}

export function synchConfirmLading(params) {
  return request({
    url: "/biz/lading/synchConfirmLading",
    method: "post",
    params
  });
}

export function synchConfirmDelivery(params) {
  return request({
    url: "/biz/delivery/synchConfirmDelivery",
    method: "post",
    params
  });
}

export function synchConfirmTranship(params) {
  return request({
    url: "/biz/tranship/synchConfirmTranship",
    method: "post",
    params
  });
}

export function synchConfirmDispatch(params) {
  return request({
    url: "/biz/dispatch/synchConfirmDispatch",
    method: "post",
    params
  });
}

export function checkBaseVehicle(params) {
  return request({
    url: "/base/vehicle/checkBaseVehicle",
    method: "post",
    params
  });
}

export function batchGenerationWayBill(params) {
  return request({
    url: "/biz/plan/batchGenerationWayBill",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}

export function updatePlanBillStatusToConfirm(params) {
  return request({
    url: "/biz/plan/updatePlanBillStatusToConfirm",
    method: "post",
    params
  });
}
export function updateCnorNbrByplanId(params) {
  return request({
    url: "/biz/plan/updateCnorNbr",
    method: "post",
    params
  });
}
export function getPlanBillCollectData(params) {
  return request({
    url: "/biz/plan/getPlanBillCollectData",
    method: "post",
    params
  });
}
export function updateCnorNbrByWayId(params) {
  return request({
    url: "/biz/way/updateCnorNbrByWayId",
    method: "post",
    params
  });
}
export function acquireBizDispatchBill(params) {
  return request({
    url: "/biz/dispatch/acquireBizDispatchBill",
    method: "post",
    params
  });
}

export function queryBizBillModifyDetailList(params) {
  return request({
    url: "/biz/modifyRecord/queryBizBillModifyDetailList",
    method: "post",
    params
  });
}

export function queryModifyDetailList(params) {
  return request({
    url: "/biz/modifyRecord/queryModifyDetailList",
    method: "post",
    params
  });
}

export function updateReadUsr(params) {
  return request({
    url: "/biz/modifyRecord/updateReadUsr",
    method: "post",
    params
  });
}

export function queryPlanPrice(params) {
  return request({
    url: "/biz/dispatch/queryPlanPrice",
    method: "post",
    params
  });
}

export function updateBillWayFee(params) {
  return request({
    url: "/biz/dispatch/updateBillWayFee",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    data: params
  });
}
export function backSchedulingForDisp(params) {
  return request({
    url: "/biz/dispatch/backSchedulingForDisp",
    method: "post",
    params
  });
}
export function backSchedulingForTrans(params) {
  return request({
    url: "/biz/tranship/backSchedulingForTrans",
    method: "post",
    params
  });
}
export function backSchedulingForOut(params) {
  return request({
    url: "/biz/outsource/backSchedulingForOut",
    method: "post",
    params
  });
}
export function queryOutsourceBillByWayId(params) {
  return request({
    url: "/biz/outsource/queryOutsourceBillByWayId",
    method: "post",
    params
  });
}
export function editDispatch4OK(params) {
  return request({
    url: "/biz/dispatch/editDispatch4OK",
    method: "post",
    params
  });
}
export function editTrans4OK(params) {
  return request({
    url: "/biz/tranship/editTrans4OK",
    method: "post",
    params
  });
}

export function queryAllDisp(params) {
  return request({
    url: "/biz/way/queryAllDisp",
    method: "post",
    params
  });
}

export function synchDispatch() {
  return request({
    url: '/biz/dispatch/synchDispatch',
    method: 'post'
  })
}
