<template>
    <center-dialog
      :visible.sync="visible"
      :title="title"
      :destroyClose="true"
      @close="resetForm"
      top="20vh"
      type="alert"
    >
      <el-form 
        ref="form"
        :model="form"
        size="mini"
        label-width="90px"
        :disabled="disabled"
      >
        <el-row>
          <el-col :span="span">
            <el-form-item label="厂家单号" prop="cnorNbr">
              <el-input
                v-model.trim="form.cnorNbr"
                placeholder="请输入厂家单号"
                clearable
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="span">
            <el-form-item label="运单号" prop="wayId">
              <el-input
                v-model.trim="form.wayId"
                placeholder="请输入厂家单号"
                style="width: 100%"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="span">
            <el-form-item label="收货单位" prop="rcvrNm">
              <el-input
                v-model.trim="form.rcvrNm"
                placeholder="请输入收货单位"
                style="width: 100%"
                clearable
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="span">
            <el-form-item label="收货人" prop="rcvrCtcts">
              <el-input
                v-model.trim="form.rcvrCtcts"
                placeholder="请输入收货人"
                style="width: 100%"
                clearable
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="preview-pic">
        <div class="left">
          <el-carousel
            ref="carousel"
            initial-index="0"
            height="200px"
            arrow="hover"
            :autoplay="false"
            @change="handleCurrentImage"
            indicator-position="none"
          >
            <el-carousel-item
              v-for="item in (type === 'abnormal' ? form.picName : form.fileList)"
              :key="item"
              class="preview-carousel-item"
            >
              <el-image
                class="preview-img"
                :src="(type === 'abnormal' ? `${fileDomain}${item}` : `${fileDomain}${item}`)"
                :preview-src-list="[(type === 'abnormal' ? `${fileDomain}${item}` : `${fileDomain}${item}`)]"
              ></el-image>
            </el-carousel-item>
          </el-carousel>
          <!-- <el-image
            :src="currentImage ||
              (type === 'abnormal' ? `${fileDomain}${form.picName[0]}` : `${fileDomain}${form.fileList[0]}`)"
            :preview-src-list="currentImage ? [currentImage] : [(type === 'abnormal' ? `${fileDomain}${form.picName[0]}` : `${fileDomain}${form.fileList[0]}`)]"
          ></el-image> -->
        </div>
        <div class="right">
          <div class="right-container">
            <template v-if="type === 'abnormal'">
              <el-image
                class="preview-image"
                :class="{active: currentIndex === index}"
                v-for="(item, index) in form.picName"
                :key="index"
                :src="`${fileDomain}${item}`"
                :fit="contain"
                @click.stop="handleClickImage(index, item)"
              ></el-image>
            </template>
            <template v-else>
              <el-image
                class="preview-image"
                :class="{active: currentIndex === index}"
                v-for="(item, index) in form.fileList"
                :key="index"
                :src="`${fileDomain}${item}`"
                @click.stop="handleClickImage(index, item)"
              ></el-image>
            </template>
          </div>
        </div>
      </div>
    </center-dialog>

</template>

<script>
import CenterDialog from '@/components/Common/CenterDialog.vue'
export default {
  components: {
    CenterDialog
  },
  props: {
    disabled: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
    }
  },
  data() {
    return {
      visible: false,
      
      span: 12,
      form: {
        fileList: [],
        picName: []
      },
      currentImage: '', // 当前选中的图片
      currentIndex: 0, // 当前选中的图片
      fileDomain: process.env.VUE_APP_FILE_URL
    }
  },
  mounted () {
    
  },
  methods: {
    async resetForm() {
      // await this.$nextTick()
      this.currentIndex = 0
      this.$refs['form'].resetFields()
    },
    // 预览图片
    handleClickImage(index, item) {
      this.currentIndex = index
      this.$refs.carousel.setActiveItem(index)
    },
    handleCurrentImage(current) {
      this.currentIndex = current
    }
  }
}
</script>

<style scoped lang="scss">
.preview-pic {
  width: 100%;
  height: 200px;
  display: flex;
  // justify-content: center;
  align-items: center;
  .left {
    width: 90%;
    height: 100%;
    
    .preview-carousel-item {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .preview-img {
      height: 100%;
    }

  }
  .right {
    display: flex;
    // align-items: center;
    flex-direction: column;
    width: 10%;
    height: 100%;
    .right-container {
      overflow: auto;
    }
    .preview-image {
      width: 70%;
      margin-bottom: 10px;
    }
    .active {
      width: 90%;
    }
  }
}

</style>