<template>
  <div>
    <el-select
      v-model="areaTemp"
      :remote-method="remoteMethod"
      :class="{ validFieldClass : validFlag }"
      :size="size"
      :placeholder="placeholder"
      :clearable="canClear"
      :disabled="disabled"
      :style="styles"
      value-key="aeId"
      filterable
      remote
      class="input-cls"
      @focus="initCity"
      @change="changeMethod"
      @clear="clearMethod"
    >
      <el-option
        v-for="item in options"
        :key="item.aeId"
        :label="item.aeName"
        :value="item"
      >
        <span style="float: left">{{ item.aeName }}</span>
        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.dtlName }}</span>
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { queryAllAreaList, queryStartCityOptions, queryDestCityOptions } from '@/api/base'
export default {
  name: 'ElSelectCity',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: null
    },
    defaultAeId: {
      type: Object,
      default: null
    },
    validFlag: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'mini'
    },
    placeholder: {
      type: String,
      default: '选择城市'
    },
    styles: {
      type: String,
      default: 'width: 100%;'
    },
    canClear: {
      type: Boolean,
      default: false
    },
    /* 发站城市，用于记忆用户输入的发站城市信息,值可取strt */
    startCity: {
      type: String,
      default: ''
    },
    /* 到站城市，用于记忆用户输入的到站城市信息，值可取dest */
    destCity: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      initOptions: [],
      options: [],
      aeId: null,
      areaTemp: {
        aeId: null,
        aeLevel: null,
        aeName: null,
        aeParCode: null,
        dtlName: null
      },
      startCityOptions: [],
      destCityOptions: []
    }
  },
  watch: {
    defaultAeId(v) {
      if (v != null) {
        this.options = []
        this.initOptions.forEach((item) => {
          if (item.aeId === v.aeId) {
            this.options.push(item)
            this.areaTemp = item
            this.$emit('input', this.areaTemp)
          }
        })
      }
    }
  },
  created() {
    this.queryStartCityOptions()
    this.queryDestCityOptions()
  },
  mounted() {
    if (this.$store.getters.areas.length < 1) {
      this.queryAllAreaList()
    } else {
      this.initOptions = this.$store.getters.areas
      if (this.defaultAeId != null) {
        if (this.defaultAeId.aeId > 0) {
          this.initOptions.forEach((item) => {
            if (item.aeId === this.defaultAeId.aeId) {
              this.options.push(item)
              this.areaTemp = item
              this.$emit('input', this.areaTemp)
            }
          })
        }
      }
    }
  },
  methods: {
    queryAllAreaList() {
      queryAllAreaList().then(response => {
        if (response.code === '000') {
          this.$store.commit('SET_AREAS', response.data)
          this.initOptions = response.data
          if (this.defaultAeId != null) {
            if (this.defaultAeId.aeId > 0) {
              this.initOptions.forEach((item) => {
                if (item.aeId === this.defaultAeId.aeId) {
                  this.options.push(item)
                  this.areaTemp = item
                  this.$emit('input', this.areaTemp)
                }
              })
            }
          }
        }
      })
    },
    remoteMethod(query) {
      if (query !== '') {
        this.options = this.initOptions.filter(item => {
          return item.aeName.indexOf(query) > -1
        })
      } else {
        this.options = []
      }
    },
    initCity() {
      if (this.startCity === 'strt') {
        this.options = this.startCityOptions
      }
      if (this.destCity === 'dest') {
        this.options = this.destCityOptions
      }
    },
    queryStartCityOptions() {
      const params = {
        maxSize: 5
      }
      queryStartCityOptions(params).then(response => {
        if (response.code === '000') {
          this.startCityOptions = response.data
        }
      })
    },
    queryDestCityOptions() {
      const params = {
        maxSize: 5
      }
      queryDestCityOptions(params).then(response => {
        if (response.code === '000') {
          this.destCityOptions = response.data
        }
      })
    },
    changeMethod(val) {
      this.$emit('input', val)
    },
    clearMethod() {
      this.$emit('input', null)
    }
  }

}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
  .input-cls {
    /*width: 260px;*/
    font-size: 14px;
  }
</style>
