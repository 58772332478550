<template>
  <div class="app-container">
    <div
      v-if="opt === 'edit' || opt === 'create' || opt === 'detail' || opt==='checkIn' || opt==='generator'"
      class="main-toolbar"
    >
    </div>
    <div id="appmain_section" class="main-body">
      <div class="left">
        <header class="order-editor-header">
          <h1>货物托运单</h1>
          <div class="fl">
            <el-date-picker
              v-model="waybill.sendDt"
              :disabled="true"
              :class="{ validFieldClass : validFields.sendDt }"
              class="waybill waybill11"
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              placeholder="日期时间"
              size="mini"
            />
          </div>
          <div class="fr">
            <label class="fn-label">运单号：</label>
            <el-input
              v-model="waybill.wayIdsub"
              :class="{ validFieldClass : validFields.wayId }"
              disabled
              type="text"
              style="width: 180px;"
              class="waybillid waybill"
              size="mini"
            />
          </div>
        </header>

        <div class="order-content">
          <div class="order-editor-traffic">
            <el-row :gutter="10">
              <el-col :span="2" class="label_right">
                <label>发 站：</label>
              </el-col>
              <el-col :span="3">
                <waybill-select-city
                  v-model="waybill.strtList"
                  :disabled="opt==='checkIn' || opt==='detail' || (opt === 'edit' && checkingFlag)"
                  :default-ae-id="waybill.strtList"
                  :valid-flag="validFields.strtList"
                  start-city="strt"
                  readonly
                  class="waybill waybill2"
                />
                <!--                <el-cascader v-model="strt" :options="strts" :show-all-levels="false" :class="{ validFieldClass : validFields.strt }" filterable placeholder="出发城市" class="waybill" size="mini" @change="changeStrtCity"/>-->
              </el-col>
              <el-col :span="2" class="label_right line_col_l">
                <label>到 站：</label>
              </el-col>
              <el-col :span="3">
                <waybill-select-city
                  v-model="waybill.destList"
                  :disabled="opt==='checkIn' || opt==='detail' || (opt === 'edit' && checkingFlag)"
                  :default-ae-id="waybill.destList"
                  :valid-flag="validFields.destList"
                  dest-city="dest"
                  class="waybill waybill2"
                />
                <!--                <el-cascader v-model="dest" :options="dests" :show-all-levels="false" :class="{ validFieldClass : validFields.dest }" filterable placeholder="到达城市" class="waybill" size="mini" @change="changeDestCity"/>-->
              </el-col>
              <el-col :span="2" class="label_right line_col_l">
                <label>运输类型：</label>
              </el-col>
              <el-col :span="3">
                <el-select
                  v-model="waybill.transTp"
                  :disabled="opt==='checkIn' || opt==='detail' || (opt === 'edit' && checkingFlag)"
                  placeholder="运输类型"
                  class="waybill"
                  size="mini"
                  @change="changeTransTp"
                >
                  <el-option
                    v-for="item in transTps"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-col>
              <!--<el-col :span="2" class="label_right line_col_l">
                <label>运单类型：</label>
              </el-col>
              <el-col :span="3">
                <el-select v-model="waybill.wayTp" :disabled="opt==='checkIn' || wayTpDisabled || opt==='generator' || opt==='edit'" placeholder="运单类型" class="waybill" size="mini" @change="changeWayTp">
                  <el-option
                    v-for="item in wayTps"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    :hidden="item.hidden"
                  />
                </el-select>
              </el-col>-->
              <el-col :span="2" class="label_right line_col_l">
                <label>厂家单号：</label>
              </el-col>
              <el-col :span="3">
                <el-input
                  v-model="waybill.cnorNbr"
                  :disabled="opt==='checkIn' || opt==='detail' || (opt === 'edit' && checkingFlag)"
                  type="text"
                  class="waybill"
                  size="mini"
                  placeholder="厂家单号"
                />
              </el-col>
            </el-row>
          </div>
          <div class="order-editor-traffic2">
            <el-row :gutter="10">
              <template v-if="waybill.transTp !== '2'">
                <el-col :span="2" class="label_right line_col_l">
                  <label>交货方式：</label>
                </el-col>
                <el-col :span="3">
                  <el-select
                    v-model="waybill.recvTp"
                    :disabled="opt==='checkIn' || opt==='detail' || (opt === 'edit' && checkingFlag)"
                    placeholder="交货方式"
                    class="waybill"
                    size="mini"
                  >
                    <el-option
                      v-for="item in recvTps"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-col>
              </template>
              <template v-if="waybill.transTp === '2'">
                <el-col :span="2" class="label_right line_col_l">
                  <label>车型：</label>
                </el-col>
                <el-col :span="3">
                  <el-select
                    v-model="waybill.carTp"
                    :disabled="opt==='checkIn' || opt==='detail' || (opt === 'edit' && checkingFlag)"
                    placeholder="车型"
                    class="waybill"
                    size="mini"
                  >
                    <el-option
                      v-for="item in carTps"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-col>
              </template>
              <el-col :span="2" class="label_right line_col_l">
                <label>退货：</label>
              </el-col>
              <el-col :span="3">
                <el-select
                  v-model="waybill.urgencyTp"
                  :disabled="opt==='checkIn' || opt==='detail' || (opt === 'edit' && checkingFlag)"
                  placeholder="退货"
                  class="waybill"
                  size="mini"
                >
                  <el-option
                    v-for="item in urgencyTps"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-col>
              <el-col :span="2" class="label_right line_col_l">
                <label>&nbsp;</label>
              </el-col>
              <el-col :span="3" />
            </el-row>
          </div>
        </div>

        <div v-if="waybill.wayTp!=='2'" class="order-content">
          <div class="order-editor-traffic">
            <el-row :gutter="10" style="padding-left: 5px;">
              <el-col :span="2" class="fhr_shr">
                <i class="el-icon-s-promotion" />
                <br />
                <span>发货方</span>
              </el-col>
              <el-col :span="10">
                <el-row>
                  <el-col :span="3" class="label_right2">
                    <label>发货单位：</label>
                  </el-col>
                  <el-col :span="19">
                    <select-table
                      v-model="cnorId"
                      :disabled="true || opt==='checkIn' ||opt==='detail' || (opt === 'edit' && checkingFlag)"
                      :table-config="cnorTableConfig"
                      :options="cnorOptions"
                      :class="{ validFieldClass : validFields.cnorNm }"
                      class="waybillAcceptShipper input_span1"
                      
                      size="mini"
                      val-key="cnorId"
                      val-name="cnorNm"
                      clearable
                      placeholder="发货单位"
                      filter-type="cnorNm"
                      @cnorNm="setCnorNmInputVal"
                    />
                  </el-col>
                </el-row>
                <el-row>
                  <el-row>
                    <el-col :span="3" class="label_right2">
                      <label>发货人：</label>
                    </el-col>
                    <el-col :span="19">
                      <select-table
                        v-model="cnorContactId"
                        :disabled="opt==='checkIn' || opt==='detail' || (opt === 'edit' && checkingFlag)"
                        :table-config="cnorCtctsConfig"
                        :options="cnorCtOptions"
                        :class="{ validFieldClass : validFields.cnorCtcts }"
                        class="waybillAcceptShipper input_span1"
                        
                        size="mini"
                        val-key="cnorContactId"
                        val-name="cnorCtcts"
                        clearable
                        placeholder="发货人"
                        filter-type="cnorCtcts"
                        @cnorCtcts="setCnorCtctsInputVal"
                      />
                    </el-col>
                  </el-row>
                </el-row>
                <el-row>
                  <el-row>
                    <el-col :span="3" class="label_right2">
                      <label>发货电话：</label>
                    </el-col>
                    <el-col :span="19">
                      <select-table
                        v-model="cnorContactId"
                        :disabled="opt==='checkIn' || opt==='detail' || (opt === 'edit' && checkingFlag)"
                        :table-config="cnorCtctsConfig"
                        :options="cnorCtOptions"
                        :class="{ validFieldClass : validFields.cnorTel }"
                        class="waybillAcceptShipper input_span1"
                        
                        size="mini"
                        val-key="cnorContactId"
                        val-name="cnorTel"
                        clearable
                        placeholder="发货电话"
                        filter-type="cnorTel"
                        @cnorTel="setCnorTelInputVal"
                      />
                    </el-col>
                  </el-row>
                </el-row>
                <el-row>
                  <template v-for="(obj, index2) in waybill.cnorAddrs">
                    <el-row :key="index2">
                      <el-col :span="3" class="label_right2">
                        <label>发货地址：</label>
                      </el-col>
                      <el-col :span="19">
                        <el-autocomplete
                          style="width: 100%;"
                          id="cnorAddrInput"
                          v-model="obj.cnorAddrNm"
                          :disabled="opt==='detail' || (opt === 'edit' && checkingFlag)"
                          :fetch-suggestions="querySearchCnorAdd"
                          class="waybillAcceptShipper input_span1"
                          placeholder="发货地址"
                          size="mini"
                          value-key="cnorAddrNm"
                          @select="handleSelectCnorAddrId"
                        >
                          <el-button
                            slot="append"
                            size="mini"
                            icon="el-icon-map-location"
                            
                          />
                        </el-autocomplete>
                      </el-col>
                    </el-row>
                  </template>

                </el-row>
                <el-row>
                  <el-row>
                    <template v-for="(obj, index2) in waybill.cnorAddrs">
                      <el-row :key="index2">
                        <el-col :span="19" :offset="5" style="margin-top: 4px">
                          <span>经度:{{ obj.lat===''?'无':obj.lng }}</span>
                          <span style="margin-left: 230px;">纬度:{{ obj.lng===''?'无':obj.lat }}</span>
                        </el-col>
                      </el-row>
                    </template>
                  </el-row>

                </el-row>
              </el-col>
              <el-col :span="2" class="fhr_shr">
                <i class="el-icon-s-custom" />
                <br />
                <span>收货方</span>
              </el-col>
              <el-col :span="10">
                <el-row>
                  <el-col :span="3" class="label_right2">
                    <label>收货单位：</label>
                  </el-col>
                  <el-col :span="19">
                    <select-table
                      v-model="rcvrId"
                      :disabled="opt==='detail' ||(opt==='checkIn' || (opt === 'edit' && checkingFlag))"
                      :table-config="rcvrTableConfig"
                      :options="rcvrOptions"
                      :class="{ validFieldClass : validFields.rcvrNm }"
                      class="waybillAcceptShipper input_span1"
                     
                      size="mini"
                      val-key="rcvrId"
                      val-name="rcvrNm"
                      clearable
                      placeholder="收货单位"
                      filter-type="rcvrNm"
                      @rcvrNm="setRcvrNmInputVal"
                    />
                  </el-col>
                </el-row>
                <el-row>
                  <el-row>
                    <el-col :span="3" class="label_right2">
                      <label>收货人：</label>
                    </el-col>
                    <el-col :span="19">
                      <select-table
                        v-model="rcvrContactId"
                        :disabled="opt==='detail' || (opt==='checkIn' || (opt === 'edit' && checkingFlag))"
                        :table-config="rcvrCtctsTableConfig"
                        :options="rcvrCtOptions"
                        :class="{ validFieldClass : validFields.rcvrCtcts }"
                        class="waybillAcceptShipper input_span1"
                        
                        size="mini"
                        val-key="rcvrContactId"
                        val-name="rcvrCtcts"
                        clearable
                        placeholder="收货人"
                        filter-type="rcvrCtcts"
                        @rcvrCtcts="setRcvrCtctsInputVal"
                      />
                    </el-col>
                  </el-row>
                </el-row>
                <el-row>
                  <el-row>
                    <el-col :span="3" class="label_right2">
                      <label>收货电话：</label>
                    </el-col>
                    <el-col :span="19">
                      <select-table
                        v-model="rcvrContactId"
                        :disabled="opt==='detail' || (opt==='checkIn' || (opt === 'edit' && checkingFlag))"
                        :table-config="rcvrCtctsTableConfig"
                        :options="rcvrCtOptions"
                        :class="{ validFieldClass : validFields.rcvrTel }"
                        class="waybillAcceptShipper input_span1"
                        
                        size="mini"
                        val-key="rcvrContactId"
                        val-name="rcvrTel"
                        clearable
                        placeholder="收货电话"
                        filter-type="rcvrTel"
                        @rcvrTel="setRcvrTelInputVal"
                      />
                    </el-col>
                  </el-row>
                </el-row>
                <el-row>
                  <template v-for="(obj, index) in waybill.rcvrAddrs">
                    <el-row :key="index">
                      <el-col :span="3" class="label_right2">
                        <label>收货地址：</label>
                      </el-col>
                      <el-col :span="19">
                        <el-autocomplete
                          style="width: 100%;"
                          v-model="obj.addrNm"
                          :disabled="opt === 'detail' || (opt === 'edit' && checkingFlag)"
                          :fetch-suggestions="querySearch"
                          class="waybillAcceptShipper input_span1"
                          placeholder="收货地址"
                          size="mini"
                        >
                          <el-button
                            slot="append"
                            size="mini"
                            icon="el-icon-map-location"
                            disabled
                            @click="showMap"
                          />
                        </el-autocomplete>
                      </el-col>
                    </el-row>
                  </template>
                </el-row>
                <el-row>
                  <template v-for="(obj, index) in waybill.rcvrAddrs">
                    <el-row :key="index">
                      <el-col :span="19" :offset="5" style="margin-top: 4px">
                        <span>经度:{{ obj.lat===''?'无':obj.lng }}</span>
                        <span style="margin-left: 230px;">纬度:{{ obj.lng===''?'无':obj.lat }}</span>
                      </el-col>
                    </el-row>
                  </template>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>

        <div class="order-content">
          <div class="order-editor-traffic">
            <el-row>
              <el-col :span="1" class="row_header_col1">操作</el-col>
              <el-col :span="2" class="row_header_col">货物名称</el-col>
              <el-col :span="2" class="row_header_col">包装方式</el-col>
              <el-col :span="2" class="row_header_col">货号</el-col>
              <el-col :span="2" class="row_header_col">货物编号</el-col>
              <el-col :span="2" class="row_header_col">型号</el-col>
              <el-col :span="2" class="row_header_col">数量（件）</el-col>
              <el-col :span="4" class="row_header_col">净重（吨）</el-col>
              <el-col :span="4" class="row_header_col">毛重（吨）</el-col>
              <el-col :span="3" class="row_header_col">体积（方）</el-col>
            </el-row>
            <template v-for="(obj, index) in cargoInsts">
              <el-row :key="index">
                <el-col v-if="obj.optFlg" :span="1" class="row_table_col1">
                  <i class="el-icon-circle-plus" @click="addCargoRow(index)" />
                </el-col>
                <el-col v-if="!obj.optFlg" :span="1" class="row_table_col1 icon_delete">
                  <i class="el-icon-remove" @click="removeCargoRow(index)" />
                </el-col>
                <el-col :span="2" class="row_table_col">
                  <!--<el-select v-model="obj.cgId" :disabled="opt==='checkIn'" placeholder="货物" filterable clearable allow-create default-first-option class="waybill input_span2" size="mini" @change="changeCargoNm(obj)">
                    <el-option
                      v-for="item in cargoOptions"
                      :key="item.cgId"
                      :label="item.cgNm"
                      :value="item.cgId"
                    />
                  </el-select>-->
                  <el-autocomplete
                    v-model="obj.cgId"
                    :disabled="true || opt === 'edit' && checkingFlag"
                    :fetch-suggestions="querySearch2"
                    class="waybill3 input_span2"
                    :class="{ validFieldClass : (obj.validFields === undefined || null) ? false : obj.validFields.cgId }"
                    placeholder="货物"
                    size="mini"
                    @select="changeCargoNm"
                    @blur="changeCargoNm"
                  >
                    <template slot-scope="scope">
                      <div class="name">{{ scope.item.cgNm }}</div>
                    </template>
                  </el-autocomplete>
                </el-col>
                <el-col :span="2" class="row_table_col">
                  <el-select
                    v-model="obj.cgSpec"
                    :disabled="opt==='checkIn' || opt === 'detail' || (opt === 'edit' && checkingFlag)"
                    placeholder="包装方式"
                    filterable
                    allow-create
                    class="waybill3 input_span3"
                    size="mini"
                  >
                    <el-option
                      v-for="item in cargoSpecs"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                    />
                  </el-select>
                </el-col>
                <el-col :span="2" class="row_table_col">
                  <el-input
                    v-model="obj.itemNo"
                    :disabled="opt === 'detail' ||(opt === 'edit' && checkingFlag)"
                    type="text"
                    class="waybill input_span3"
                    size="mini"
                    placeholder="货号"
                  />
                </el-col>
                <el-col :span="2" class="row_table_col">
                  <el-input
                    v-model="obj.cgNum"
                    :disabled="opt === 'detail' ||(opt === 'edit' && checkingFlag)"
                    type="text"
                    class="waybill input_span3"
                    size="mini"
                    placeholder="货物编号"
                  />
                </el-col>
                <el-col :span="2" class="row_table_col">
                  <el-input
                    v-model="obj.cgType"
                    :disabled="opt === 'detail' ||(opt === 'edit' && checkingFlag)"
                    type="text"
                    class="waybill input_span3"
                    size="mini"
                    placeholder="型号"
                  />
                </el-col>
                <el-col :span="2" class="row_table_col" style="text-align: center;">
                  <el-input-number
                    v-model="obj.instQty"
                    :disabled="opt === 'detail' ||(opt==='edit' && (modifyFlag || checkingFlag))"
                    class="waybill input_span4"
                    size="mini"
                    placeholder="数量"
                    @change="calcWeiAndVol(obj)"
                  />
                </el-col>
                <el-col :span="4" class="row_table_col" style="text-align: center;">
                  <el-input-number
                    v-model="obj.instWei"
                    :disabled="opt === 'detail' ||(opt==='edit' && (modifyFlag || checkingFlag))"
                    class="waybill input_span4"
                    size="mini"
                    placeholder="净重"
                    @change="calcWei(obj)"
                  />
                </el-col>
                <el-col :span="4" class="row_table_col" style="text-align: center;">
                  <el-input-number
                    v-model="obj.instGrossWei"
                    :disabled="opt === 'detail' ||(opt==='edit' && (modifyFlag || checkingFlag))"
                    class="waybill input_span4"
                    size="mini"
                    placeholder="毛重"
                    @change="calcGrossWei(obj)"
                  />
                </el-col>
                <el-col :span="3" class="row_table_col" style="text-align: center;">
                  <el-input-number
                    v-model="obj.instVol"
                    :disabled="opt === 'detail' ||(opt==='edit' && (modifyFlag || checkingFlag))"
                    class="waybill input_span4"
                    size="mini"
                    placeholder="体积"
                    @change="calcInstVol(obj)"
                  />
                </el-col>
              </el-row>
            </template>
            <el-row>
              <el-col :span="1" class="row_footer_col" />
              <el-col :span="2" class="row_footer_col2">&nbsp;合 计：</el-col>
              <el-col :span="2" class="row_footer_col2">&nbsp;</el-col>
              <el-col :span="2" class="row_footer_col2">&nbsp;</el-col>
              <el-col :span="2" class="row_footer_col2">&nbsp;</el-col>
              <el-col :span="2" class="row_footer_col2">&nbsp;</el-col>
              <el-col :span="2" class="row_footer_col2">&nbsp;{{ sumQty }}</el-col>
              <el-col :span="4" class="row_footer_col2">&nbsp;{{ sumWei }}</el-col>
              <el-col :span="4" class="row_footer_col2">&nbsp;{{ sumGrossWei }}</el-col>
              <el-col :span="3" class="row_footer_col2">&nbsp;{{ sumVol }}</el-col>
            </el-row>
          </div>
        </div>

        <div class="order-content">
          <div class="order-editor-traffic">
            <el-row :gutter="10">

              <el-col
                :span="2"
                :class="{shuxian : waybill.wayTp==='1' || waybill.wayTp==='3'|| waybill.wayTp==='2'}"
                class="label_right"
              >
                <label>发货时间：</label>
              </el-col>
              <el-col :span="3">
                <el-date-picker
                  v-model="waybill.deliveryDt"
                  :disabled="opt==='checkIn' ||opt==='detail' || (opt === 'edit' && checkingFlag)"
                  :class="{ validFieldClass : validFields.deliveryDt }"
                  format="yyyy-MM-dd HH:mm:ss"
                  style="width: 200px;"
                  class="waybill waybill11"
                  type="datetime"
                  placeholder="发货时间"
                  size="mini"
                />
              </el-col>
              <el-col
                :span="2"
                :class="{shuxian : waybill.wayTp==='1' || waybill.wayTp==='3'}"
                class="label_right"
              >
                <label>预计到达：</label>
              </el-col>
              <el-col :span="3">
                <el-date-picker
                  v-model="waybill.expectDt"
                  :disabled="opt==='checkIn' ||opt==='detail' || (opt === 'edit' && checkingFlag)"
                  :class="{ validFieldClass : validFields.expectDt }"
                  format="yyyy-MM-dd HH:mm:ss"
                  style="width: 200px;"
                  class="waybill waybill11"
                  type="datetime"
                  placeholder="预计到达时间"
                  size="mini"
                />
              </el-col>
              <el-col
                :span="2"
                :class="{shuxian : waybill.wayTp==='1' || waybill.wayTp==='3'}"
                class="label_right"
              >
                <label>预警时间：</label>
              </el-col>
              <el-col :span="3">
                <el-date-picker
                  v-model="waybill.warningDt"
                  :disabled="opt==='checkIn' ||opt==='detail' || (opt === 'edit' && checkingFlag)"
                  :class="{ validFieldClass : validFields.warningDt }"
                  format="yyyy-MM-dd HH:mm:ss"
                  style="width: 200px;"
                  class="waybill waybill11"
                  type="datetime"
                  placeholder="预警时间"
                  size="mini"
                />
              </el-col>
              <el-col :span="2" class="label_right line_col_l">
                <label>回单要求：</label>
              </el-col>
              <el-col :span="4">
                <el-select
                  v-model="waybill.receiptReq"
                  :disabled="opt==='checkIn' ||opt==='detail' || (opt === 'edit' && checkingFlag)"
                  placeholder="回单要求"
                  class="waybillReceiptReq"
                  size="mini"
                  @change="changeReceiptReq"
                >
                  <el-option
                    v-for="item in receiptReqs"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
                <el-input-number
                  v-model="waybill.receiptQty"
                  :disabled="receiptQtyDisStatus ||opt==='detail' || (opt === 'edit' && checkingFlag)"
                  style="width: 80px;"
                  controls-position="right"
                  :min="0"
                  :max="100"
                  size="mini"
                />
                <span>份</span>
              </el-col>
              <!--<el-col :span="2" class="label_right line_col_l">
                <label>回单份数：</label>
              </el-col>
              <el-col :span="3">
                <el-input-number v-model="waybill.receiptQty" :disabled="receiptQtyDisStatus" :min="0" :max="100" size="mini" />
              </el-col>-->
            </el-row>
          </div>
          <!-- <div class="order-editor-traffic2">
            <el-row :gutter="10">
              <el-col :span="2" class="label_right">
                <label>运 费：</label>
              </el-col>
              <el-col :span="3">
                <el-input
                  v-model="sumWayFee"
                  :disabled="opt==='detail' || (opt === 'edit' && checkingFlag)"
                  :class="{ validFieldClass : validFields.wayFee }"
                  type="text"
                  size="mini"
                  class="waybill"
                  @keyup.native="inputNumber"
                />
              </el-col>
              <el-col :span="2" class="label_right line_col_l">
                <label>送货费：</label>
              </el-col>
              <el-col :span="3">
                <el-input
                  v-model="waybill.dlvrFee"
                  :disabled="opt === 'detail' ||(opt === 'edit' && checkingFlag)"
                  type="text"
                  size="mini"
                  class="waybill"
                  @keyup.native="dlvrFeeNumber"
                />
              </el-col>
              <el-col :span="2" class="label_right line_col_l">
                <label>其它费：</label>
              </el-col>
              <el-col :span="3">
                <el-input
                  v-model="waybill.otherFee"
                  :disabled="opt === 'detail' ||(opt === 'edit' && checkingFlag)"
                  type="text"
                  size="mini"
                  class="waybill"
                  @keyup.native="inputNumber3"
                />
              </el-col>
              <el-col :span="2" class="label_right line_col_l">
                <label>合计运费：</label>
              </el-col>
              <el-col :span="3">
                <el-input
                  v-model="jhFee"
                  :disabled="true"
                  type="text"
                  readonly
                  size="mini"
                  class="waybill"
                />
              </el-col>
            </el-row>
          </div> -->
          
          <!-- <div class="order-editor-traffic2">
            <el-row :gutter="10">
              <el-col :span="2" class="label_right line_col_l">
                <label>付款方式：</label>
              </el-col>
              <el-col :span="3">
                <el-select
                  v-model="waybill.payTp"
                  :disabled="opt==='checkIn' || opt==='detail' || (opt === 'edit' && checkingFlag)"
                  :class="{ validFieldClass : validFields.payTp }"
                  placeholder="付款方式"
                  class="waybill"
                  size="mini"
                  @change="changePayTp"
                >
                  <el-option
                    v-for="item in payTps"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-col>
              <el-col :span="2" class="label_right line_col_l">
                <label>月 结：</label>
              </el-col>
              <el-col :span="3">
                <el-input
                  v-model="waybill.monthlyFee"
                  :class="{ validFieldClass : validFields.monthlyFee }"
                  :disabled="monthlyFeeIsDisabled || opt==='detail' || (opt === 'edit' && checkingFlag)"
                  type="text"
                  class="waybill"
                  size="mini"
                  placeholder="月 结"
                  @keyup.native="monthlyFeeNumber"
                />
              </el-col>
              <el-col :span="2" class="label_right line_col_l">
                <label>现 付：</label>
              </el-col>
              <el-col :span="3">
                <el-input
                  v-model="waybill.cashFee"
                  :class="{ validFieldClass : validFields.cashFee }"
                  :disabled="cashFeeIsDisabled || (opt === 'edit' && checkingFlag)"
                  type="text"
                  size="mini"
                  class="waybill"
                  placeholder="现 付"
                  @keyup.native="inputNumber5"
                />
              </el-col>
              <el-col :span="2" class="label_right line_col_l">
                <label>到 付：</label>
              </el-col>
              <el-col :span="3">
                <el-input
                  v-model="waybill.collectFee"
                  :class="{ validFieldClass : validFields.collectFee }"
                  :disabled="collectFeeIsDisabled || (opt === 'edit' && checkingFlag)"
                  type="text"
                  size="mini"
                  class="waybill"
                  placeholder="到 付"
                  @keyup.native="inputNumber6"
                />
              </el-col>
            </el-row>
          </div> -->
          <div class="order-editor-traffic2">
            <el-row :gutter="10">
              <el-col :span="2" class="label_right line_col_l">
                <label>回 单 号：</label>
              </el-col>
              <el-col :span="20">
                <el-input
                  v-model="waybill.receiptOdd"
                  :disabled="opt==='detail' || (opt === 'edit' && checkingFlag)"
                  type="textarea"
                  class="waybill"
                  maxlength="255"
                  show-word-limit
                  placeholder="回单号"
                />
              </el-col>
            </el-row>
          </div>
          <div class="order-editor-traffic2">
            <el-row :gutter="10">
              <el-col :span="2" class="label_right line_col_l">
                <label>备 注：</label>
              </el-col>
              <el-col :span="20">
                <el-input
                  v-model="waybill.remark"
                  :disabled="opt==='checkIn' || opt==='detail' "
                  type="textarea"
                  class="waybill"
                  maxlength="255"
                  show-word-limit
                  placeholder="备注信息"
                />
              </el-col>
            </el-row>
          </div>
          <div>
            <el-dialog :visible.sync="sumFeeDialog" title="运费合计" width="25%">
              <el-radio-group v-model="conFee" :disabled="false">
                <el-radio :label="qtyFee">按数量计费：{{ qtyFee }}</el-radio>
                <el-radio :label="weiFee">按重量计费：{{ weiFee }}</el-radio>
                <el-radio :label="volFee">按体积计费：{{ volFee }}</el-radio>
                <el-radio :label="vehFee">按车型计费：{{ vehFee }}</el-radio>
              </el-radio-group>
              <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="sumFeeDialog = false">确 定</el-button>
              </span>
            </el-dialog>
            <el-dialog :visible.sync="sumInnerFeeDialog" title="内部结算价合计" width="25%">
              <el-radio-group v-model="conInnerData" :disabled="false">
                <el-radio v-for="item in sumInnerFees" :key="item.value" :label="item.data">
                  按{{ item.ruleTp }}计价:
                  <span v-show="item.carTp !== ''">车型{{ item.carTp }}</span>
                  运费 {{ item.value }} 送货费 {{ item.dlr }}
                </el-radio>
                <el-row :gutter="10">
                  <el-col :span="6" class="label_right">
                    <label>其它费用：</label>
                  </el-col>
                  <el-col :span="6">
                    <el-input
                      v-model="waybill.innerOtherFee"
                      type="text"
                      size="mini"
                      class="innerfee"
                      @keyup.native="inputNumber4"
                    />
                  </el-col>
                </el-row>
              </el-radio-group>
              <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="chooseInnerFee">确 定</el-button>
              </span>
            </el-dialog>
          </div>
        </div>
      </div>
      <!-- <div v-if="opt === 'detail'" class="left">
        <el-row>
          <el-col :span="24">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
              <el-tab-pane label="外包详情" name="first">
                <vxe-table
                  v-if="outActive"
                  ref="outbill"
                  align="center"
                  :data="outSourceData"
                  border
                  resizable
                >
                  <vxe-table-column field="outId" title="外包单号" min-width="100" show-overflow />
                  <vxe-table-column field="crirNm" title="承运商" min-width="80" show-overflow />
                  <vxe-table-column field="strtCtcts" title="承运商联系人" min-width="110" show-overflow />
                  <vxe-table-column field="strtTel" title="承运商电话" min-width="100" show-overflow />
                  <vxe-table-column
                    :formatter="formatDate"
                    field="outDt"
                    title="外包日期"
                    min-width="100"
                    show-overflow
                  />
                  <vxe-table-column field="outFee" title="专线费" min-width="100" show-overflow />
                  <vxe-table-column field="advance" title="现付" min-width="80" show-overflow />
                  <vxe-table-column field="remitFee" title="到付" min-width="80" show-overflow />
                  <vxe-table-column field="receiptFee" title="回付" min-width="80" show-overflow />
                  <vxe-table-column field="payCardFee" title="油卡金额" min-width="80" show-overflow />
                  <vxe-table-column field="depositFee" title="油卡押金" min-width="80" show-overflow />
                  <vxe-table-column field="otherFee" title="其它费" min-width="80" show-overflow />
                  <vxe-table-column field="remark" title="外包备注" min-width="80" show-overflow />
                </vxe-table>
              </el-tab-pane>
              <el-tab-pane label="发车详情" name="second">
                <vxe-table
                  v-if="dispActive"
                  ref="dispbill"
                  align="center"
                  :data="dispData"
                  border
                  resizable
                >
                  <vxe-table-column field="dispId" title="发车单号" min-width="80" show-overflow />
                  <vxe-table-column field="driverNm" title="司机姓名" min-width="80" show-overflow />
                  <vxe-table-column field="vehNbr" title="司机车牌" min-width="120" show-overflow />
                  <vxe-table-column field="driverTel" title="司机电话" min-width="100" show-overflow />
                  <vxe-table-column
                    :formatter="formatDate"
                    field="dispDt"
                    title="发车日期"
                    min-width="100"
                    show-overflow
                  />
                  <vxe-table-column field="dispFee" title="整车费" min-width="100" show-overflow />
                  <vxe-table-column field="avgFee" title="分摊整车费" min-width="100" show-overflow />
                  <vxe-table-column field="advance" title="现付" min-width="80" show-overflow />
                  <vxe-table-column field="remitFee" title="到付" min-width="80" show-overflow />
                  <vxe-table-column field="receiptFee" title="回付" min-width="80" show-overflow />
                  <vxe-table-column field="payCardFee" title="油卡金额" min-width="80" show-overflow />
                  <vxe-table-column
                    field="depositOilFee"
                    title="油卡押金"
                    min-width="80"
                    show-overflow
                  />
                  <vxe-table-column field="woodDeposit" title="木头押金" min-width="80" show-overflow />
                  <vxe-table-column field="otherFee" title="其它费" min-width="80" show-overflow />
                  <vxe-table-column field="remark" title="发车备注" min-width="80" show-overflow />
                </vxe-table>
              </el-tab-pane>
              <el-tab-pane label="短驳详情" name="three">
                <vxe-table
                  v-if="transActive"
                  ref="transbill"
                  align="center"
                  :data="transhipData"
                  border
                  resizable
                >
                  <vxe-table-column field="transId" title="短驳单号" min-width="80" show-overflow />
                  <vxe-table-column field="driverNm" title="司机姓名" min-width="80" show-overflow />
                  <vxe-table-column field="vehNbr" title="司机车牌" min-width="120" show-overflow />
                  <vxe-table-column field="driverTel" title="司机电话" min-width="100" show-overflow />
                  <vxe-table-column
                    :formatter="formatDate"
                    field="transDt"
                    title="短驳日期"
                    min-width="100"
                    show-overflow
                  />
                  <vxe-table-column field="transFee" title="短驳费" min-width="100" show-overflow />
                  <vxe-table-column field="avgFee" title="分摊短驳费" min-width="120" show-overflow />
                  <vxe-table-column
                    field="transCashFee"
                    title="短驳现金费"
                    min-width="120"
                    show-overflow
                  />
                  <vxe-table-column field="payCardFee" title="短驳油卡" min-width="80" show-overflow />
                  <vxe-table-column field="feeDeposit" title="油卡押金" min-width="80" show-overflow />
                  <vxe-table-column field="remark" title="短驳备注" min-width="80" show-overflow />
                </vxe-table>
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
      </div> -->
    </div>
    <el-dialog
      :visible.sync="mapDialogVisible"
      title="详细地址标注"
      width="50%"
      :close-on-click-modal="false"
    >
      <span>
        <div id="map-container" class="map-cls" style="margin-top: -10px;">
          <el-input
            id="pickerInput"
            v-model="poiValue"
            type="text"
            size="small"
            class="search-box-map"
          >
            <el-button slot="append" icon="el-icon-search" />
          </el-input>
          <el-amap :amap-manager="amapManager" :events="events" vid="amapDemo" class="amap-demo" />
        </div>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="mapDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmDetailAddr">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import SelectTable from './components/SelectTable'
import WaybillSelectCity from './components/WaybillSelectCity'
import {
  queryBaseConsignorList,
  queryBaseReceiverList,
  queryBaseCargoList,
  calcBasePricePlan,
  calcProtocolPricePlan,
  calcBaseInnerPricePlan,
  queryConsignorContactList,
  queryConsignorAddrList,
  queryBaseReceiverContactList,
} from '@/api/base'
import { getSerialNumber } from '@/api/system'
import VueAMap from 'vue-amap'
import {
  submitBizWayBill,
  acquireBizWayBill,
  modifyBizWayBill,
  getCustPrice,
  queryAllDisp,
} from '@/api/business'

const amapManager = new VueAMap.AMapManager()
export default {
  name: 'Waybill',
  components: {
    SelectTable,
    WaybillSelectCity,
  },
  props: {
    opt: {
      type: String,
      default: 'create',
    },
    wayId: {
      type: String,
      default: null,
    },
    waybillJson: {
      type: Object,
      default() {
        return {}
      },
    },
    schedulingSource: {
      type: String,
      default: null,
    },
  },
  // inject: ['reload'],
  data() {
    const self = this
    return {
      precision: 4,
      precisions: 8,
      pricePrecision: process.env.PRICE_PRECISION,
      // accessCode: this.$store.getters.accessCode,
      amapManager,
      poiValue: '',
      isEdit: false,
      mapDialogVisible: false,
      positionPicker: null,
      infoWindow: null,
      location: [],
      wayTpDisabled: false,
      priceTpFlag: false,
      sumFeeDialog: false,
      receiptQtyDisStatus: false,
      qtyFee: 0,
      weiFee: 0,
      volFee: 0,
      vehFee: 0,
      conFee: 0,
      acqDlvrFee: 0,
      sumInnerFees: [],
      sumInnerFeeDialog: false,
      conInnerData: '',
      isSaved: false,
      printFlag: '1',
      print_qty: 1,
      print_yd: '',
      print_xf: '',
      print_bq: '',
      lng: '',
      lat: '',
      cnorId: 0,
      rcvrId: 0,
      cnorContactId: 0,
      rcvrContactId: 0,
      rcvrAddrOptions: [],
      rcvrAddr: {
        addrNm: '',
        optFlg: false,
        lng: '',
        lat: '',
      },
      planId: 0,
      rcvrAddrId: 0,
      calcWayFeeFlag: false,
      saveBtnDisabled: false,
      priceTpDisabled: false,
      payTpDisabled: false,
      firstEnter: false,
      qryCnorFlag: false,
      wayTpChangeFlag: false,
      modifyFlag: false,
      validFields: {
        wayId: false,
        strt: false,
        dest: false,
        strtList: false,
        destList: false,
        sendDt: false,
        cnorNm: false,
        cnorCtcts: false,
        cnorTel: false,
        cnorAddr: false,
        rcvrNm: false,
        rcvrCtcts: false,
        rcvrTel: false,
        rcvrAddr: false,
        payTp: false,
        cashFee: false,
        collectFee: false,
        monthlyFee: false,
        wayFee: false,
        deliveryDt: false,
        expectDt: false,
        warningDt: false,
      },
      cashFeeIsDisabled: true,
      monthlyFeeIsDisabled: true,
      collectFeeIsDisabled: true,
      wayFeeSyncStatus: false,
      checkingFlag: false,
      initRecord: {
        instQty: 0,
        instWei: 0,
        instGrossWei: 0,
        instVol: 0,
        priceTp: null,
        wayFee: 0,
        dlvrFee: 0,
        otherFee: 0,
        jhFee: 0,
        sumPrice: 0,
        remark: '',
      },
      waybill: {
        wayId: '',
        // strt: 1059,
        strt: null,
        strtCode: null,
        strtList: {
          aeId: null,
          aeParCode: null,
        },
        dest: null,
        destCode: null,
        destList: {
          aeId: null,
          aeParCode: null,
        },
        wayTp: '1',
        sendDt: '',
        cnorNbr: '',
        transTp: '1',
        transWay: '',
        recvTp: '1',
        urgency: '1',
        urgencyTp: '0',
        cnorNm: '',
        cnorCtcts: '',
        cnorContactId: '',
        rcvrContactId: '',
        items: {
          addrNm: '',
          cnorContactId: '',
          cnorAddrId: '',
          cnorCtcts: '',
          cnorTel: '',
          rcvrContactId: '',
          lng: '',
          lat: '',
          rcvrCtcts: '',
          rcvrTel: '',
        },
        cnorTel: '',
        cnorAddr: '',
        rcvrNm: '',
        rcvrCtcts: '',
        rcvrTel: '',
        rcvrAddr: '',
        price: 0,
        innerPrice: 0,
        priceTp: null,
        receiptReq: '1',
        receiptQty: 1,
        payTp: '4',
        carTp: null,
        deliveryDt: '',
        expectDt: '',
        warningDt: '',
        wayFee: 0,
        innerFee: 0,
        innerDlvrFee: 0,
        innerOtherFee: 0,
        dlvrFee: 0,
        otherFee: 0,
        remark: '',
        cashFee: 0,
        cashStatus: '',
        collectFee: 0,
        monthlyFee: 0,
        collectStatus: '',
        cargoInsts: [],
        rcvrAddrs: [],
        cnorAddrs: [],
        receiptOdd: '',
        custBillStatus: '',
      },
      planbill: {
        directFlag: '0',
        orderSendDt: null,
        planBillId: '',
        strt: null,
        strtCode: null,
        strtList: {
          aeId: null,
          aeParCode: null,
        },
        dest: null,
        destCode: null,
        destList: {
          aeId: null,
          aeParCode: null,
        },
        wayTp: '1',
        sendDt: '',
        cnorId: 0,
        cnorNbr: '',
        transWay: '',
        cnorNm: '',
        cnorCtcts: '',
        cnorContactId: '',
        cnorAddrId: '',
        cnorTel: '',
        rcvrContactId: '',
        addrNm: '',
        cnorAddrNm: '',
        cnorAddr: '',
        aeCode: '',
        aeName: '',
        instQty: 0,
        instWei: 0,
        instGrossWei: 0,
        instVol: 0,
        // 后台计算价格时用
        planId: 0,
        rcvrNm: '',
        rcvrCtcts: '',
        rcvrTel: '',
        rcvrAddr: '',
        carTp: null,
        payTp: '4',
        transTp: '1',
        recvTp: '1',
        urgencyTp: '0',
        receiptReq: '1',
        receiptQty: '1',
        deliveryDt: new Date(),
        priceTp: '1',
        expectDt: '',
        warningDt: '',
        remark: '',
        wayFee: 0,
        price: 0,
        otherFee: 0,
        dlvrFee: 0,
        planBillDetails: [],
        cnorAddrs: [],
        rcvrAddrs: [],
        isUploadFee: 0,
        receiptOdd: '',
        cashFee: 0,
        collectFee: 0,
        monthlyFee: 0,
        lngLats: null,
        cnorlngLats: null,
        custBillStatus: '0',
      },
      cargoInst: {
        cgId: null,
        cgNm: null,
        cgSpec: null,
        instQty: 0,
        instWei: 0,
        instGrossWei: 0,
        instVol: 0,
        dtlTp: '1',
        price: 0,
        dlvrFee: 0,
        cargoWayFee: 0,
        priceTp: '1',
        remark: '',
        optFlg: false,
      },
      cargoInsts: [],
      pricePlans: [],
      innerPricePlans: [],
      strts: [],
      dests: [],
      prvns: [],
      cnorOptions: [],
      cnorCtOptions: [],
      cnorAddrOption: [],
      cnorAddrOptions: [],
      rcvrCtOptions: [],
      rcvrOptions: [],
      cargoOptions: [],
      urgencys: [
        {
          value: '1',
          label: '否',
        },
        {
          value: '0',
          label: '是',
        },
      ],
      urgencyTps: [
        {
          value: '0',
          label: '否',
        },
        {
          value: '1',
          label: '是',
        },
      ],
      transTps: [
        {
          value: '1',
          label: '零担',
        },
        {
          value: '2',
          label: '整车',
        },
      ],
      recvTps: [
        {
          value: '1',
          label: '送货',
        },
        {
          value: '2',
          label: '自提',
        },
      ],
      priceTps: [
        {
          value: '3',
          label: '/件',
        },
        {
          value: '1',
          label: '/吨',
        },
        {
          value: '2',
          label: '/方',
        },
        {
          value: '4',
          label: '/车',
        },
      ],
      receiptReqs: [
        {
          value: '1',
          label: '回单',
        },
        {
          value: '2',
          label: '收条',
        },
        {
          value: '3',
          label: '无回单',
        },
      ],
      payTps: [
        {
          value: '4',
          label: '月结',
        },
      ],
      returnRcvrTableConfig: [
        {
          keyName: 'cnorNm',
          name: '收货单位',
          width: 150,
        },
        {
          keyName: 'cnorCtcts',
          name: '收货人',
          width: 100,
        },
        {
          keyName: 'cnorTel',
          name: '收货电话',
          width: 100,
        },
        {
          keyName: 'cnorAddr',
          name: '收货地址',
          width: 200,
        },
      ],
      returnCnorTableConfig: [
        {
          keyName: 'rcvrNm',
          name: '发货单位',
          width: 200,
        },
        {
          keyName: 'rcvrCtcts',
          name: '发货人',
          width: 150,
        },
        {
          keyName: 'rcvrTel',
          name: '发货电话',
          width: 150,
        },
        // {
        //   keyName: 'rcvrAddr',
        //   name: '收货地址',
        //   width: 200
        // }
      ],
      cnorTableConfig: [
        {
          keyName: 'cnorNm',
          name: '发货单位',
          width: 500,
        },
      ],
      cnorCtctsConfig: [
        {
          keyName: 'cnorCtcts',
          name: '发货人',
          width: 250,
        },
        {
          keyName: 'cnorTel',
          name: '发货电话',
          width: 250,
        },
      ],
      cnorAddrConfig: [
        {
          keyName: 'cnorAddrNm',
          name: '发货地址',
          width: 500,
        },
      ],
      rcvrTableConfig: [
        {
          keyName: 'rcvrNm',
          name: '收货单位',
          width: 500,
        },
      ],
      rcvrCtctsTableConfig: [
        {
          keyName: 'rcvrCtcts',
          name: '收货人',
          width: 250,
        },
        {
          keyName: 'rcvrTel',
          name: '收货电话',
          width: 250,
        },
        // {
        //   keyName: 'rcvrAddr',
        //   name: '收货地址',
        //   width: 200
        // }
      ],
      cargoSpecs: [
        {
          value: '1',
          label: '纸箱',
        },
        {
          value: '2',
          label: '木箱',
        },
        {
          value: '3',
          label: '袋装',
        },
        {
          value: '4',
          label: '托盘',
        },
        {
          value: '5',
          label: '周转箱',
        },
      ],
      carTps: [
        {
          value: 1,
          label: '17.5米',
        },
        {
          value: 7,
          label: '13.75米',
        },
        {
          value: 2,
          label: '13米',
        },
        {
          value: 3,
          label: '9.6米',
        },
        {
          value: 4,
          label: '7.5米',
        },
        {
          value: 5,
          label: '6.8米',
        },
        {
          value: 6,
          label: '4.2米',
        },
      ],
      wayTps: [
        {
          value: '1',
          label: '合同订单',
        },
        {
          value: '2',
          label: '返程运单',
          hidden: true,
        },
        {
          value: '3',
          label: '普通订单',
        },
      ],
      dtlTps: [
        {
          value: '1',
          label: '类型1',
        },
        {
          value: '2',
          label: '类型2',
        },
        {
          value: '3',
          label: '类型3',
        },
      ],
      outSourceData: [],
      transhipData: [],
      dispData: [],
      activeName: 'first',
      outActive: true,
      dispActive: false,
      transActive: false,
      coCode: process.env.SYSTEM_ENV,
      events: {
        complete() {
          var map = self.amapManager.getMap()
          if (self.isEdit) {
            self.location = []
            if (
              self.waybill.rcvrAddrs[0].lng > 0 &&
              self.waybill.rcvrAddrs[0].lat > 0
            ) {
              self.location.push(self.waybill.rcvrAddrs[0].lng)
              self.location.push(self.waybill.rcvrAddrs[0].lat)
              map.setCenter(self.location)
              map.setZoom(14)
              self.positionPicker.start(self.location)
            }
          }
        },
        init(map) {
          if (!self.isEdit) {
            self.setMapCity(map)
          }
          // 加载PoiPicker，loadUI的路径参数为模块名中 'ui/' 之后的部分
          AMapUI.loadUI(['misc/PoiPicker'], function (PoiPicker) {
            var poiPicker = new PoiPicker({
              input: 'pickerInput', // 输入框id
            })
            // 监听poi选中信息
            poiPicker.on('poiPicked', function (poiResult) {
              // 用户选中的poi点信息
              var poi = poiResult.item
              self.poiValue = poi.name
              self.lng = poi.location.lng
              self.lat = poi.location.lat
              self.waybill.rcvrAddr = poi.address
              self.location = []
              self.location.push(self.lng)
              self.location.push(self.lat)
              map.setCenter(self.location)
              map.setZoom(14)
              self.positionPicker.start(self.location)

              var info = []
              // info.push('<div style="padding:5px 0px 0px 0px;"><h4>' + self.poiValue + '</h4>')
              info.push(
                "<p style='font-size: 12px;'>经度： " + self.lng + '</p>'
              )
              info.push(
                "<p style='margin-top: -5px;font-size: 12px;'>纬度： " +
                  self.lat +
                  '</p>'
              )
              info.push(
                "<p style='margin-top: -5px;font-size: 12px;'>地址：" +
                  self.waybill.rcvrAddr +
                  '</p></div>'
              )

              self.infoWindow = new AMap.InfoWindow({
                content: info.join(''), // 使用默认信息窗体框样式，显示信息内容
                offset: new AMap.Pixel(0, -31),
              })
              self.infoWindow.open(map, self.location)
            })
          })
          // 加载PositionPicker，loadUI的路径参数为模块名中 'ui/' 之后的部分
          AMapUI.loadUI(['misc/PositionPicker'], function (PositionPicker) {
            self.positionPicker = new PositionPicker({
              mode: 'dragMarker', // 设定为拖拽地图模式，可选'dragMap'、'dragMarker'，默认为'dragMap'
              map: map, // 依赖地图对象
            })
            // 事件绑定、结果处理等
            self.positionPicker.on('success', function (positionResult) {
              self.lng = positionResult.position.lng
              self.lat = positionResult.position.lat
              self.waybill.rcvrAddrs[0].lng = positionResult.position.lng
              self.waybill.rcvrAddrs[0].lat = positionResult.position.lat
              self.location = []
              self.location.push(self.lng)
              self.location.push(self.lat)
              self.waybill.rcvrAddr = positionResult.address
              self.waybill.rcvrAddrs[0].addrNm = positionResult.address
              self.waybill.lngLats =
                positionResult.position.lng + ',' + positionResult.position.lat
              var info = []
              // info.push('<div style="padding:5px 0px 0px 0px;"><h4>' + self.poiValue + '</h4>')
              info.push(
                "<p style='font-size: 12px;'>经度： " + self.lng + '</p>'
              )
              info.push(
                "<p style='margin-top: -5px;font-size: 12px;'>纬度： " +
                  self.lat +
                  '</p>'
              )
              info.push(
                "<p style='margin-top: -5px;font-size: 12px;'>地址：" +
                  self.waybill.rcvrAddr +
                  '</p></div>'
              )

              self.infoWindow = new AMap.InfoWindow({
                content: info.join(''), // 使用默认信息窗体框样式，显示信息内容
                offset: new AMap.Pixel(0, -31),
              })
              self.infoWindow.open(map, self.location)
            })
          })
        },
      },
    }
  },
  computed: {
    sumQty() {
      let sumQty = 0
      this.cargoInsts.forEach((item) => {
        sumQty += Number(item.instQty === undefined ? 0 : item.instQty)
      })
      return parseFloat(Number(sumQty).toFixed(this.precisions))
    },
    sumWei() {
      let sumWei = 0
      this.cargoInsts.forEach((item) => {
        sumWei += Number(item.instWei === undefined ? 0 : item.instWei)
      })
      return parseFloat(Number(sumWei).toFixed(this.precisions))
    },
    sumGrossWei() {
      let sumGrossWei = 0
      this.cargoInsts.forEach((item) => {
        sumGrossWei += Number(
          item.instGrossWei === undefined ? 0 : item.instGrossWei
        )
      })
      return parseFloat(Number(sumGrossWei).toFixed(this.precisions))
    },
    sumVol() {
      let sumVol = 0
      this.cargoInsts.forEach((item) => {
        sumVol += Number(item.instVol === undefined ? 0 : item.instVol)
      })
      return parseFloat(Number(sumVol).toFixed(this.precisions))
    },
    // 计算送货费
    sumDlvrFee() {
      let sumDlvrFee = 0
      this.cargoInsts.forEach((item) => {
        sumDlvrFee += Number(item.dlvrFee === undefined ? 0 : item.dlvrFee)
      })
      const ths = this
      if (ths.opt === 'detail' || ths.opt === 'edit') {
        if (ths.wayFeeSyncStatus) {
          ths.waybill.dlvrFee = Number(sumDlvrFee)
        }
      } else {
        ths.waybill.dlvrFee = Number(sumDlvrFee)
      }
      return Number(sumDlvrFee)
    },
    // 计算运费小计
    sumCargoWayFee() {
      let sumCargoWayFee = 0
      this.cargoInsts.forEach((item) => {
        sumCargoWayFee += Number(
          item.cargoWayFee === undefined ? 0 : item.cargoWayFee
        )
      })
      const ths = this
      if (ths.opt === 'detail' || ths.opt === 'edit') {
        if (ths.wayFeeSyncStatus) {
          ths.waybill.wayFee = Number(sumCargoWayFee)
        }
      } else {
        ths.waybill.wayFee = Number(sumCargoWayFee)
      }
      return Number(sumCargoWayFee)
    },
    jhFee() {
      var jhFee = parseFloat(
        (
          Number(this.waybill.wayFee) +
          Number(this.waybill.otherFee) +
          Number(this.waybill.dlvrFee)
        ).toFixed(this.precisions)
      )
      const ths = this
      if (ths.waybill.payTp === '1') {
        ths.waybill.cashFee = jhFee
      } else if (ths.waybill.payTp === '2') {
        ths.waybill.collectFee = jhFee
      } else if (ths.waybill.payTp === '4') {
        ths.waybill.monthlyFee = jhFee
      }
      return isNaN(jhFee) ? 0 : jhFee
    },
    sumPrice: {
      set: function (newValue) {
        this.waybill.price = newValue
        // 普通运单输入价格时，原有的价格计划设置为空，已输入价格为准
        if (this.waybill.wayTp === '3') {
          this.pricePlans = []
        }
      },
      get: function () {
        const ths = this
        // 非普通运单
        if (ths.waybill.wayTp !== '3' && ths.waybill.wayTp !== '2') {
          ths.waybill.price = 0
        }
        ths.waybill.dlvrFee = 0
        try {
          // 加比例
          this.pricePlans.forEach((item) => {
            // 按数量
            if (
              item.ruleTp === this.waybill.priceTp &&
              this.waybill.priceTp === '3' &&
              this.sumQty > item.minVal &&
              this.sumQty <= item.maxVal
            ) {
              if (
                ths.waybill.carTp === null ||
                ths.waybill.carTp === '' ||
                ths.waybill.carTp === 0
              ) {
                if (item.priceRatio !== '' && item.priceRatio !== null) {
                  if (
                    item.priceRatio.substring(0, 1) === '+' ||
                    item.priceRatio.substring(0, 1) === '-'
                  ) {
                    const priceRatio = item.priceRatio.substring(
                      0,
                      item.priceRatio.lastIndexOf('%')
                    )
                    ths.waybill.price = Math.round(
                      item.unitFee * (1 + priceRatio / 100)
                    )
                  } else {
                    const priceRatio2 = item.priceRatio.substring(
                      0,
                      item.priceRatio.lastIndexOf('%')
                    )
                    ths.waybill.price = Math.round(
                      item.unitFee * (1 + priceRatio2 / 100)
                    )
                  }
                } else {
                  ths.waybill.price = item.unitFee
                }

                if (item.dlvrFee > 0) {
                  if (this.waybill.transTp === '1') {
                    ths.waybill.dlvrFee =
                      item.dlvrFee * ths.waybill.rcvrAddrs.length
                  } else if (this.waybill.transTp === '2') {
                    ths.waybill.dlvrFee =
                      item.dlvrFee * (ths.waybill.rcvrAddrs.length - 1)
                  }
                }
                ths.calcWayFeeFlag = true
                throw new Error()
              } else if (ths.waybill.carTp.toString() === item.vehTp) {
                ths.waybill.price = item.unitFee
                if (item.dlvrFee > 0) {
                  if (this.waybill.transTp === '1') {
                    ths.waybill.dlvrFee =
                      item.dlvrFee * ths.waybill.rcvrAddrs.length
                  } else if (this.waybill.transTp === '2') {
                    ths.waybill.dlvrFee =
                      item.dlvrFee * (ths.waybill.rcvrAddrs.length - 1)
                  }
                }
                ths.calcWayFeeFlag = true
                throw new Error()
              }
            }
            // 按重量
            if (
              item.ruleTp === this.waybill.priceTp &&
              this.waybill.priceTp === '1' &&
              this.sumWei > item.minVal &&
              this.sumWei <= item.maxVal
            ) {
              if (
                ths.waybill.carTp === null ||
                ths.waybill.carTp === '' ||
                ths.waybill.carTp === 0 ||
                ths.waybill.carTp === undefined
              ) {
                if (item.priceRatio !== '' && item.priceRatio !== null) {
                  if (
                    item.priceRatio.substring(0, 1) === '+' ||
                    item.priceRatio.substring(0, 1) === '-'
                  ) {
                    const priceRatio = item.priceRatio.substring(
                      0,
                      item.priceRatio.lastIndexOf('%')
                    )
                    ths.waybill.price = Math.round(
                      item.unitFee * (1 + priceRatio / 100)
                    )
                  } else {
                    const priceRatio2 = item.priceRatio.substring(
                      0,
                      item.priceRatio.lastIndexOf('%')
                    )
                    ths.waybill.price = Math.round(
                      item.unitFee * (1 + priceRatio2 / 100)
                    )
                  }
                } else {
                  ths.waybill.price = item.unitFee
                }

                if (item.dlvrFee > 0) {
                  if (this.waybill.transTp === '1') {
                    ths.waybill.dlvrFee =
                      item.dlvrFee * ths.waybill.rcvrAddrs.length
                  } else if (this.waybill.transTp === '2') {
                    ths.waybill.dlvrFee =
                      item.dlvrFee * (ths.waybill.rcvrAddrs.length - 1)
                  }
                }
                ths.calcWayFeeFlag = true
                throw new Error()
              } else if (ths.waybill.carTp.toString() === item.vehTp) {
                ths.waybill.price = item.unitFee
                if (item.dlvrFee > 0) {
                  if (this.waybill.transTp === '1') {
                    ths.waybill.dlvrFee =
                      item.dlvrFee * ths.waybill.rcvrAddrs.length
                  } else if (this.waybill.transTp === '2') {
                    ths.waybill.dlvrFee =
                      item.dlvrFee * (ths.waybill.rcvrAddrs.length - 1)
                  }
                }
                ths.calcWayFeeFlag = true
                throw new Error()
              }
            }
            // 按体积
            if (
              item.ruleTp === this.waybill.priceTp &&
              this.waybill.priceTp === '2' &&
              this.sumVol > item.minVal &&
              this.sumVol <= item.maxVal
            ) {
              if (
                ths.waybill.carTp === null ||
                ths.waybill.carTp === '' ||
                ths.waybill.carTp === 0
              ) {
                if (item.priceRatio !== '' && item.priceRatio !== null) {
                  if (
                    item.priceRatio.substring(0, 1) === '+' ||
                    item.priceRatio.substring(0, 1) === '-'
                  ) {
                    const priceRatio = item.priceRatio.substring(
                      0,
                      item.priceRatio.lastIndexOf('%')
                    )
                    ths.waybill.price = Math.round(
                      item.unitFee * (1 + priceRatio / 100)
                    )
                  } else {
                    const priceRatio2 = item.priceRatio.substring(
                      0,
                      item.priceRatio.lastIndexOf('%')
                    )
                    ths.waybill.price = Math.round(
                      item.unitFee * (1 + priceRatio2 / 100)
                    )
                  }
                } else {
                  ths.waybill.price = item.unitFee
                }

                if (item.dlvrFee > 0) {
                  if (this.waybill.transTp === '1') {
                    ths.waybill.dlvrFee =
                      item.dlvrFee * ths.waybill.rcvrAddrs.length
                  } else if (this.waybill.transTp === '2') {
                    ths.waybill.dlvrFee =
                      item.dlvrFee * (ths.waybill.rcvrAddrs.length - 1)
                  }
                }
                ths.calcWayFeeFlag = true
                throw new Error()
              } else if (ths.waybill.carTp.toString() === item.vehTp) {
                ths.waybill.price = item.unitFee
                if (item.dlvrFee > 0) {
                  if (this.waybill.transTp === '1') {
                    ths.waybill.dlvrFee =
                      item.dlvrFee * ths.waybill.rcvrAddrs.length
                  } else if (this.waybill.transTp === '2') {
                    ths.waybill.dlvrFee =
                      item.dlvrFee * (ths.waybill.rcvrAddrs.length - 1)
                  }
                }
                ths.calcWayFeeFlag = true
                throw new Error()
              }
            }
            // 按整车
            if (
              item.ruleTp === this.waybill.priceTp &&
              this.waybill.priceTp === '4' &&
              this.waybill.carTp === item.minVal
            ) {
              if (item.priceRatio !== '' && item.priceRatio !== null) {
                if (
                  item.priceRatio.substring(0, 1) === '+' ||
                  item.priceRatio.substring(0, 1) === '-'
                ) {
                  const priceRatio = item.priceRatio.substring(
                    0,
                    item.priceRatio.lastIndexOf('%')
                  )
                  ths.waybill.price = Math.round(
                    item.unitFee * (1 + priceRatio / 100)
                  )
                } else {
                  const priceRatio2 = item.priceRatio.substring(
                    0,
                    item.priceRatio.lastIndexOf('%')
                  )
                  ths.waybill.price = Math.round(
                    item.unitFee * (1 + priceRatio2 / 100)
                  )
                }
              } else {
                ths.waybill.price = item.unitFee
              }

              if (item.dlvrFee > 0) {
                if (this.waybill.transTp === '1') {
                  ths.waybill.dlvrFee =
                    item.dlvrFee * ths.waybill.rcvrAddrs.length
                } else if (this.waybill.transTp === '2') {
                  ths.waybill.dlvrFee =
                    item.dlvrFee * (ths.waybill.rcvrAddrs.length - 1)
                }
              }
              ths.calcWayFeeFlag = true
              throw new Error()
            }
          })
        } catch (e) {
          console.log()
        }
        if (this.waybill.payTp === '3' && this.waybill.wayTp === '2') {
          ths.waybill.price = 0
        }
        // 数据库中保存的送货费（录单时手动输入）
        if (this.acqDlvrFee > 0) {
          ths.waybill.dlvrFee = this.acqDlvrFee
        }
        return this.waybill.price
      },
    },
    sumInnerPrice() {
      const ths = this
      ths.waybill.innerPrice = 0
      ths.waybill.innerDlvrFee = 0
      try {
        this.innerPricePlans.forEach((item) => {
          // 按数量
          if (
            item.ruleTp === this.waybill.priceTp &&
            this.waybill.priceTp === '3' &&
            this.sumQty > item.minVal &&
            this.sumQty <= item.maxVal
          ) {
            if (
              ths.waybill.carTp === null ||
              ths.waybill.carTp === '' ||
              ths.waybill.carTp === 0
            ) {
              ths.waybill.innerPrice = item.unitFee
              if (item.dlvrFee > 0) {
                if (this.waybill.transTp === '1') {
                  ths.waybill.innerDlvrFee =
                    item.dlvrFee * ths.waybill.rcvrAddrs.length
                } else if (this.waybill.transTp === '2') {
                  ths.waybill.innerDlvrFee =
                    item.dlvrFee * (ths.waybill.rcvrAddrs.length - 1)
                }
              }
              // ths.calcWayFeeFlag = true
              throw new Error()
            } else if (ths.waybill.carTp.toString() === item.vehTp) {
              ths.waybill.price = item.unitFee
              if (item.dlvrFee > 0) {
                if (this.waybill.transTp === '1') {
                  ths.waybill.innerDlvrFee =
                    item.dlvrFee * ths.waybill.rcvrAddrs.length
                } else if (this.waybill.transTp === '2') {
                  ths.waybill.innerDlvrFee =
                    item.dlvrFee * (ths.waybill.rcvrAddrs.length - 1)
                }
              }
              // ths.calcWayFeeFlag = true
              throw new Error()
            }
          }
          // 按重量
          if (
            item.ruleTp === this.waybill.priceTp &&
            this.waybill.priceTp === '1' &&
            this.sumWei > item.minVal &&
            this.sumWei <= item.maxVal
          ) {
            if (
              ths.waybill.carTp === null ||
              ths.waybill.carTp === '' ||
              ths.waybill.carTp === 0
            ) {
              ths.waybill.innerPrice = item.unitFee
              if (item.dlvrFee > 0) {
                if (this.waybill.transTp === '1') {
                  ths.waybill.innerDlvrFee =
                    item.dlvrFee * ths.waybill.rcvrAddrs.length
                } else if (this.waybill.transTp === '2') {
                  ths.waybill.innerDlvrFee =
                    item.dlvrFee * (ths.waybill.rcvrAddrs.length - 1)
                }
              }
              // ths.calcWayFeeFlag = true
              throw new Error()
            } else if (ths.waybill.carTp.toString() === item.vehTp) {
              ths.waybill.price = item.unitFee
              if (item.dlvrFee > 0) {
                if (this.waybill.transTp === '1') {
                  ths.waybill.innerDlvrFee =
                    item.dlvrFee * ths.waybill.rcvrAddrs.length
                } else if (this.waybill.transTp === '2') {
                  ths.waybill.innerDlvrFee =
                    item.dlvrFee * (ths.waybill.rcvrAddrs.length - 1)
                }
              }
              // ths.calcWayFeeFlag = true
              throw new Error()
            }
          }
          // 按体积
          if (
            item.ruleTp === this.waybill.priceTp &&
            this.waybill.priceTp === '2' &&
            this.sumVol > item.minVal &&
            this.sumVol <= item.maxVal
          ) {
            if (
              ths.waybill.carTp === null ||
              ths.waybill.carTp === '' ||
              ths.waybill.carTp === 0
            ) {
              ths.waybill.innerPrice = item.unitFee
              if (item.dlvrFee > 0) {
                if (this.waybill.transTp === '1') {
                  ths.waybill.innerDlvrFee =
                    item.dlvrFee * ths.waybill.rcvrAddrs.length
                } else if (this.waybill.transTp === '2') {
                  ths.waybill.innerDlvrFee =
                    item.dlvrFee * (ths.waybill.rcvrAddrs.length - 1)
                }
              }
              // ths.calcWayFeeFlag = true
              throw new Error()
            } else if (ths.waybill.carTp.toString() === item.vehTp) {
              ths.waybill.innerPrice = item.unitFee
              if (item.dlvrFee > 0) {
                if (this.waybill.transTp === '1') {
                  ths.waybill.innerDlvrFee =
                    item.dlvrFee * ths.waybill.rcvrAddrs.length
                } else if (this.waybill.transTp === '2') {
                  ths.waybill.innerDlvrFee =
                    item.dlvrFee * (ths.waybill.rcvrAddrs.length - 1)
                }
              }
              // ths.calcWayFeeFlag = true
              throw new Error()
            }
          }
          // 按整车
          if (
            item.ruleTp === this.waybill.priceTp &&
            this.waybill.priceTp === '4' &&
            this.waybill.carTp === item.minVal
          ) {
            ths.waybill.innerPrice = item.unitFee
            if (item.dlvrFee > 0) {
              if (this.waybill.transTp === '1') {
                ths.waybill.innerDlvrFee =
                  item.dlvrFee * ths.waybill.rcvrAddrs.length
              } else if (this.waybill.transTp === '2') {
                ths.waybill.innerDlvrFee =
                  item.dlvrFee * (ths.waybill.rcvrAddrs.length - 1)
              }
            }
            // ths.calcWayFeeFlag = true
            throw new Error()
          }
        })
      } catch (e) {
        console.log()
      }
      return this.waybill.innerPrice
    },
    sumInnerFee: {
      set: function (newValue) {
        if (!this.calcWayFeeFlag) {
          this.waybill.innerFee = newValue
        }
      },
      get: function () {
        const ths = this
        // 内部价格flag
        if (this.calcWayFeeFlag) {
          // priceTpDisabled:整车true,零担false
          if (!this.priceTpDisabled) {
            if (this.waybill.priceTp === '3') {
              ths.waybill.innerFee = parseFloat(
                Number(
                  this.sumInnerPrice * this.sumQty + this.waybill.innerDlvrFee
                ).toFixed(this.precisions)
              )
            } else if (this.waybill.priceTp === '1') {
              ths.waybill.innerFee = parseFloat(
                Number(
                  this.sumInnerPrice * this.sumWei + this.waybill.innerDlvrFee
                ).toFixed(this.precisions)
              )
            } else if (this.waybill.priceTp === '2') {
              ths.waybill.innerFee = parseFloat(
                Number(
                  this.sumInnerPrice * this.sumVol + this.waybill.innerDlvrFee
                ).toFixed(this.precisions)
              )
            } else if (this.waybill.priceTp === '4') {
              ths.waybill.innerFee = parseFloat(
                Number(this.sumInnerPrice + this.waybill.innerDlvrFee).toFixed(
                  this.precisions
                )
              )
            }
          } else {
            ths.waybill.innerFee = parseFloat(
              Number(this.sumInnerPrice + this.waybill.innerDlvrFee).toFixed(
                this.precisions
              )
            )
          }
        }
        return this.waybill.innerFee
      },
    },
    sumWayFee: {
      set: function (newValue) {
        this.waybill.wayFee = newValue
        this.waybill.price = ''
      },
      get: function () {
        // const ths = this
        // 价格flag  firstEnter:显示已有运单时为true
        /* if (this.calcWayFeeFlag) {
          // priceTpDisabled:整车true,零担false
          if (!this.priceTpDisabled) {
            if (this.waybill.priceTp === '3') {
              ths.waybill.wayFee = Number(this.sumPrice * this.sumQty).toFixed(this.pricePrecision)
            } else if (this.waybill.priceTp === '1') {
              ths.waybill.wayFee = Number(this.sumPrice * this.sumWei).toFixed(this.pricePrecision)
            } else if (this.waybill.priceTp === '2') {
              ths.waybill.wayFee = Number(this.sumPrice * this.sumVol).toFixed(this.pricePrecision)
            } else if (this.waybill.priceTp === '4') {
              ths.waybill.wayFee = Number(this.sumPrice).toFixed(this.pricePrecision)
            }
          } else {
            ths.waybill.wayFee = Number(this.sumPrice).toFixed(this.pricePrecision)
          }
        } else if (!this.calcWayFeeFlag && !this.firstEnter && (this.waybill.wayTp === '2' || this.waybill.wayTp === '3')) {
          if (this.waybill.priceTp === '3') {
            ths.waybill.wayFee = Number(this.sumPrice * this.sumQty).toFixed(this.pricePrecision)
          } else if (this.waybill.priceTp === '1') {
            ths.waybill.wayFee = Number(this.sumPrice * this.sumWei).toFixed(this.pricePrecision)
          } else if (this.waybill.priceTp === '2') {
            ths.waybill.wayFee = Number(this.sumPrice * this.sumVol).toFixed(this.pricePrecision)
          } else if (this.waybill.priceTp === '4') {
            ths.waybill.wayFee = Number(this.sumPrice).toFixed(this.pricePrecision)
          }
        }*/
        /* if (this.waybill.price !== '' && this.waybill.price > 0) {
          if (this.waybill.priceTp === '3') {
            ths.waybill.wayFee = Number(this.waybill.price * this.sumQty).toFixed(this.pricePrecision)
          } else if (this.waybill.priceTp === '1') {
            ths.waybill.wayFee = Number(this.waybill.price * this.sumWei).toFixed(this.pricePrecision)
          } else if (this.waybill.priceTp === '2') {
            ths.waybill.wayFee = Number(this.waybill.price * this.sumVol).toFixed(this.pricePrecision)
          } else if (this.waybill.priceTp === '4') {
            ths.waybill.wayFee = Number(this.waybill.price).toFixed(this.pricePrecision)
          }
        }*/
        return this.waybill.wayFee
      },
    },
  },
  watch: {
    cnorId(v) {
      if (this.waybill.wayTp !== '2') {
        this.cnorOptions.forEach((item) => {
          if (item.cnorId === v) {
            this.waybill.cnorNm = item.cnorNm
            this.waybill.cnorAddr = item.cnorAddr
          }
        })
        const cnorCtParams = {
          cnorId: v,
          searchAuthorized: true,
          pageNumber: 1,
          pageSize: 99999,
        }
        queryConsignorContactList(cnorCtParams).then((response) => {
          if (response.code === '000') {
            this.cnorCtOptions = response.data.items
          }
        })
        const cnorAddrParams = {
          cnorId: v,
          searchAuthorized: true,
          pageNumber: 1,
          pageSize: 99999,
        }
        queryConsignorAddrList(cnorAddrParams).then((response) => {
          if (response.code === '000') {
            this.cnorAddrOption = response.data.items
            this.cnorAddrOption.forEach((item) => {
              if (item.cnorAddrs !== undefined) {
                this.cnorAddrOptions = item.cnorAddrs
              }
            })
          }
        })
        const rcvrParams = {
          cnorId: v,
          pageNumber: 1,
          pageSize: 99999,
        }
        queryBaseReceiverList(rcvrParams).then((response) => {
          if (response.code === '000') {
            if (!this.firstEnter) {
              this.rcvrId = 0
            }
            this.rcvrOptions = response.data.items
            if (this.opt === 'generator') {
              this.rcvrOptions.forEach((item) => {
                if (item.rcvrId === this.rcvrId) {
                  this.rcvrAddrOptions = item.rcvrAddrs
                  this.waybill.rcvrNm = item.rcvrNm
                  this.waybill.rcvrAddr = item.rcvrAddr
                }
              })
            }
          }
        })
        const cargoParams = {
          cnorId: v,
          pageNumber: 1,
          pageSize: 99999,
        }
        queryBaseCargoList(cargoParams).then((response) => {
          if (response.code === '000') {
            this.cargoOptions = response.data.items
          }
        })
        this.cnorOptions.forEach((item) => {
          if (item.cnorId === v) {
            this.planId = item.planId
            this.waybill.planId = item.planId
          }
        })
        this.calcPricePlan()
        this.calcInnerPricePlan()
      }
      if (this.waybill.wayTp === '2') {
        if (!this.firstEnter) {
          if (v === 0) {
            this.waybill.cnorNm = ''
            this.waybill.cnorCtcts = ''
            this.waybill.cnorTel = ''
            this.waybill.cnorAddr = ''
            return
          }
        }
        this.cnorOptions.forEach((item) => {
          if (item.cnorId === v) {
            this.waybill.cnorNm = item.cnorNm
            this.waybill.cnorCtcts = item.cnorCtcts
            this.waybill.cnorTel = item.cnorTel
            this.waybill.cnorAddr = item.cnorAddr
          }
        })
        const cargoParams = {
          cnorId: v,
          pageNumber: 1,
          pageSize: 99999,
        }
        queryBaseCargoList(cargoParams).then((response) => {
          if (response.code === '000') {
            this.cargoOptions = response.data.items
          }
        })
        this.cnorOptions.forEach((item) => {
          if (item.cnorId === v) {
            this.planId = item.planId
            this.waybill.planId = item.planId
          }
        })
        this.calcPricePlan()
        this.calcInnerPricePlan()
      }
    },
    rcvrId(v) {
      if (this.waybill.wayTp !== '2') {
        // 返程运单收货方和发货方颠倒
        this.rcvrOptions.forEach((item) => {
          if (item.rcvrId === v) {
            this.rcvrAddrOptions = item.rcvrAddrs
            this.waybill.rcvrNm = item.rcvrNm
            this.waybill.rcvrAddr = item.rcvrAddr
          }
        })
        const rcvrCtParams = {
          rcvrId: v,
          searchAuthorized: true,
          pageNumber: 1,
          pageSize: 99999,
        }
        queryBaseReceiverContactList(rcvrCtParams).then((response) => {
          if (response.code === '000') {
            this.rcvrCtOptions = response.data.items
            this.rcvrCtOptions.forEach((item) => {
              this.rcvrContactId = item.rcvrContactId
              this.waybill.rcvrCtcts = item.rcvrCtcts
              this.waybill.rcvrTel = item.rcvrTel
            })
          }
        })
        const cnorParams = {
          rcvrId: v,
          pageNumber: 1,
          pageSize: 99999,
        }
        queryBaseConsignorList(cnorParams).then((response) => {
          if (response.code === '000') {
            if (!this.firstEnter) {
              this.cnorId = 0
            }
            this.cnorOptions = response.data.items
          }
        })
      }
      if (this.waybill.wayTp === '2') {
        if (!this.firstEnter) {
          if (v === 0) {
            this.waybill.rcvrNm = ''
            this.waybill.rcvrCtcts = ''
            this.waybill.rcvrTel = ''
            this.waybill.rcvrAddr = ''
            return
          }
        }
        this.rcvrOptions.forEach((item) => {
          if (item.rcvrId === v) {
            this.rcvrAddrOptions = item.rcvrAddrs
            this.waybill.rcvrNm = item.rcvrNm
            this.waybill.rcvrCtcts = item.rcvrCtcts
            this.waybill.rcvrTel = item.rcvrTel
            this.waybill.rcvrAddr = item.rcvrAddr
          }
        })
      }
    },
    'waybill.wayTp': {
      handler: function (v) {
        /* if (this.wayTpChangeFlag === false && this.waybill.wayTp !== '3') {
          this.cnorOptions = []
          this.rcvrOptions = []
          this.pricePlans = []
          this.cnorId = 0
          this.rcvrId = 0
          this.innerPricePlans = []
        }*/
        this.wayTpChangeFlag = false
        if (v === '2') {
          const rcvrParams = {
            pageNumber: 1,
            pageSize: 99999,
            searchAuthorized: true,
          }
          queryBaseReceiverList(rcvrParams).then((response) => {
            if (response.code === '000') {
              this.rcvrOptions = response.data.items
            }
          })
        } else {
          /* 不知道有啥用，加上之后网络货运的用户就没办法展示发货单位信息
          this.queryBaseConsignorList()
          */
        }
      },
    },
    'waybill.wayId': {
      handler: function (v) {
        if (!this.isEmptyStr(v)) {
          this.validFields.wayId = false
        }
      },
    },
    'waybill.sendDt': {
      handler: function (v) {
        if (!this.isEmptyStr(v)) {
          this.validFields.sendDt = false
        }
      },
    },
    'waybill.strtList': {
      handler: function (v) {
        if (v != null) {
          console.log(v)
          this.validFields.strtList = false
        }
        this.calcPricePlan()
        this.calcInnerPricePlan()
      },
    },
    'waybill.destList': {
      handler: function (v) {
        if (v != null) {
          console.log(v)
          this.validFields.destList = false
        }
        this.calcPricePlan()
        this.calcInnerPricePlan()
      },
    },
    'waybill.cnorNm': {
      handler: function (v) {
        if (!this.isEmptyStr(v)) {
          this.validFields.cnorNm = false
        }
      },
    },
    'waybill.cnorCtcts': {
      handler: function (v) {
        if (!this.isEmptyStr(v)) {
          this.validFields.cnorCtcts = false
        }
      },
    },
    'waybill.cnorTel': {
      handler: function (v) {
        if (!this.isEmptyStr(v)) {
          this.validFields.cnorTel = false
        }
      },
    },
    'waybill.cnorAddr': {
      handler: function (v) {
        if (!this.isEmptyStr(v)) {
          this.validFields.cnorAddr = false
        }
      },
    },
    'waybill.rcvrNm': {
      handler: function (v) {
        if (!this.isEmptyStr(v)) {
          this.validFields.rcvrNm = false
        }
      },
    },
    'waybill.rcvrCtcts': {
      handler: function (v) {
        if (!this.isEmptyStr(v)) {
          this.validFields.rcvrCtcts = false
        }
      },
    },
    'waybill.rcvrTel': {
      handler: function (v) {
        if (!this.isEmptyStr(v)) {
          this.validFields.rcvrTel = false
        }
      },
    },
    'waybill.rcvrAddr': {
      handler: function (v) {
        if (!this.isEmptyStr(v)) {
          this.validFields.rcvrAddr = false
        }
      },
    },
    'waybill.payTp': {
      handler: function (v) {
        if (!this.isEmptyStr(v)) {
          this.validFields.payTp = false
        }
      },
    },
    'waybill.wayFee': {
      handler: function (v) {
        if (!this.isEmptyStr(v) && v > 0) {
          this.validFields.wayFee = false
        }
      },
    },
    'waybill.deliveryDt': {
      handler: function (v) {
        if (!this.isEmptyStr(v) && v > 0) {
          this.validFields.deliveryDt = false
        }
      },
    },
    'waybill.expectDt': {
      handler: function (v) {
        if (!this.isEmptyStr(v) && v > 0) {
          this.validFields.expectDt = false
        }
      },
    },
    'waybill.warningDt': {
      handler: function (v) {
        if (!this.isEmptyStr(v) && v > 0) {
          this.validFields.warningDt = false
        }
      },
    },
    'waybill.cashFee': {
      handler: function (v) {
        if (!this.isEmptyStr(v) && v > 0) {
          this.validFields.cashFee = false
          this.waybill.cashStatus = 1
        }
      },
    },
    'waybill.collectFee': {
      handler: function (v) {
        if (!this.isEmptyStr(v) && v > 0) {
          this.validFields.collectFee = false
          this.waybill.collectStatus = 1
        }
      },
    },
    wayId: {
      handler: function (v) {
        this.init()
      },
    },
  },
  created() {
    this.checkingFlag = this.$route.query.checkingFlag
    // this.opt === 'generator'从订单生成计划单
    if (this.opt === 'generator') {
      this.anew()
    } else {
      this.init()
    }
  },
  methods: {
    setMapCity(map) {
      AMap.plugin('AMap.CitySearch', function () {
        var citySearch = new AMap.CitySearch()
        citySearch.getLocalCity(function (status, result) {
          if (status === 'complete' && result.info === 'OK') {
            // 查询成功，result即为当前所在城市信息
            map.setCity(result.city)
          } else {
            map.setCity('杭州市')
          }
        })
      })
    },
    confirmDetailAddr() {
      this.mapDialogVisible = false
    },
    showMap() {
      var self = this
      var map = this.amapManager.getMap()
      if (self.positionPicker !== null) {
        self.positionPicker.stop()
      }
      if (self.infoWindow !== null) {
        self.infoWindow.close()
      }
      this.poiValue = ''
      this.mapDialogVisible = true
      if (map !== null) {
        if (this.waybill.rcvrAddr === '') {
          this.setMapCity(map)
        }
        if (this.isEdit) {
          if (this.waybill.rcvrAddr !== '' && self.lng > 0 && self.lat > 0) {
            self.location = []
            self.location.push(self.lng)
            self.location.push(self.lat)
            map.setCenter(self.location)
            map.setZoom(14)
            self.positionPicker.start(self.location)
          }
          if (self.lng === null && self.lat === null) {
            this.setMapCity(map)
          }
        }
      }
    },
    changeReceiptReq() {
      if (this.waybill.receiptReq === '1') {
        this.waybill.receiptQty = 1
        this.receiptQtyDisStatus = false
      } else {
        this.waybill.receiptQty = 0
        this.receiptQtyDisStatus = true
      }
    },
    async anew() {
      this.firstEnter = true
      this.modifyFlag = false
      this.waybill = this.waybillJson
      this.waybill.sendDt = new Date()
      this.waybill.urgency = '1'
      this.rcvrId = this.waybillJson.rcvrId
      this.cargoInsts = this.waybillJson.cargoInsts
      if (this.cargoInsts.length >= 1) {
        this.cargoInsts[0].optFlg = true
        this.cargoInsts.forEach((item) => {
          // if (item.cgId === null) {
          item.cgId = item.cgNm
          // }
        })
      }
      const cnorParams = {
        cnorId: this.waybillJson.cnorId,
        pageNumber: 1,
        pageSize: 99999,
      }
      await queryBaseConsignorList(cnorParams).then((response) => {
        if (response.code === '000') {
          this.cnorOptions = response.data.items
          if (this.waybill.wayTp === '1') {
            this.cnorOptions.forEach((item) => {
              if (item.cnorId === this.cnorId) {
                this.planId = item.planId
                this.waybill.planId = item.planId
              }
            })
          }
        }
      })
      const cnorAddrParams = {
        cnorAddrId: this.planbill.cnorAddrId,
        pageNumber: 1,
        pageSize: 99999,
      }
      queryConsignorAddrList(cnorAddrParams).then((response) => {
        if (response.code === '000') {
          this.cnorAddrOptions = response.data.items
          this.cnorAddrOptions.forEach((item) => {
            if (item.cnorId === this.cnorId) {
              this.planbill.cnorAddrId = item.cnorAddrId
              this.planbill.planId = item.planId
              this.cnorAddrOptions = item.cnorAddrs
            }
          })
        }
      })
      const rcvrCtParams = {
        rcvrContactId: this.rcvrContactId,
        pageNumber: 1,
        pageSize: 99999,
      }
      queryBaseReceiverContactList(rcvrCtParams).then((response) => {
        if (response.code === '000') {
          this.rcvrCtOptions = response.data
        }
      })
      this.waybill.payTp = '4'
      // this.waybill.otherFee = 0
      this.cnorId = this.waybillJson.cnorId
      // this.changeTransTp(this.waybill.transTp)
    },
    async init() {
      if (this.opt === 'create') {
        this.waybill.sendDt = new Date()
        // this.queryPrintScripts()
        if (this.cargoInsts.length < 1) {
          this.cargoInst.optFlg = true
        }
        this.cargoInsts.push(JSON.parse(JSON.stringify(this.cargoInst)))
        this.cargoInst.optFlg = false

        if (this.waybill.rcvrAddrs.length < 1) {
          this.rcvrAddr.optFlg = true
        }
        this.waybill.rcvrAddrs.push(JSON.parse(JSON.stringify(this.rcvrAddr)))
        this.rcvrAddr.optFlg = false
      } else if (this.opt === 'detail') {
        this.isSaved = true
      }
      if (this.opt === 'detail' || this.opt === 'edit') {
        this.isEdit = true
        this.wayFeeSyncStatus = false
      }
      this.modifyFlag = false
      await this.queryBaseConsignorList()
      const addrs = this.waybill.rcvrAddrs.map((item) => {
        return item.addrNm
      })
      const temp = JSON.stringify(this.cargoInsts)
      this.initRecord = {
        strt: this.waybill.strtList.aeId,
        strtCode: this.waybill.strtList.aeParCode,
        dest: this.waybill.destList.aeId,
        destCode: this.waybill.destList.aeParCode,
        wayTp: this.waybill.wayTp,
        cnorNbr: this.waybill.cnorNbr,
        transTp: this.waybill.transTp,
        recvTp: this.waybill.recvTp,
        carTp: this.waybill.carTp,
        urgency: this.waybill.urgency,
        rcvrId: this.rcvrId,
        addrs: addrs,
        cnorId: this.cnorId,
        cargoInsts: JSON.parse(temp),
        priceTp: this.waybill.priceTp,
        payTp: this.waybill.payTp,
        receiptReq: this.waybill.receiptReq,
        receiptQty: this.waybill.receiptQty,
        sumWayFee: this.sumWayFee,
        dlvrFee: this.waybill.dlvrFee,
        otherFee: this.waybill.otherFee,
        jhFee: this.jhFee,
        deliveryDt: this.waybill.deliveryDt,
        expectDt: this.waybill.expectDt,
        warningDt: this.waybill.warningDt,
        remark: this.waybill.remark,
        cashFee: this.waybill.cashFee,
        collectFee: this.waybill.collectFee,
        monthlyFee: this.waybill.monthlyFee,
      }
    },
    calcWeiAndVol(obj) {
      this.cargoOptions.forEach((item) => {
        if (item.cgId === obj.cgId) {
          if (item.unitVol !== undefined) {
            obj.instVol = parseFloat(
              Number(item.unitVol * obj.instQty).toFixed(this.precisions)
            )
          }
          if (item.netWei !== undefined) {
            obj.instWei = parseFloat(
              Number(item.netWei * obj.instQty).toFixed(this.precisions)
            )
          }
          if (item.grossWei !== undefined) {
            obj.instGrossWei = parseFloat(
              Number(item.grossWei * obj.instQty).toFixed(this.precisions)
            )
          }
        }
      })
      this.wayFeeSyncStatus = true
      this.calcCargoWayFee(obj)
    },
    calcWei(obj) {
      obj.instGrossWei = obj.instWei
      this.wayFeeSyncStatus = true
      this.calcCargoWayFee(obj)
    },
    calcGrossWei(obj) {
      this.wayFeeSyncStatus = true
      this.calcCargoWayFee(obj)
    },
    calcInstVol(obj) {
      this.wayFeeSyncStatus = true
      this.calcCargoWayFee(obj)
    },
    calcPrice(obj) {
      obj.price = obj.price.replace(/[^\d.]/g, '')
      if (obj.price !== '' && obj.price > 0) {
        if (obj.priceTp === '3') {
          obj.cargoWayFee = parseFloat(
            Number(obj.price * obj.instQty).toFixed(this.precisions)
          )
        } else if (obj.priceTp === '1') {
          obj.cargoWayFee = parseFloat(
            Number(obj.price * obj.instWei).toFixed(this.precisions)
          )
        } else if (obj.priceTp === '2') {
          obj.cargoWayFee = parseFloat(
            Number(obj.price * obj.instVol).toFixed(this.precisions)
          )
        } else if (obj.priceTp === '4') {
          obj.cargoWayFee = parseFloat(
            Number(obj.price).toFixed(this.precisions)
          )
        }
      } else {
        obj.cargoWayFee = 0
      }
      this.wayFeeSyncStatus = true
    },
    calcDlvrFee(obj) {
      obj.dlvrFee = obj.dlvrFee.replace(/[^\d.]/g, '')
      this.wayFeeSyncStatus = true
    },
    calcWayFee(obj) {
      obj.cargoWayFee = obj.cargoWayFee.replace(/[^\d.]/g, '')
      obj.price = 0
      this.wayFeeSyncStatus = true
    },
    changePriceTp(obj) {
      this.wayFeeSyncStatus = true
      this.calcCargoWayFee(obj)
    },
    calcCargoWayFee(obj) {
      const params = {
        strt: this.waybill.strtList.aeId,
        dest: this.waybill.destList.aeId,
        planId: this.waybill.planId,
        carTp: this.waybill.carTp,
        priceTp: obj.priceTp,
        instQty: obj.instQty,
        instWei: obj.instWei,
        instVol: obj.instVol,
      }
      console.log(params)
      getCustPrice(params).then((response) => {
        if (response.code === '000') {
          if (response.data.data.length > 0 && response.data.data !== null) {
            obj.price = response.data.data[0].unitFee
            obj.dlvrFee = response.data.data[0].dlvrFee
          } else {
            obj.price = 0
            obj.dlvrFee = 0
          }
          if (
            obj.price !== '' &&
            obj.price !== null &&
            obj.price !== undefined &&
            obj.price > 0
          ) {
            if (obj.priceTp === '3') {
              obj.cargoWayFee = parseFloat(
                Number(obj.price * obj.instQty).toFixed(this.precisions)
              )
            } else if (obj.priceTp === '1') {
              obj.cargoWayFee = parseFloat(
                Number(obj.price * obj.instWei).toFixed(this.precisions)
              )
            } else if (obj.priceTp === '2') {
              obj.cargoWayFee = parseFloat(
                Number(obj.price * obj.instVol).toFixed(this.precisions)
              )
            } else if (obj.priceTp === '4') {
              obj.cargoWayFee = parseFloat(
                Number(obj.price).toFixed(this.precisions)
              )
            }
          } else {
            obj.dlvrFee = 0
          }
        }
      })
    },
    cashFeeBlur() {
      if (this.waybill.payTp === '6') {
        this.waybill.collectFee = this.jhFee - this.waybill.cashFee
      }
    },
    collectFeeBlur() {
      if (this.waybill.payTp === '6') {
        this.waybill.cashFee = this.jhFee - this.waybill.collectFee
      }
    },
    async acquireBizWayBill() {
      this.payTps = [
        { value: '1', label: '现付' },
        { value: '2', label: '到付' },
        { value: '4', label: '月结' },
        { value: '6', label: '多笔付' },
      ]
      this.priceTps = [
        {
          value: '3',
          label: '/件',
        },
        {
          value: '1',
          label: '/吨',
        },
        {
          value: '2',
          label: '/方',
        },
        {
          value: '4',
          label: '/车',
        },
      ]
      var wayParams = {
        wayId: this.wayId,
      }
      // 查询调度信息，只在详情页展示数据
      await queryAllDisp(wayParams).then((dispResp) => {
        if (dispResp.code === '000') {
          this.outSourceData = dispResp.data.outSourceData
          this.dispData = dispResp.data.dispData
          this.transhipData = dispResp.data.transhipData
        }
      })
      await acquireBizWayBill(wayParams).then(async (response) => {
        if (response.code === '000') {
          this.waybill = response.data
          this.doChangePayTp(response.data.payTp)
          this.changeReceiptReq()
          this.waybill.strtList = {}
          this.waybill.destList = {}
          this.waybill.wayIdsub = this.waybill.wayId.includes('TT-') ? this.waybill.wayId.substring(3, 13) : this.waybill.wayId
          this.waybill.strtList.aeId = response.data.strt
          this.waybill.strtList.aeParCode = response.data.strtCode
          this.waybill.destList.aeId = response.data.dest
          this.waybill.destList.aeParCode = response.data.destCode
          this.acqDlvrFee = response.data.dlvrFee
          // this.strt = this.waybill.strt
          // this.dest = this.waybill.dest
          if (this.waybill.wayTp !== '1') {
            this.wayTpChangeFlag = true
          }
          if (response.data.carrierBillStatus === '1') {
            this.modifyFlag = true
          }
          this.firstEnter = true
          this.sumWayFee = this.waybill.wayFee
          this.cnorId = this.waybill.cnorId
          this.rcvrId = this.waybill.rcvrId
          this.cnorContactId = this.waybill.cnorContactId
          this.rcvrContactId = this.waybill.rcvrContactId
          this.cargoInsts = response.data.cargoInsts
          this.waybill.carTp = Number(response.data.carTp)
          if (this.cargoInsts.length >= 1) {
            this.cargoInsts[0].optFlg = true
            this.cargoInsts.forEach((item) => {
              // if (item.cgId === null) {
              // 直接赋值为名字是为了页面能够直接获取值，而不是Id
              item.cgId = item.cgNm
              // }
            })
          }
          this.cnorOptions.forEach((item) => {
            if (item.cnorId === this.waybill.cnorId) {
              this.waybill.planId = item.planId
            }
          })
          const rcvrParams = {
            cnorId: this.cnorId,
            pageNumber: 1,
            pageSize: 99999,
          }
          await queryBaseReceiverList(rcvrParams).then((response) => {
            if (response.code === '000') {
              if (!this.firstEnter) {
                this.rcvrId = 0
              }
              this.rcvrOptions = response.data.items
              this.rcvrOptions.forEach((item) => {
                if (item.rcvrId === this.rcvrId) {
                  this.rcvrAddrOptions = item.rcvrAddrs
                }
              })
            }
          })
          this.waybill.rcvrAddrs = response.data.rcvrAddrs
          if (this.waybill.rcvrAddrs.length >= 1) {
            this.waybill.rcvrAddrs[0].optFlg = true
          }
        }
      })
    },
    async queryBaseConsignorList() {
      const cnorParams = {
        pageNumber: 1,
        pageSize: 99999,
      }
      if (this.waybill.wayTp === '1') {
        cnorParams.searchAuthorized = true
      }
      const response = await queryBaseConsignorList(cnorParams)
      if (response.code === '000') {
        this.cnorOptions = response.data.items
        if (
          this.opt === 'edit' ||
          this.opt === 'detail' ||
          this.opt === 'checkIn'
        ) {
          await this.acquireBizWayBill()
        }
      }
    },
    sumConFee() {
      var ths = this
      this.priceTpFlag = false
      var qtyPrice = 0
      var weiPrice = 0
      var volPrice = 0
      var vehPrice = 0
      var qtyDlvrFee = 0
      var weiDlvrFee = 0
      var volDlvrFee = 0
      var vehDlvrFee = 0
      // 加比例
      this.pricePlans.forEach((item) => {
        // 按数量
        if (
          item.ruleTp === '3' &&
          this.waybill.transTp === '1' &&
          this.sumQty > item.minVal &&
          this.sumQty <= item.maxVal
        ) {
          if (
            ths.waybill.carTp === null ||
            ths.waybill.carTp === '' ||
            ths.waybill.carTp === 0
          ) {
            if (item.priceRatio !== '' && item.priceRatio !== null) {
              if (
                item.priceRatio.substring(0, 1) === '+' ||
                item.priceRatio.substring(0, 1) === '-'
              ) {
                const priceRatio = item.priceRatio.substring(
                  0,
                  item.priceRatio.lastIndexOf('%')
                )
                qtyPrice = Math.round(item.unitFee * (1 + priceRatio / 100))
              } else {
                const priceRatio2 = item.priceRatio.substring(
                  0,
                  item.priceRatio.lastIndexOf('%')
                )
                qtyPrice = Math.round(item.unitFee * (1 + priceRatio2 / 100))
              }
            } else {
              qtyPrice = item.unitFee
            }
          } else if (ths.waybill.carTp.toString() === item.vehTp) {
            if (item.priceRatio !== '' && item.priceRatio !== null) {
              if (
                item.priceRatio.substring(0, 1) === '+' ||
                item.priceRatio.substring(0, 1) === '-'
              ) {
                const priceRatio = item.priceRatio.substring(
                  0,
                  item.priceRatio.lastIndexOf('%')
                )
                qtyPrice = Math.round(item.unitFee * (1 + priceRatio / 100))
              } else {
                const priceRatio2 = item.priceRatio.substring(
                  0,
                  item.priceRatio.lastIndexOf('%')
                )
                qtyPrice = Math.round(item.unitFee * (1 + priceRatio2 / 100))
              }
            } else {
              qtyPrice = item.unitFee
            }
          }
          // 计划单生成运单
          if (this.opt === 'generator' && item.dlvrFee > 0) {
            if (this.waybill.transTp === '1') {
              qtyDlvrFee = item.dlvrFee * ths.waybill.rcvrAddrs.length
            } else if (this.waybill.transTp === '2') {
              qtyDlvrFee = item.dlvrFee * (ths.waybill.rcvrAddrs.length - 1)
            }
          }
        }
        // 按重量
        if (
          item.ruleTp === '1' &&
          this.waybill.transTp === '1' &&
          this.sumWei > item.minVal &&
          this.sumWei <= item.maxVal
        ) {
          if (
            ths.waybill.carTp === null ||
            ths.waybill.carTp === '' ||
            ths.waybill.carTp === 0 ||
            ths.waybill.carTp === undefined
          ) {
            if (item.priceRatio !== '' && item.priceRatio !== null) {
              if (
                item.priceRatio.substring(0, 1) === '+' ||
                item.priceRatio.substring(0, 1) === '-'
              ) {
                const priceRatio = item.priceRatio.substring(
                  0,
                  item.priceRatio.lastIndexOf('%')
                )
                weiPrice = Math.round(item.unitFee * (1 + priceRatio / 100))
              } else {
                const priceRatio2 = item.priceRatio.substring(
                  0,
                  item.priceRatio.lastIndexOf('%')
                )
                weiPrice = Math.round(item.unitFee * (1 + priceRatio2 / 100))
              }
            } else {
              weiPrice = item.unitFee
            }
          } else if (ths.waybill.carTp.toString() === item.vehTp) {
            if (item.priceRatio !== '' && item.priceRatio !== null) {
              if (
                item.priceRatio.substring(0, 1) === '+' ||
                item.priceRatio.substring(0, 1) === '-'
              ) {
                const priceRatio = item.priceRatio.substring(
                  0,
                  item.priceRatio.lastIndexOf('%')
                )
                weiPrice = Math.round(item.unitFee * (1 + priceRatio / 100))
              } else {
                const priceRatio2 = item.priceRatio.substring(
                  0,
                  item.priceRatio.lastIndexOf('%')
                )
                weiPrice = Math.round(item.unitFee * (1 + priceRatio2 / 100))
              }
            } else {
              weiPrice = item.unitFee
            }
          }
          // 计划单生成运单
          if (this.opt === 'generator' && item.dlvrFee > 0) {
            if (this.waybill.transTp === '1') {
              weiDlvrFee = item.dlvrFee * ths.waybill.rcvrAddrs.length
            } else if (this.waybill.transTp === '2') {
              weiDlvrFee = item.dlvrFee * (ths.waybill.rcvrAddrs.length - 1)
            }
          }
        }
        // 按体积
        if (
          item.ruleTp === '2' &&
          this.waybill.transTp === '1' &&
          this.sumVol > item.minVal &&
          this.sumVol <= item.maxVal
        ) {
          if (
            ths.waybill.carTp === null ||
            ths.waybill.carTp === '' ||
            ths.waybill.carTp === 0
          ) {
            if (item.priceRatio !== '' && item.priceRatio !== null) {
              if (
                item.priceRatio.substring(0, 1) === '+' ||
                item.priceRatio.substring(0, 1) === '-'
              ) {
                const priceRatio = item.priceRatio.substring(
                  0,
                  item.priceRatio.lastIndexOf('%')
                )
                volPrice = Math.round(item.unitFee * (1 + priceRatio / 100))
              } else {
                const priceRatio2 = item.priceRatio.substring(
                  0,
                  item.priceRatio.lastIndexOf('%')
                )
                volPrice = Math.round(item.unitFee * (1 + priceRatio2 / 100))
              }
            } else {
              volPrice = item.unitFee
            }
          } else if (ths.waybill.carTp.toString() === item.vehTp) {
            if (item.priceRatio !== '' && item.priceRatio !== null) {
              if (
                item.priceRatio.substring(0, 1) === '+' ||
                item.priceRatio.substring(0, 1) === '-'
              ) {
                const priceRatio = item.priceRatio.substring(
                  0,
                  item.priceRatio.lastIndexOf('%')
                )
                volPrice = Math.round(item.unitFee * (1 + priceRatio / 100))
              } else {
                const priceRatio2 = item.priceRatio.substring(
                  0,
                  item.priceRatio.lastIndexOf('%')
                )
                volPrice = Math.round(item.unitFee * (1 + priceRatio2 / 100))
              }
            } else {
              volPrice = item.unitFee
            }
          }
          // 计划单生成运单
          if (this.opt === 'generator' && item.dlvrFee > 0) {
            if (this.waybill.transTp === '1') {
              volDlvrFee = item.dlvrFee * ths.waybill.rcvrAddrs.length
            } else if (this.waybill.transTp === '2') {
              volDlvrFee = item.dlvrFee * (ths.waybill.rcvrAddrs.length - 1)
            }
          }
        }
        // 按整车
        if (
          item.ruleTp === '4' &&
          this.waybill.transTp === '2' &&
          this.waybill.carTp === item.minVal
        ) {
          if (item.priceRatio !== '' && item.priceRatio !== null) {
            if (
              item.priceRatio.substring(0, 1) === '+' ||
              item.priceRatio.substring(0, 1) === '-'
            ) {
              const priceRatio = item.priceRatio.substring(
                0,
                item.priceRatio.lastIndexOf('%')
              )
              vehPrice = Math.round(item.unitFee * (1 + priceRatio / 100))
            } else {
              const priceRatio2 = item.priceRatio.substring(
                0,
                item.priceRatio.lastIndexOf('%')
              )
              vehPrice = Math.round(item.unitFee * (1 + priceRatio2 / 100))
            }
          } else {
            vehPrice = item.unitFee
          }
          // 计划单生成运单
          if (this.opt === 'generator' && item.dlvrFee > 0) {
            if (this.waybill.transTp === '1') {
              vehDlvrFee = item.dlvrFee * ths.waybill.rcvrAddrs.length
            } else if (this.waybill.transTp === '2') {
              vehDlvrFee = item.dlvrFee * (ths.waybill.rcvrAddrs.length - 1)
            }
          }
        }
      })
      this.qtyFee = parseFloat(
        Number(qtyPrice * this.sumQty).toFixed(this.precisions)
      )
      this.weiFee = parseFloat(
        Number(weiPrice * this.sumWei).toFixed(this.precisions)
      )
      this.volFee = parseFloat(
        Number(volPrice * this.sumVol).toFixed(this.precisions)
      )
      this.vehFee = parseFloat(Number(vehPrice).toFixed(this.precisions))
      var ary = [
        { id: 0, value: this.qtyFee, dlvrFee: qtyDlvrFee },
        { id: 1, value: this.weiFee, dlvrFee: weiDlvrFee },
        { id: 2, value: this.volFee, dlvrFee: volDlvrFee },
        { id: 3, value: this.vehFee, dlvrFee: vehDlvrFee },
      ]
      ary.sort(this.sortBy('value'))
      if (ary[3].value * 100 !== 0) {
        this.conFee = ary[3].value
        this.waybill.priceTp = this.priceTps[ary[3].id].value
        if (this.opt === 'generator') {
          this.waybill.dlvrFee = ary[3].dlvrFee
        }
        // this.priceTpFlag = true
        // this.sumFeeDialog = true
      } else if (
        ary[3].value * 100 === 0 &&
        (this.waybill.wayTp === '3' || this.waybill.wayTp === '2')
      ) {
        // this.$message.info('没有价格数据，请选择计费方式并输入价格！')
      } else {
        // this.$message.error('运费为零，请维护基础信息或将运单信息填写完整！')
      }
    },
    sortBy(key) {
      return function (a, b) {
        var result =
          a[key] * 100 < b[key] * 100 ? -1 : a[key] * 100 > b[key] * 100 ? 1 : 0
        return result
      }
    },
    sortNumber(key) {
      return function (a, b) {
        return a[key] - b[key]
      }
    },
    
    chooseInnerFee() {
      if (this.conInnerData !== '') {
        const conInner = this.conInnerData.split(',')
        this.waybill.transWay = conInner[0]
        this.waybill.innerPrice = conInner[1]
        this.waybill.innerFee = parseFloat(
          (
            (Number(conInner[2]) * 100 +
              Number(this.waybill.innerOtherFee) * 100) /
            100
          ).toFixed(this.precisions)
        )
        if (
          Number(this.jhFee) < Number(this.waybill.innerFee) &&
          this.waybill.payTp !== '3'
        ) {
          this.$message.error('内部结算价不能大于接货价！')
          return
        }
        this.sumInnerFeeDialog = false
        this.submit()
      } else {
        this.$message.error('请选择内部结算价计算方式！')
      }
    },
    submitWayBill() {
      var params = {
        type: 1,
      }
      getSerialNumber(params).then((response) => {
        if (
          response.code === '000' &&
          this.opt !== 'checkIn' &&
          this.opt !== 'edit'
        ) {
          this.waybill.wayId = response.data
        }
        // 校验
        if (this.isEmptyStr(this.waybill.wayId)) {
          this.validFields.wayId = true
        }
        if (this.isEmptyStr(this.waybill.sendDt)) {
          this.validFields.sendDt = true
        }
        if (this.isEmptyStr(this.waybill.strtList.aeId)) {
          this.validFields.strtList = true
        }
        if (this.isEmptyStr(this.waybill.destList.aeId)) {
          this.validFields.destList = true
        }
        if (this.isEmptyStr(this.waybill.cnorNm)) {
          this.validFields.cnorNm = true
        }
        if (this.isEmptyStr(this.waybill.cnorCtcts)) {
          this.validFields.cnorCtcts = true
        }
        if (this.isEmptyStr(this.waybill.cnorTel)) {
          this.validFields.cnorTel = true
        }
        if (this.isEmptyStr(this.waybill.cnorAddr)) {
          this.validFields.cnorAddr = true
        }
        if (this.isEmptyStr(this.waybill.rcvrNm)) {
          this.validFields.rcvrNm = true
        }
        if (this.isEmptyStr(this.waybill.rcvrCtcts)) {
          this.validFields.rcvrCtcts = true
        }
        if (this.isEmptyStr(this.waybill.rcvrTel)) {
          this.validFields.rcvrTel = true
        }
        if (
          this.isEmptyStr(this.waybill.payTp) ||
          this.waybill.payTp === null
        ) {
          this.validFields.payTp = true
        }
        let cargoInstsFlag = false
        this.cargoInsts.forEach((item) => {
          item.validFields = {}
          if (item.cgId === undefined || this.isEmptyStr(item.cgId)) {
            cargoInstsFlag = true
            item.validFields.cgId = true
          } else {
            item.validFields.cgId = false
          }
          if (
            (this.isEmptyStr(item.instQty) || item.instQty <= 0) &&
            (this.isEmptyStr(item.instWei) || item.instWei <= 0) &&
            (this.isEmptyStr(item.instGrossWei) || item.instGrossWei <= 0) &&
            (this.isEmptyStr(item.instVol) || item.instVol <= 0)
          ) {
            cargoInstsFlag = true
          }
          /* if (item.priceTp === '1' && (this.isEmptyStr(item.instWei) || item.instWei <= 0)) {
            cargoInstsFlag = true
          }
          if (item.priceTp === '2' && (this.isEmptyStr(item.instVol) || item.instVol <= 0)) {
            cargoInstsFlag = true
          }
          if (item.priceTp === '3' && (this.isEmptyStr(item.instQty) || item.instQty <= 0)) {
            cargoInstsFlag = true
          }
          if (item.cargoWayFee === undefined || this.isEmptyStr(item.cargoWayFee) || item.cargoWayFee <= 0) {
            cargoInstsFlag = true
            item.validFields.cargoWayFee = true
          } else {
            item.validFields.cargoWayFee = false
          }*/
        })
        if (cargoInstsFlag) {
          this.$message.error(
            '请填写货物名称信息，数量、重量、体积至少填写一项'
          )
          return
        }
        if (this.isEmptyStr(this.waybill.deliveryDt)) {
          this.validFields.deliveryDt = true
          this.$message.error('发货时间不能为空')
          return
        }
        if (
          this.isEmptyStr(this.waybill.expectDt) ||
          new Date(this.waybill.deliveryDt).getTime() >
            new Date(this.waybill.expectDt).getTime()
        ) {
          this.validFields.expectDt = true
          this.$message.error(
            '预计到达到达时间不能为空，且预计到达到达时间必须大于发货时间'
          )
          return
        }
        if (this.isEmptyStr(this.waybill.wayFee) || this.waybill.wayFee <= 0) {
          this.validFields.wayFee = true
          this.$message.error('运费金额不能为空或为0')
          return
        }
        if (this.isEmptyStr(this.waybill.dlvrFee)) {
          this.waybill.dlvrFee = 0
        }
        if (this.isEmptyStr(this.waybill.otherFee)) {
          this.waybill.otherFee = 0
        }
        if (
          this.waybill.payTp === '1' &&
          (this.isEmptyStr(this.waybill.cashFee) ||
            this.waybill.cashFee <= 0 ||
            Number(this.waybill.cashFee) !== this.jhFee)
        ) {
          this.validFields.cashFee = true
          this.$message.error(
            '现付金额不能为空或小于0，且金额必须与运费合计金额一致'
          )
          return
        }
        if (
          this.waybill.payTp === '2' &&
          (this.isEmptyStr(this.waybill.collectFee) ||
            this.waybill.collectFee <= 0 ||
            Number(this.waybill.collectFee) !== this.jhFee)
        ) {
          this.validFields.collectFee = true
          this.$message.error(
            '到付金额不能为空或小于0，且金额必须与运费合计金额一致'
          )
          return
        }
        if (
          this.waybill.payTp === '4' &&
          (this.isEmptyStr(this.waybill.monthlyFee) ||
            this.waybill.monthlyFee <= 0 ||
            Number(this.waybill.monthlyFee) !== this.jhFee)
        ) {
          this.validFields.monthlyFee = true
          this.$message.error(
            '月结金额不能为空或小于0，且金额必须与运费合计金额一致'
          )
          return
        }
        let num = 0
        let flag = true
        if (this.waybill.payTp === '6') {
          if (
            this.isEmptyStr(this.waybill.cashFee) ||
            this.waybill.cashFee <= 0
          ) {
            num++
            this.waybill.cashFee = 0
          }
          if (
            this.isEmptyStr(this.waybill.collectFee) ||
            this.waybill.collectFee <= 0
          ) {
            num++
            this.waybill.collectFee = 0
          }
          if (
            this.isEmptyStr(this.waybill.monthlyFee) ||
            this.waybill.monthlyFee <= 0
          ) {
            num++
            this.waybill.monthlyFee = 0
          }
          if (
            Number(this.waybill.cashFee) +
              Number(this.waybill.collectFee) +
              Number(this.waybill.monthlyFee) ===
            this.jhFee
          ) {
            flag = true
          } else {
            flag = false
          }
        }
        if (num > 1) {
          this.validFields.cashFee = true
          this.validFields.collectFee = true
          this.validFields.monthlyFee = true
          this.$message.error('多笔付至少填写两笔费用金额')
          return
        }
        if (!flag) {
          this.validFields.cashFee = true
          this.validFields.collectFee = true
          this.validFields.monthlyFee = true
          this.$message.error('多笔付填写费用总金额必须与合计运费金额一致')
          return
        }
        /* if (this.waybill.wayTp === '3' && (this.isEmptyStr(this.waybill.wayFee) || this.waybill.wayFee <= 0)) {
          this.validFields.wayFee = true
        }
        if (this.waybill.payTp === '6' && (this.isEmptyStr(this.waybill.cashFee) || this.waybill.cashFee <= 0)) {
          this.validFields.cashFee = true
        }
        if (this.waybill.payTp === '6' && (this.isEmptyStr(this.waybill.collectFee) || this.waybill.collectFee <= 0)) {
          this.validFields.collectFee = true
        }
        if (this.waybill.payTp !== '6') {
          this.validFields.cashFee = false
          this.validFields.collectFee = false
          this.waybill.cashFee = 0
          this.waybill.collectFee = 0
        } else {
          if (Number((Number(this.waybill.cashFee) + Number(this.waybill.collectFee)).toFixed(this.pricePrecision)) !== this.jhFee) {
            this.$message.error('现付金额和到付金额不等于接货价，不能保存')
            return
          }
        }
        if (this.cargoInsts.length > 0) {
          this.validFields.cargoInsts = this.cargoInsts.every(i => {
            return i.cgId === null || i.instQty === 0 || ((i.instWei === 0 || i.instGrossWei === 0) && i.instVol === 0)
          })
        } else {
          this.$message.error('至少存在一条货物')
          return
        }
        if (this.validFields.cargoInsts) {
          this.$message.error('至少存在一条货物，该货物数量、重量、体积任何一项都不能为零')
          return
        }
        if (this.sumWayFee === '0' || this.sumWayFee === 0 || this.sumWayFee === '') {
          this.$message.error('运费为零，无法保存！')
          return
        }*/
        if (
          !this.validFields.wayId &&
          !this.validFields.sendDt &&
          !this.validFields.strtList &&
          !this.validFields.destList &&
          !this.validFields.cnorNm &&
          !this.validFields.cnorCtcts &&
          !this.validFields.cnorTel &&
          !this.validFields.cnorAddr &&
          !this.validFields.rcvrNm &&
          !this.validFields.rcvrCtcts &&
          !this.validFields.rcvrTel &&
          !this.validFields.deliveryDt &&
          !this.validFields.expectDt &&
          !this.validFields.payTp
        ) {
          this.submit()
        } else {
          this.$message.error('请将运单信息填写完整！')
        }
      })
    },
    isEmptyStr(val) {
      if (val === null || val === '') {
        return true
      } else {
        return false
      }
    },
    /*
    focusWayFee(event) {
      this.calcWayFeeFlag = false
    },
    */
    setCnorNmInputVal(data) {
      this.waybill.cnorNm = data
    },
    setCnorCtctsInputVal(data) {
      this.waybill.cnorCtcts = data
    },
    setCnorTelInputVal(data) {
      this.waybill.cnorTel = data
    },
    setCnorAddrInputVal(data) {
      this.waybill.cnorAddr = data
    },
    setRcvrNmInputVal(data) {
      this.waybill.rcvrNm = data
    },
    setRcvrCtctsInputVal(data) {
      this.waybill.rcvrCtcts = data
    },
    setRcvrTelInputVal(data) {
      this.waybill.rcvrTel = data
    },
    setRcvrAddrInputVal(data) {
      this.waybill.rcvrAddr = data
    },
    addCargoRow(index) {
      // if (this.cargoInsts.length === 5) {
      //   this.$message({
      //     message: '最多只能添加5种货物！',
      //     type: 'warning'
      //   })
      //   return
      // }
      if (this.opt === 'checkIn' || this.opt === 'detail') {
        return
      }
      if (this.waybill.transTp === '1') {
        this.cargoInst.priceTp = '1'
      } else {
        this.cargoInst.priceTp = '4'
      }
      this.cargoInsts.push(JSON.parse(JSON.stringify(this.cargoInst)))
    },
    removeCargoRow(index) {
      if (this.opt === 'detail') {
        return
      }
      this.cargoInsts.splice(index, 1)
    },
    addRcvrAddr(index) {
      if (this.waybill.rcvrAddrs.length === 5) {
        this.$message({
          message: '最多只能添加5个地址！',
          type: 'warning',
        })
        return
      }
      this.waybill.rcvrAddrs.push(JSON.parse(JSON.stringify(this.rcvrAddr)))
    },
    removeRcvrAddr(index) {
      this.waybill.rcvrAddrs.splice(index, 1)
    },
    changeCargoNm(obj) {
      // 判断货物列表中是否存在输入框中的货物信息，如果存在，就不进行货物信息赋值，否则就赋值
      let flag = true
      /* this.cargoOptions.forEach((item) => {
        if (item.cgId === obj.cgId) {
          obj.cgNm = item.cgNm
          flag = false
        }
      })*/
      for (let i = 0; i < this.cargoOptions.length; i++) {
        if (this.cargoOptions[i].cgId === obj.cgId) {
          obj.cgNm = this.cargoOptions[i].cgNm
          flag = false
          break
        }
      }
      if (flag) {
        obj.cgId = obj.target.value
      }
      obj.instQty = 0
      obj.instGrossWei = 0
      obj.instWei = 0
    },
    isRealNum(val) {
      console.log(val)
      // 先判定是否为number
      if (typeof val !== 'number') {
        return false
      }
      if (!isNaN(val)) {
        return true
      } else {
        return false
      }
    },
    calcPricePlan() {
      if (this.waybill.strtList !== null && this.waybill.destList !== null) {
        const priceParams = {
          strt: this.waybill.strtList.aeId,
          dest: this.waybill.destList.aeId,
        }
        if (this.waybill.wayTp === '1' || this.waybill.wayTp === '2') {
          // planId为null时后台报错
          if (this.planId === null) {
            this.planId = 0
          }
          priceParams.planId = this.planId
          calcProtocolPricePlan(priceParams).then((response) => {
            if (response.code === '000') {
              this.pricePlans = response.data
              console.log(this.pricePlans)
              if (this.opt === 'generator' && this.pricePlans.length > 0) {
                this.sumConFee()
              }
            }
          })
        } else if (this.waybill.wayTp === '3') {
          calcBasePricePlan(priceParams).then((response) => {
            if (response.code === '000') {
              this.pricePlans = response.data
            }
          })
        }
      }
    },
    calcInnerPricePlan() {
      if (this.waybill.strt !== null && this.waybill.dest !== null) {
        const priceParams = {
          strt: this.waybill.strt,
          dest: this.waybill.dest,
        }
        calcBaseInnerPricePlan(priceParams).then((response) => {
          if (response.code === '000') {
            this.innerPricePlans = response.data
          }
        })
      }
    },
    inputNumber() {
      if (this.sumWayFee.indexOf('0') === 0) {
        this.sumWayFee = this.sumWayFee.substring(1)
      }
      this.sumWayFee = this.sumWayFee.replace(/[^\d.]/g, '')
    },
    inputNumber2() {
      this.waybill.dlvrFee = this.waybill.dlvrFee.replace(/[^\d.]/g, '')
    },
    inputNumber3() {
      if (this.waybill.otherFee.indexOf('0') === 0) {
        this.waybill.otherFee = this.waybill.otherFee.substring(1)
      }
      this.waybill.otherFee = this.waybill.otherFee.replace(/[^\d.]/g, '')
    },
    dlvrFeeNumber() {
      if (this.waybill.dlvrFee.indexOf('0') === 0) {
        this.waybill.dlvrFee = this.waybill.dlvrFee.substring(1)
      }
      this.waybill.dlvrFee = this.waybill.dlvrFee.replace(/[^\d.]/g, '')
    },
    priceNumber() {
      this.waybill.price = this.waybill.price.replace(/[^\d.]/g, '')
      if (this.waybill.payTp === '6') {
        this.waybill.cashFee = 0
        this.waybill.collectFee = 0
      }
    },
    inputNumber4() {
      this.waybill.innerOtherFee = this.waybill.innerOtherFee.replace(
        /[^\d.]/g,
        ''
      )
    },
    inputNumber5() {
      if (this.waybill.cashFee.indexOf('0') === 0) {
        this.waybill.cashFee = this.waybill.cashFee.substring(1)
      }
      this.waybill.cashFee = this.waybill.cashFee.replace(/[^\d.]/g, '')
      this.validFields.cashFee = false
      this.validFields.collectFee = false
      this.validFields.monthlyFee = false
    },
    inputNumber6() {
      if (this.waybill.collectFee.indexOf('0') === 0) {
        this.waybill.collectFee = this.waybill.collectFee.substring(1)
      }
      this.waybill.collectFee = this.waybill.collectFee.replace(/[^\d.]/g, '')
      this.validFields.cashFee = false
      this.validFields.collectFee = false
      this.validFields.monthlyFee = false
    },
    monthlyFeeNumber() {
      if (this.waybill.monthlyFee.indexOf('0') === 0) {
        this.waybill.monthlyFee = this.waybill.monthlyFee.substring(1)
      }
      this.waybill.monthlyFee = this.waybill.monthlyFee.replace(/[^\d.]/g, '')
      this.validFields.cashFee = false
      this.validFields.collectFee = false
      this.validFields.monthlyFee = false
    },
    changeTransTp(val) {
      if (val === '2') {
        this.priceTpDisabled = true
        this.cargoInsts.forEach((item) => {
          item.priceTp = '4'
        })
      } else {
        this.priceTpDisabled = false
        this.waybill.carTp = null
        this.cargoInsts.forEach((item) => {
          item.priceTp = '1'
        })
      }
      if (this.opt === 'generator') {
        // this.sumConFee()
      }
    },
    changePayTp(val) {
      this.waybill.cashFee = 0
      this.waybill.collectFee = 0
      this.waybill.monthlyFee = 0
      this.doChangePayTp(val)
    },
    doChangePayTp(val) {
      if (val === '1') {
        // 现付
        this.cashFeeIsDisabled = false
        this.collectFeeIsDisabled = true
        this.monthlyFeeIsDisabled = true
      } else if (val === '2') {
        // 到付
        this.cashFeeIsDisabled = true
        this.collectFeeIsDisabled = false
        this.monthlyFeeIsDisabled = true
      } else if (val === '4') {
        // 月结
        this.cashFeeIsDisabled = true
        this.collectFeeIsDisabled = true
        this.monthlyFeeIsDisabled = false
      } else if (val === '6') {
        // 多笔付
        this.cashFeeIsDisabled = false
        this.collectFeeIsDisabled = false
        this.monthlyFeeIsDisabled = false
      }
    },
    handleClick(tab) {
      switch (tab.name) {
        case 'first':
          this.outActive = true
          this.dispActive = false
          this.transActive = false
          break
        case 'second':
          this.outActive = false
          this.dispActive = true
          this.transActive = false
          break
        case 'three':
          this.outActive = false
          this.dispActive = false
          this.transActive = true
          break
      }
    },
    changeWayTp(val) {
      this.waybill.priceTp = null
      this.priceTps = [
        {
          value: '3',
          label: '/件',
        },
        {
          value: '1',
          label: '/吨',
        },
        {
          value: '2',
          label: '/方',
        },
        {
          value: '4',
          label: '/车',
        },
      ]
      if (val === '1') {
        this.payTps = [{ value: '4', label: '月结' }]
        this.waybill.payTp = '4'
      } else if (val === '3') {
        const opt = {
          value: '5',
          label: '自定义',
        }
        this.priceTps.push(opt)
        this.payTps = [
          { value: '1', label: '现付' },
          { value: '2', label: '到付' },
          { value: '4', label: '月结' },
          { value: '6', label: '多笔付' },
        ]
        this.waybill.payTp = '1'
        this.waybill.receiptReq = '3'
      } else {
        const opt = {
          value: '5',
          label: '自定义',
        }
        this.priceTps.push(opt)
        this.payTps = [
          { value: '1', label: '现付' },
          { value: '2', label: '到付' },
          { value: '3', label: '免费' },
          { value: '4', label: '月结' },
        ]
        this.waybill.payTp = '4'
      }
    },
    backToList() {
      // history.go(-1)
      if (this.opt === 'edit') {
        this.$router.push({
          path: '/biz/WaybillMng/',
          query: {
            queryParams: this.$route.query.queryParams,
            dt: this.$route.query.dt,
          },
        })
      } else {
        history.go(-1)
      }
    },
    converMoney(value) {
      const chinList = [
        '零',
        '壹',
        '贰',
        '叁',
        '肆',
        '伍',
        '陆',
        '柒',
        '捌',
        '玖',
      ] // 所有的数值对应的汉字
      return chinList[value]
    },
    formatDate({ cellValue }) {
      return this.dateFormat(cellValue)
    },
    dateFormat(time) {
      if (time === null || time === '') {
        return null
      }
      var date = new Date(time)
      var year = date.getFullYear()
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      var month =
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      // var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      // var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      // var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      // 拼接
      return year + '-' + month + '-' + day
    },
    dateFormat4Year(time) {
      if (time === null || time === '') {
        return null
      }
      var date = new Date(time)
      var year = date.getFullYear()
      return year
    },
    dateFormat4Month(time) {
      if (time === null || time === '') {
        return null
      }
      var date = new Date(time)
      var month =
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      return month
    },
    dateFormat4Day(time) {
      if (time === null || time === '') {
        return null
      }
      var date = new Date(time)
      var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      return day
    },
    convertMoney(n) {
      if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n)) {
        return '数据非法' // 判断数据是否大于0
      }
      var unit = '千百拾亿千百拾万千百拾元角分'
      var str = ''
      n += '00'
      var indexpoint = n.indexOf('.') // 如果是小数，截取小数点前面的位数
      if (indexpoint >= 0) {
        n = n.substring(0, indexpoint) + n.substr(indexpoint + 1, 2) // 若为小数，截取需要使用的unit单位
      }
      unit = unit.substr(unit.length - n.length) // 若为整数，截取需要使用的unit单位
      for (var i = 0; i < n.length; i++) {
        str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i) // 遍历转化为大写的数字
      }
      return str
        .replace(/零(千|百|拾|角)/g, '零')
        .replace(/(零)+/g, '零')
        .replace(/零(万|亿|元)/g, '$1')
        .replace(/(亿)万|壹(拾)/g, '$1$2')
        .replace(/^元零?|零分/g, '')
        .replace(/元$/g, '元整') // 替换掉数字里面的零字符，得到结果
    },

    printYLXF() {
      var lineHei = 30
      var lineSpace = 5
      var top = 110
      var left = 140
      const LODOP = getLodop()
      LODOP.PRINT_INIT('信封打印')
      // LODOP.SET_PRINT_PAGESIZE(2, 1500, 2400, '')
      LODOP.SET_PRINT_PAGESIZE(1, 2400, 1500, '')
      LODOP.SET_PRINT_STYLE('FontSize', 15)
      for (var i = 0; i < this.waybill.rcvrAddrs.length; i++) {
        top = top + (lineHei + lineSpace) * i
        LODOP.ADD_PRINT_TEXT(
          top,
          left,
          700,
          lineHei,
          '收货地址：' + this.waybill.rcvrAddrs[i].addrNm
        )
      }
      top += lineHei + lineSpace + 20
      LODOP.ADD_PRINT_TEXT(
        top,
        left,
        240,
        lineHei,
        '收货人：' + this.waybill.rcvrCtcts
      )
      LODOP.ADD_PRINT_TEXT(
        top,
        left + 260,
        400,
        lineHei,
        '收货单位：' + this.waybill.rcvrNm
      )

      top += lineHei + lineSpace
      // LODOP.ADD_PRINT_TEXT(top, left, 240, lineHei, '收货手机：' + this.waybill.rcvrTel)
      LODOP.ADD_PRINT_TEXT(top, left, 240, lineHei, '体积：' + this.sumVol)
      var cargoName = ''
      this.waybill.cargoInsts.forEach((item) => {
        cargoName += item.cgNm + ','
      })
      if (cargoName.length > 0) {
        cargoName = cargoName.substring(0, cargoName.length - 1)
      }
      LODOP.ADD_PRINT_TEXT(
        top,
        left + 260,
        240,
        lineHei,
        '货物名称：' + cargoName
      )

      top += lineHei + lineSpace
      LODOP.ADD_PRINT_TEXT(top, left, 240, lineHei, '件数：' + this.sumQty)
      // LODOP.ADD_PRINT_TEXT(top, left + 260, 240, lineHei, '体积：' + this.sumVol)
      LODOP.ADD_PRINT_TEXT(
        top,
        left + 260,
        400,
        lineHei,
        '收货手机：' + this.waybill.rcvrTel
      )

      top += lineHei + lineSpace
      LODOP.ADD_PRINT_TEXT(top, left, 240, lineHei, '重量：' + this.sumWei)
      this.recvTps.forEach((item) => {
        if (item.value === this.waybill.recvTp) {
          LODOP.ADD_PRINT_TEXT(
            top,
            left + 260,
            240,
            lineHei,
            '交接方式：' + item.label
          )
        }
      })
      top += lineHei + lineSpace
      this.receiptReqs.forEach((item) => {
        if (item.value === this.waybill.receiptReq) {
          LODOP.ADD_PRINT_TEXT(
            top,
            left,
            240,
            lineHei,
            '回单要求：' + item.label
          )
        }
      })

      if (this.isSaved) {
        if (this.print_qty > 0) {
          LODOP.SET_PRINT_COPIES(this.print_qty)
        }
        // LODOP.PRINT()
        LODOP.PREVIEW()
      } else {
        this.$message.warning('请先保存运单！')
      }
    },
    printYLBQ() {
      var strtNm = ''
      var destNm = ''
      this.$store.getters.areas.forEach((item) => {
        if (item.aeId === this.waybill.strtList.aeId) {
          strtNm = item.aeName
        }
        if (item.aeId === this.waybill.destList.aeId) {
          destNm = item.aeName
        }
      })
      const LODOP = getLodop()
      LODOP.PRINT_INIT('标签打印')
      // LODOP.SET_SHOW_MODE('BKIMG_WIDTH', '76.2mm')
      // LODOP.SET_SHOW_MODE('BKIMG_HEIGHT', '50.8mm')
      LODOP.SET_PRINT_PAGESIZE(1, 762, 508, '')
      var lineHei = 18
      var lineSpace = 10
      var top = 10
      var left = 0
      var space = 30
      LODOP.SET_PRINT_STYLE('FontSize', 12)

      LODOP.ADD_PRINT_TEXT(
        top,
        left + space - 15,
        300,
        lineHei + 5,
        '银 轮 普 天 供 应 链'
      )
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 16)
      LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)

      top += lineHei + lineSpace + 5
      LODOP.ADD_PRINT_TEXT(
        top,
        left + space + 20,
        300,
        lineHei,
        strtNm + ' --> ' + destNm
      )
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 14)
      LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)

      top += lineHei + lineSpace
      LODOP.ADD_PRINT_TEXT(
        top,
        left + space - 15,
        150,
        lineHei,
        '收货人:' + this.waybill.rcvrCtcts
      )
      var cargoName = ''
      this.waybill.cargoInsts.forEach((item) => {
        cargoName += item.cgNm + ','
      })
      if (cargoName.length > 0) {
        cargoName = cargoName.substring(0, cargoName.length - 1)
      }
      LODOP.ADD_PRINT_TEXT(
        top,
        left + space + 100,
        200,
        lineHei,
        '品名:' + cargoName
      )

      top += lineHei + lineSpace
      LODOP.ADD_PRINT_TEXT(
        top,
        left + space - 15,
        150,
        lineHei,
        '件 数:' + this.sumQty
      )
      LODOP.ADD_PRINT_TEXT(
        top,
        left + space + 80,
        200,
        lineHei,
        '货号:' + this.waybill.wayId
      )

      top += lineHei + lineSpace
      LODOP.ADD_PRINT_BARCODE(
        top,
        left + space - 10,
        256,
        32,
        'EAN128A',
        this.waybill.wayId
      )

      top += 32 + lineSpace
      LODOP.ADD_PRINT_TEXT(
        top,
        left + space,
        300,
        lineHei,
        '全国服务热线:400-102-5588'
      )
      LODOP.PREVIEW()
    },
    printWayBillId() {
      var wayId = this.waybill.wayId.substring(3)
      const LODOP = getLodop()
      LODOP.PRINT_INIT('运单打印')
      LODOP.SET_PRINT_STYLE('FontSize', 15)
      LODOP.SET_SHOW_MODE('BKIMG_WIDTH', '210.00mm')
      LODOP.SET_SHOW_MODE('BKIMG_HEIGHT', '143.67mm')
      LODOP.ADD_PRINT_TEXT(85, 520, 100, 20, wayId)

      if (this.isSaved) {
        if (this.print_qty > 0) {
          LODOP.SET_PRINT_COPIES(this.print_qty)
        }
        LODOP.PREVIEW()
      } else {
        this.$message.warning('请先保存运单！')
      }
    },
    printYLWB() {
      var year = this.dateFormat4Year(this.waybill.deliveryDt)
      var month = this.dateFormat4Month(this.waybill.deliveryDt)
      var day = this.dateFormat4Day(this.waybill.deliveryDt)
      var strtNm = ''
      var destNm = ''
      this.$store.getters.areas.forEach((item) => {
        if (item.aeId === this.waybill.strt) {
          strtNm = item.aeName
        }
        if (item.aeId === this.waybill.dest) {
          destNm = item.aeName
        }
      })
      var wayId = this.waybill.wayId.substring(3)
      var cnorNm = this.waybill.cnorNm
      // var cnorCtcts = this.waybill.cnorCtcts
      var cnorAddr = this.waybill.cnorAddr
      var cnorTel = this.waybill.cnorTel
      var rcvrNm = this.waybill.rcvrNm
      // var rcvrCtcts = this.waybill.rcvrCtcts
      var rcvrAddr = ''
      this.waybill.rcvrAddrs.forEach((item) => {
        rcvrAddr += item.addrNm + ','
      })
      if (rcvrAddr.length > 0) {
        rcvrAddr = rcvrAddr.substring(0, rcvrAddr.length - 1)
      }
      var rcvrTel = this.waybill.rcvrTel
      const LODOP = getLodop()
      LODOP.PRINT_INIT('运单打印')
      LODOP.SET_PRINT_STYLE('FontSize', 15)
      // LODOP.ADD_PRINT_SETUP_BKIMG('<img border=\'0\' src=\'http://www.c-lodop.com/demolist/PrintSample9.jpg\'>')
      // LODOP.SET_SHOW_MODE('BKIMG_IN_PREVIEW', 1)
      LODOP.SET_SHOW_MODE('BKIMG_WIDTH', '210.00mm')
      LODOP.SET_SHOW_MODE('BKIMG_HEIGHT', '143.67mm')
      LODOP.ADD_PRINT_TEXT(105, 25, 60, 20, year)
      LODOP.ADD_PRINT_TEXT(105, 78, 30, 20, month)
      LODOP.ADD_PRINT_TEXT(105, 110, 30, 20, day)
      LODOP.ADD_PRINT_TEXT(105, 230, 100, 20, strtNm)
      LODOP.ADD_PRINT_TEXT(105, 360, 100, 20, destNm)
      LODOP.ADD_PRINT_TEXT(100, 530, 100, 20, wayId)

      LODOP.SET_PRINT_STYLE('FontSize', 10)
      LODOP.ADD_PRINT_TEXT(138, 80, 200, 20, cnorNm)
      LODOP.ADD_PRINT_TEXT(130, 265, 230, 40, cnorAddr)
      LODOP.ADD_PRINT_TEXT(138, 560, 100, 20, cnorTel)
      LODOP.ADD_PRINT_TEXT(170, 80, 100, 20, rcvrNm)
      LODOP.ADD_PRINT_TEXT(170, 265, 230, 40, rcvrAddr)
      LODOP.ADD_PRINT_TEXT(170, 560, 100, 20, rcvrTel)

      // LODOP.ADD_PRINT_TEXT(133, 80, 240, 20, cnorAddr)
      // LODOP.ADD_PRINT_TEXT(157, 80, 240, 20, cnorTel)
      // LODOP.ADD_PRINT_TEXT(108, 390, 265, 20, rcvrCtcts)
      // LODOP.ADD_PRINT_TEXT(133, 390, 265, 20, rcvrAddr)
      // LODOP.ADD_PRINT_TEXT(157, 390, 265, 20, rcvrTel)
      if (this.waybill.cargoInsts.length > 0) {
        LODOP.ADD_PRINT_TEXT(235, 25, 100, 20, this.waybill.cargoInsts[0].cgNm)
        LODOP.ADD_PRINT_TEXT(
          235,
          135,
          42,
          20,
          this.waybill.cargoInsts[0].instQty + '件'
        )
        LODOP.ADD_PRINT_TEXT(
          235,
          190,
          43,
          20,
          this.waybill.cargoInsts[0].cgSpec
        )
        LODOP.ADD_PRINT_TEXT(
          235,
          250,
          99,
          20,
          this.waybill.cargoInsts[0].instWei +
            '吨/' +
            this.waybill.cargoInsts[0].instVol +
            '方'
        )
      }
      if (this.waybill.cargoInsts.length > 1) {
        LODOP.ADD_PRINT_TEXT(270, 25, 100, 20, this.waybill.cargoInsts[1].cgNm)
        LODOP.ADD_PRINT_TEXT(
          270,
          135,
          42,
          20,
          this.waybill.cargoInsts[1].instQty + '件'
        )
        LODOP.ADD_PRINT_TEXT(
          270,
          190,
          43,
          20,
          this.waybill.cargoInsts[1].cgSpec
        )
        LODOP.ADD_PRINT_TEXT(
          270,
          250,
          99,
          20,
          this.waybill.cargoInsts[1].instWei +
            '吨/' +
            this.waybill.cargoInsts[1].instVol +
            '方'
        )
      }
      if (this.waybill.cargoInsts.length > 2) {
        LODOP.ADD_PRINT_TEXT(305, 25, 100, 20, this.waybill.cargoInsts[2].cgNm)
        LODOP.ADD_PRINT_TEXT(
          305,
          135,
          42,
          20,
          this.waybill.cargoInsts[2].instQty + '件'
        )
        LODOP.ADD_PRINT_TEXT(
          305,
          190,
          43,
          20,
          this.waybill.cargoInsts[2].cgSpec
        )
        LODOP.ADD_PRINT_TEXT(
          305,
          250,
          99,
          20,
          this.waybill.cargoInsts[2].instWei +
            '吨/' +
            this.waybill.cargoInsts[2].instVol +
            '方'
        )
      }
      let delivery = ''
      let takeTheir = ''
      if (this.waybill.transTp === '1') {
        if (this.waybill.recvTp === '1') {
          delivery = '√'
        } else {
          takeTheir = '√'
        }
      } else {
        delivery = '√'
      }
      let cashPay = ''
      let collectPay = ''
      let monthlyPay = ''
      if (this.waybill.payTp === '1') {
        cashPay = '√'
      } else if (this.waybill.payTp === '2') {
        collectPay = '√'
      } else if (this.waybill.payTp === '4') {
        monthlyPay = '√'
      }
      LODOP.ADD_PRINT_TEXT(230, 475, 60, 30, this.jhFee)
      LODOP.ADD_PRINT_TEXT(230, 560, 43, 20, cashPay)
      LODOP.ADD_PRINT_TEXT(230, 645, 43, 20, delivery)
      LODOP.ADD_PRINT_TEXT(270, 480, 43, 20, '')
      LODOP.ADD_PRINT_TEXT(270, 560, 43, 20, collectPay)
      LODOP.ADD_PRINT_TEXT(270, 645, 43, 20, takeTheir)
      LODOP.ADD_PRINT_TEXT(310, 480, 43, 20, '')
      LODOP.ADD_PRINT_TEXT(310, 560, 43, 20, monthlyPay)
      LODOP.ADD_PRINT_TEXT(310, 645, 43, 20, '')
      /* let convertMoney = this.convertMoney(this.jhFee)
      console.log(convertMoney)
      console.log(convertMoney.substring(0, convertMoney.lastIndexOf('元') + 1))*/
      LODOP.SET_PRINT_STYLE('FontSize', 15)
      LODOP.ADD_PRINT_TEXT(340, 130, 253, 20, this.convertMoney(this.jhFee))
      LODOP.ADD_PRINT_TEXT(340, 385, 100, 20, this.jhFee)
      LODOP.ADD_PRINT_TEXT(340, 490, 200, 50, this.waybill.remark)
      /* this.payTps.forEach((item) => {
        if (item.value === this.waybill.payTp) {
          LODOP.ADD_PRINT_TEXT(330, 588, 65, 20, item.label) // 付款方式
        }
      })*/
      LODOP.SET_PRINT_STYLE('FontSize', 12)
      LODOP.ADD_PRINT_TEXT(450, 70, 100, 20, this.$store.getters.name)
      console.log(this.print_qty)
      if (this.isSaved) {
        if (this.print_qty > 0) {
          LODOP.SET_PRINT_COPIES(this.print_qty)
        }
        // LODOP.PRINT()
        LODOP.PREVIEW()
      } else {
        this.$message.warning('请先保存运单！')
      }
    },
    printWaybill() {
      if (!this.isSaved) {
        this.$message.warning('请先保存运单！')
        return
      }
      const LODOP = getLodop() // 调用getLodop获取LODOP对象
      LODOP.PRINT_INITA(0, -2, 856, 600, '运单打印')
      // LODOP.ADD_PRINT_SETUP_BKIMG('C:\\Users\\shaow\\Desktop\\yundan.jpg')
      LODOP.SET_SHOW_MODE('BKIMG_WIDTH', '215.11mm')
      LODOP.SET_SHOW_MODE('BKIMG_HEIGHT', '300.3mm')
      LODOP.ADD_PRINT_TEXT(
        111,
        107,
        118,
        20,
        this.dateFormat(this.waybill.sendDt)
      ) // 托运日期
      this.$store.getters.areas.forEach((item) => {
        if (item.aeId === this.waybill.strtList.aeId) {
          LODOP.ADD_PRINT_TEXT(115, 280, 100, 20, item.aeName) // 发站
        }
        if (item.aeId === this.waybill.destList.aeId) {
          LODOP.ADD_PRINT_TEXT(115, 429, 100, 20, item.aeName) // 到站
        }
      })
      LODOP.ADD_PRINT_TEXT(215, 116, 100, 20, this.waybill.rcvrCtcts) // 收货人
      LODOP.ADD_PRINT_TEXT(219, 296, 221, 20, this.waybill.rcvrAddr) // 收货地址
      LODOP.ADD_PRINT_TEXT(222, 585, 146, 20, this.waybill.rcvrTel) // 收货电话
      LODOP.ADD_PRINT_TEXT(250, 114, 100, 20, this.waybill.cnorCtcts) // 发货人
      LODOP.ADD_PRINT_TEXT(253, 297, 220, 20, this.waybill.cnorAddr) // 发货地址
      LODOP.ADD_PRINT_TEXT(258, 584, 150, 20, this.waybill.cnorTel) // 发货电话
      LODOP.ADD_PRINT_TEXT(313, 23, 95, 20, this.waybill.cargoInsts[0].cgNm) // 货物名称
      LODOP.ADD_PRINT_TEXT(313, 128, 60, 20, this.waybill.cargoInsts[0].instQty) // 件数
      LODOP.ADD_PRINT_TEXT(314, 225, 56, 20, '') // 单位
      this.cargoSpecs.forEach((item) => {
        if (item.value === this.waybill.cargoInsts[0].cgSpec) {
          LODOP.ADD_PRINT_TEXT(314, 296, 56, 20, item.label) // 包装
        }
      })
      LODOP.ADD_PRINT_TEXT(314, 406, 44, 20, this.waybill.cargoInsts[0].instWei) // 重量
      LODOP.ADD_PRINT_TEXT(314, 486, 62, 20, this.waybill.cargoInsts[0].instVol) // 体积
      LODOP.ADD_PRINT_TEXT(348, 484, 74, 20, this.jhFee) // 总运费
      this.payTps.forEach((item) => {
        if (item.value === this.waybill.payTp) {
          LODOP.ADD_PRINT_TEXT(349, 686, 62, 20, item.label) // 付款方式
        }
      })
      LODOP.ADD_PRINT_TEXT(399, 45, 254, 20, this.waybill.remark) // 备注
      const jhFee = this.jhFee + ''
      if (jhFee.length > 0) {
        LODOP.ADD_PRINT_TEXT(
          347,
          319,
          23,
          20,
          this.converMoney(Number(jhFee.substring(jhFee.length - 1)))
        ) // 元
      }
      if (jhFee.length > 1) {
        LODOP.ADD_PRINT_TEXT(
          348,
          276,
          23,
          20,
          this.converMoney(
            Number(jhFee.substring(jhFee.length - 2, jhFee.length - 1))
          )
        ) // 拾
      }
      if (jhFee.length > 2) {
        LODOP.ADD_PRINT_TEXT(
          347,
          225,
          23,
          20,
          this.converMoney(
            Number(jhFee.substring(jhFee.length - 3, jhFee.length - 2))
          )
        ) // 百
      }
      if (jhFee.length > 3) {
        LODOP.ADD_PRINT_TEXT(
          347,
          180,
          24,
          20,
          this.converMoney(
            Number(jhFee.substring(jhFee.length - 4, jhFee.length - 3))
          )
        ) // 千
      }
      if (jhFee.length > 4) {
        LODOP.ADD_PRINT_TEXT(
          347,
          142,
          25,
          20,
          this.converMoney(
            Number(jhFee.substring(jhFee.length - 5, jhFee.length - 4))
          )
        ) // 万
      }
      this.recvTps.forEach((item) => {
        if (item.value === this.waybill.recvTp) {
          LODOP.ADD_PRINT_TEXT(408, 587, 100, 20, item.label) // 交货方式
        }
      })
      if (this.isSaved) {
        if (this.print_qty > 0) {
          LODOP.SET_PRINT_COPIES(this.print_qty)
        }
        // LODOP.PRINT()
        LODOP.PREVIEW()
      } else {
        this.$message.warning('请先保存运单！')
      }
    },
    querySearchCnorAdd(queryString, cb) {
      var restaurants = this.cnorAddrOptions
      var map = this.map
      const self = this
      var results = queryString
        ? restaurants.filter(this.createCnorAddrFilter(queryString))
        : restaurants
      AMapUI.loadUI(['misc/PoiPicker'], function (PoiPicker) {
        var poiPicker = new PoiPicker({
          input: 'cnorAddrInput', // 输入框id
        })
        // 监听poi选中信息
        poiPicker.on('poiPicked', function (poiResult) {
          // 用户选中的poi点信息
          var poi = poiResult.item
          self.poiValue = poi.name
          self.planbill.aeCode = poi.adcode
          if (poi.location !== undefined) {
            self.planbill.cnorAddrs[0].lng = poi.location.lng
            self.planbill.cnorAddrs[0].lat = poi.location.lat
            if (poi.district !== undefined) {
              self.planbill.aeName = poi.district
              self.planbill.cnorAddrs[0].cnorAddrNm =
                poi.district + poi.name + poi.address
            } else {
              self.planbill.aeName = poi.adname
              self.planbill.cnorAddrs[0].cnorAddrNm =
                poi.pname + poi.cityname + poi.adname + poi.name + poi.address
            }
            self.location = []
            self.location.push(self.planbill.cnorAddrs[0].lng)
            self.location.push(self.planbill.cnorAddrs[0].lat)
            // map.setCenter(self.location)
            // map.setZoom(14)
            // self.positionPicker.start(self.location)

            var info = []
            // info.push('<div style="padding:5px 0px 0px 0px;"><h4>' + self.poiValue + '</h4>')
            info.push(
              "<p style='font-size: 12px;'>经度： " +
                self.planbill.cnorAddrs[0].lng +
                '</p>'
            )
            info.push(
              "<p style='margin-top: -5px;font-size: 12px;'>纬度： " +
                self.planbill.cnorAddrs[0].lat +
                '</p>'
            )
            info.push(
              "<p style='margin-top: -5px;font-size: 12px;'>地址：" +
                poi.address +
                '</p></div>'
            )

            self.infoWindow = new AMap.InfoWindow({
              content: info.join(''), // 使用默认信息窗体框样式，显示信息内容
              offset: new AMap.Pixel(0, -31),
            })
            self.infoWindow.open(map, self.location)
          } else {
            self.planbill.cnorAddrs[0].cnorAddrNm = ''
            self.planbill.cnorAddrs[0].lng = ''
            self.planbill.cnorAddrs[0].lat = ''
          }
        })
      })
      results.forEach((item) => {
        item.value = item.cnorAddrNm
      })
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    handleSelectCnorAddrId(item) {
      this.cnorAddrId = item.cnorAddrId
      this.cnorAddrOptions.forEach((item) => {
        if (item.cnorAddrId === this.cnorAddrId) {
          this.planbill.cnorAddrs[0].lng = item.lng
          this.planbill.cnorAddrs[0].lat = item.lat
          this.planbill.cnorAddrNm = item.cnorAddrNm
        }
      })
    },
    // feat: 地址选择后可编辑
    querySearch(queryString, cb) {
      var restaurants = this.rcvrAddrOptions
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants
      results.forEach((item) => {
        item.value = item.addrNm
      })
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    // 货物选择后可编辑
    querySearch2(queryString, cb) {
      var restaurants = this.cargoOptions
      var results = queryString
        ? restaurants.filter(this.createFilter2(queryString))
        : restaurants
      results.forEach((item) => {
        item.value = item.cgNm
      })
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter2(queryString) {
      return (vehicle) => {
        return vehicle.cgNm.toLowerCase().match(queryString.toLowerCase())
      }
    },
    // feat: 地址选择后可编辑
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.addrNm.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        )
      }
    },
  },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
::v-deep .el-input__suffix {
  line-height: 28px;
  cursor: pointer;
  .el-icon-map-location {
    margin-right: 6px;
  }
}
.plan-bill {
  width: 100%;
  height: 100%;
  background-color: #fff;
  .plan-bill-content {
    width: 100%;
    height: calc(100% - 34px);
    overflow: auto;
  }
}
// 地图显示搜索建议，和搜索条件的更多有冲突
.search-box-1 {
  z-index: 100;
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
  width: 300px;
  font-size: 14px;
}
.map-cls {
  height: 480px;
  border: 1px solid #d2d6de;
}
.row_table_col {
  border-bottom: 1px solid #e7e8e9;
  border-left: 1px solid #e7e8e9;
  height: 30px;
  line-height: 30px;
}
.row_table_col1 {
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid #e7e8e9;
  text-align: center;
  color: #4ad684;
}
.icon_delete {
  color: #ff6159;
}
.row_header_col {
  background: #8fbae5;
  color: #fff;
  font-weight: 500;
  border-bottom: 1px solid #e7e8e9;
  border-left: 1px solid #e7e8e9;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
.row_header_col1 {
  background: #8fbae5;
  color: #fff;
  font-weight: 500;
  border-bottom: 1px solid #e7e8e9;
  border-top-left-radius: 4px;
  height: 30px;
  text-align: center;
  line-height: 30px;
}
.row_header_col2 {
  border-top-right-radius: 4px;
}
.row_footer_col {
  height: 30px;
  line-height: 30px;
}
.row_footer_col2 {
  border-left: 1px solid #e7e8e9;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
.left {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  /*min-width: 1016px;*/
  min-width: 1575px;
  padding: 7px 0 7px 7px;
}
.order-editor-header {
  margin-bottom: 2px;
}
.clearfix {
  zoom: 1;
}
.order-editor-header > h1 {
  width: 210px;
  margin: auto;
  font-size: 23px;
  color: #333;
  line-height: 30px;
  letter-spacing: 16px;
  text-indent: 16px;
  white-space: nowrap;
  font-weight: 400;
}
.order-editor-header > h1:after {
  content: '';
  display: block;
  height: 5px;
  margin-top: 6px;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
}
.order-editor-header > h1 ~ div {
  margin-top: -22px;
}
.fn-label {
  color: red;
  font-weight: 400;
  font-size: 15px;
  line-height: 1;
}
.fr {
  float: right;
}
.fl {
  float: left;
}
.order-content {
  border-radius: 5px;
  margin-top: 8px;
  border: 1px solid #e7e8e9;
}
.order-editor-traffic {
  padding: 3px;
}
.order-editor-traffic2 {
  border-top: 1px solid #e7e8e9;
  padding: 3px;
}
/*E7E8E9 78aadd*/
.line_col_l {
  border-left: 1px solid #e7e8e9;
}
.line_col_r {
  border-right: 1px solid #e7e8e9;
}
.label_right {
  text-align: right;
  line-height: 28px;
}
.label_right2 {
  text-align: right;
  line-height: 34px;
}
.fhr_shr {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background: #8fbae5;
  height: 138px;
  color: #fff;
  font-weight: 500;
  font-size: 19px;
  text-align: center;
  padding-top: 40px;
}
.checkbox_cls {
  margin-left: 20px;
}
.required_fields:before {
  content: '* ';
  color: red;
}
</style>

<style  rel="stylesheet/scss" lang="scss" >
.validFieldClass .el-input__inner {
  border-color: #f56c6c;
}
.shuxian {
  border-left: 1px solid #e7e8e9;
}
.waybillid input.el-input__inner {
  color: red;
  font-weight: 500;
}
label {
  font-weight: 400;
  font-size: 15px;
}
.waybill .el-input__inner {
  width: 180px;
  border-radius: 0px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1.5px;
  font-weight: 400;
  font-size: 15px;
  /*outline: medium;*/
}
.waybillReceiptReq .el-input__inner {
  width: 100px;
  border-radius: 0px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1.5px;
  font-weight: 400;
  font-size: 15px;
  /*outline: medium;*/
}
.planbillAcceptShipper .el-input__inner {
  width: 450px;
  border-radius: 0px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1.5px;
  font-weight: 400;
  font-size: 15px;
  /*outline: medium;*/
}
.planbillAcceptShipper1 .el-input__inner {
  border-radius: 0px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  font-weight: 400;
  font-size: 15px;
  padding-left: 0px;
  height: 34px;
  /*outline: medium;*/
}
.planbillAcceptShipper1 input::-webkit-input-placeholder {
  color: #000000;
}
.planbillAcceptShipper1 .el-select .el-input .el-select__caret {
  color: #ffffff;
}
.waybill3 .el-input__inner {
  width: 70px;
  border-radius: 0px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1.5px;
  font-weight: 400;
  font-size: 15px;
  /*outline: medium;*/
}
.innerfee .el-input__inner {
  width: 150px;
  border-radius: 0px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1.5px;
  font-weight: 400;
  font-size: 15px;
  /*outline: medium;*/
}
.waybill2 {
  width: 200px;
}
.waybill11 .el-input__inner {
  width: 210px;
}
.input_span .el-input__inner {
  margin-left: 15px;
  width: 300px;
}
.input_span1 .el-input__inner {
  // margin-left: 5px;
  // width: 480px;
}
.input_span2 .el-input__inner {
  margin-left: 5px;
  width: 130px;
}

.input_span3 .el-input__inner {
  margin-left: 5px;
  width: 120px;
}
.input_span4 .el-input__inner {
  width: 110px;
}
.input_span5 .el-input__inner {
  width: 70px;
}
.input_span6 .el-input__inner {
  width: 135px;
}
.input_span7 .el-input__inner {
  margin-left: 20px;
  width: 220px;
}
.input_span8 .el-input__inner {
  width: 130px;
}
.el-radio-group {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
}
.el-radio {
  margin-bottom: 20px;
}
.showFixed {
  position: fixed;
  top: 50px;
  z-index: 999;
  width: 100%;
  min-width: 1200px;
  padding-right: 235px;
  /*padding-right: 50px;*/
}
.showFixed2 {
  position: fixed;
  top: 80px;
  z-index: 999;
  width: 100%;
  min-width: 1200px;
  background-color: white;
  padding-right: 235px;
  /*padding-right: 50px;*/
}
.showRetract {
  padding-right: 67px;
}
.notShowRetract {
  padding-right: 250px;
}
</style>